import React, { Component } from 'react';
import { connect } from 'react-redux';
import { db, storage, timeStamp } from '../../../auth/authService';
import { toast } from 'react-toastify';
import AdminPageLayout from './AdminPageLayout';
import Form from '@rjsf/material-ui';
import uniqid from 'uniqid';

import { Loader, Dimmer } from 'semantic-ui-react';

const schema = {
  'title': 'Edit Product Detail. This detail will replace the original detail',
  'type': 'object',
  'required': ['productDetails'],
  'properties': {
    'productDetails': {
      'type': 'array',
      'title': 'Product Detail - Accordion Form',
      'items': {
        'type': 'object',
        'required': ['accordionTitle'],
        'properties': {
          'accordionTitle': {
            'type': 'string',
            'title': 'accordion title'
          },
          'accordionBody': {
            'type': 'array',
            'items': {
              'type': 'object',
              'anyOf': [
                {
                  'title': 'Accordion Text',
                  'properties': {
                    'accordionText': {
                      'type': 'string',
                      'title': 'Text'
                    }
                  }
                },
                {
                  'title': 'Accordion Image',
                  'properties': {
                    'accordionImages': {
                      'type': 'array',
                      'title': 'Images',
                      'items': {
                        'type': 'object',
                        'properties': {
                          'imageFile': {
                            'type': 'string',
                            'format': 'data-url',
                            'title': 'Image in JPEG format',
                            'description': 'Enter jpeg Images only'
                          },
                          'imageName': {
                            'type': 'string',
                            'title': 'Image Description'
                          }
                        }
                      }
                    }
                  }
                },
                {
                  'title': 'Accordion Links',
                  'properties': {
                    'accordionLinks': {
                      'type': 'array',
                      'title': 'links',
                      'items': {
                        'type': 'object',
                        'properties': {
                          'linkUrl': {
                            'type': 'string',
                            'title': 'Link Address'
                          },
                          'linkDescription': {
                            'type': 'string',
                            'title': 'Link Description'
                          }
                        }
                      }
                    }
                  }
                },
                {
                  'title': 'Accordion Attachments',
                  'properties': {
                    'accordionAttachments': {
                      'type': 'array',
                      'title': 'PDF Attachment',
                      'items': {
                        'type': 'object',
                        'properties': {
                          'attachmentFile': {
                            'type': 'string',
                            'format': 'data-url',
                            'title': 'Attachment in PDF file',
                            'description': 'Enter PDF files only'
                          },
                          'attachmentName': {
                            'type': 'string',
                            'title': 'PDF Description'
                          }
                        }
                      }
                    }
                  }
                }
              ]
            }
          }
        }
      }
    }
  }
};

const uiSchema = {
  productDetails: {
    items: {
      accordionBody: {
        items: {
          accordionText: {
            'ui:widget': 'textarea'
          },
          accordionImages: {
            'ui:options': { accept: '.jpeg' }
          }
        }
      },
      accordionAttachment: {
        items: {
          attachmentFile: {
            'ui:options': { accept: '.pdf' }
          }
        }
      }

      // accordionImages: {
      //   items: {
      //     imageFile: {
      //       'ui:options': { accept: '.jpeg' }
      //     }
      //   }
      // }
    }
  }
};

class ProductDetailEdit extends Component {
  state = {
    formData: {},
    submitting: false
  };

  componentDidMount() {}

  onSubmit = async ({ formData }, e) => {
    try {
      e.preventDefault();
      //getting the product name from the url//////////
      const pathname = window.location.pathname;
      const pathnameArr = pathname.split('/');
      const productName = pathnameArr[pathnameArr.length - 1];
      const productNameArr = productName.split('%20');
      let name = '';
      for (let i = 0; i < productNameArr.length; i++) {
        if (i === 0) {
          name = productNameArr[i];
        } else {
          name = name.concat(' ', productNameArr[i]);
        }
      }
      //////////////////////////////////////////////////
      console.log('data submitting: ', formData);

      this.setState({
        submitting: true
      });
      const updatedAt = timeStamp.now();
      const { productDetails } = formData;

      console.log(productDetails, 'productList');

      // productDetails upload
      const newProductDetails = await this.asyncForEachAccordionFileUpload(
        productDetails,
        this.uploadTaskPromise
      );
      console.log(newProductDetails);

      let productRef = await db.collection('products');
      productRef
        .where('productName', '==', productName)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            productRef
              .doc(doc.id)
              .update({
                productDetails: newProductDetails,
                updatedAt
              })
              .then(() => {
                toast.success('data updated');
                this.setState({ submitting: false });
                window.location.reload(true);
              })
              .catch(err => console.log(err));
          });
        })
        .catch(err => console.log(err));

      //   this.setState({
      //     submitting: false
      //   });
      //   window.location.reload(true);
      //   toast.success('data submitted');
    } catch (err) {
      this.setState({
        submitting: false
      });
      console.log(err);
    }
  };

  asyncForEachAccordionFileUpload = async (productDetails, callback) => {
    try {
      let details = [...productDetails];
      console.log('HEYYY ACCORDION');
      return new Promise(async (resolve, reject) => {
        for (let j = 0; j < details.length; j++) {
          for (let k = 0; k < details[j].accordionBody.length; k++) {
            if ('accordionImages' in details[j].accordionBody[k]) {
              console.log(
                'it has accordion Image',
                details[j].accordionBody[k]
              );
              let newObject = {
                accordionImagesUrls: []
              };
              Object.assign(details[j].accordionBody[k], newObject);
              for (
                let x = 0;
                x < details[j].accordionBody[k].accordionImages.length;
                x++
              ) {
                console.log(
                  'accordionimage processing',
                  details[j].accordionBody[k].accordionImages
                );
                console.log(details[j].accordionBody[k].accordionImages[x]);
                let resolvedArr = await callback(
                  details[j].accordionBody[k].accordionImages[x].imageFile,
                  details[j].accordionBody[k].accordionImages[x].imageName
                );
                details[j].accordionBody[k].accordionImagesUrls.push({
                  url: resolvedArr[0],
                  ref: resolvedArr[1]
                });
              }
            }
            if ('accordionAttachments' in details[j].accordionBody[k]) {
              let newObject = {
                accordionAttachmentsUrls: []
              };
              Object.assign(details[j].accordionBody[k], newObject);
              for (
                let x = 0;
                x < details[j].accordionBody[k].accordionAttachments.length;
                x++
              ) {
                let resolvedArr = await callback(
                  details[j].accordionBody[k].accordionAttachments[x]
                    .attachmentFile,
                  details[j].accordionBody[k].accordionAttachments[x]
                    .attachmentName
                );
                details[j].accordionBody[k].accordionAttachmentsUrls.push({
                  url: resolvedArr[0],
                  ref: resolvedArr[1]
                });
              }
            }
          }
        }

        console.log(details);
        resolve(details);
        if (!details) {
          reject();
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  uploadTaskPromise = async (imageFile, name) => {
    const uid = uniqid();
    let imageName = uid.concat(name);
    console.log(imageName);
    return new Promise((resolve, reject) => {
      const storageRef = storage.ref(`/images/` + imageName);
      const upLoadTask = storageRef.putString(imageFile, 'data_url');
      upLoadTask.on(
        'state_changed',
        snapshot => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        err => {
          console.log(err);
          reject();
        },
        () => {
          console.log(imageName);
          storage
            .ref('images')
            .child(imageName)
            .getDownloadURL()
            .then(url => {
              console.log(url);
              resolve([url, imageName, imageFile]);
            });
        }
      );
    });
  };

  render() {
    const pathname = window.location.pathname;
    const pathnameArr = pathname.split('/');
    const productName = pathnameArr[pathnameArr.length - 1];
    const productNameArr = productName.split('%20');
    let name = '';
    for (let i = 0; i < productNameArr.length; i++) {
      if (i === 0) {
        name = productNameArr[i];
      } else {
        name = name.concat(' ', productNameArr[i]);
      }
    }
    console.log(name);
    return (
      <AdminPageLayout>
        <h1>{`${name} Product Detail (Accordion)`}</h1>
        <Form
          formData={this.state.formData}
          onSubmit={this.onSubmit}
          schema={schema}
          uiSchema={uiSchema}
        />
        <Dimmer active={this.state.submitting}>
          <Loader size='massive' active={this.state.submitting}>
            Submitting
          </Loader>
        </Dimmer>
      </AdminPageLayout>
    );
  }
}

const mapStateToProps = state => ({
  fetchedCompanies: state.fetchedCompanies
});

export default connect(
  mapStateToProps,
  {}
)(ProductDetailEdit);
