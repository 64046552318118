import React, { Component } from 'react';
import { connect } from 'react-redux';
import { db, storage, timeStamp } from '../../../auth/authService';
import { toast } from 'react-toastify';
import AdminPageLayout from './AdminPageLayout';
import Form from '@rjsf/material-ui';
import uniqid from 'uniqid';

import { Loader, Dimmer } from 'semantic-ui-react';

const schema = {
  'title': 'Add a new product',
  'type': 'object',
  'required': ['productList'],
  'properties': {
    'productList': {
      'type': 'array',
      'title': 'Products',
      'items': {
        'type': 'object',
        'required': [
          'productName',
          'productImgFiles',
          'productOverview',
          'productCategory',
          'productDetails',
          'productThumbnail'
        ],
        'properties': {
          'productName': {
            'type': 'string',
            'title': 'product name'
          },
          'productCategory': {
            'title': 'product category',
            'type': 'string',
            'enum': [
              'Very High Resolution',
              'High Resolution',
              'Medium Resolution',
              'RADAR',
              'Platform Service',
              'Applications'
            ]
          },
          'productOverview': {
            'type': 'string',
            'title': 'product overview'
          },
          'productThumbnail': {
            'type': 'string',
            'title': 'prodct thumbnail',
            'format': 'data-url',
            'description': 'Product thumbnail'
          },
          'productImgFiles': {
            'type': 'array',
            'title': 'Product Samples',
            'items': {
              'type': 'object',
              'properties': {
                'imageFile': {
                  'type': 'string',
                  'format': 'data-url',
                  'title': 'Product Sample Image',
                  'description': 'Enter Jpeg Image'
                }
              }
            }
          },
          'productDetails': {
            'type': 'array',
            'title': 'Product Detail - Accordion Form',
            'items': {
              'type': 'object',
              'required': ['accordionTitle'],
              'properties': {
                'accordionTitle': {
                  'type': 'string',
                  'title': 'accordion title'
                },
                'accordionBody': {
                  'type': 'array',
                  'items': {
                    'type': 'object',
                    'anyOf': [
                      {
                        'title': 'Accordion Text',
                        'properties': {
                          'accordionText': {
                            'type': 'string',
                            'title': 'Text'
                          }
                        }
                      },
                      {
                        'title': 'Accordion Image',
                        'properties': {
                          'accordionImages': {
                            'type': 'array',
                            'title': 'Images',
                            'items': {
                              'type': 'object',
                              'properties': {
                                'imageFile': {
                                  'type': 'string',
                                  'format': 'data-url',
                                  'title': 'Image in JPEG format',
                                  'description': 'Enter jpeg Images only'
                                },
                                'imageName': {
                                  'type': 'string',
                                  'title': 'Image Description'
                                }
                              }
                            }
                          }
                        }
                      },
                      {
                        'title': 'Accordion Links',
                        'properties': {
                          'accordionLinks': {
                            'type': 'array',
                            'title': 'links',
                            'items': {
                              'type': 'object',
                              'properties': {
                                'linkUrl': {
                                  'type': 'string',
                                  'title': 'Link Address'
                                },
                                'linkDescription': {
                                  'type': 'string',
                                  'title': 'Link Description'
                                }
                              }
                            }
                          }
                        }
                      },
                      {
                        'title': 'Accordion Attachments',
                        'properties': {
                          'accordionAttachments': {
                            'type': 'array',
                            'title': 'PDF Attachment',
                            'items': {
                              'type': 'object',
                              'properties': {
                                'attachmentFile': {
                                  'type': 'string',
                                  'format': 'data-url',
                                  'title': 'Attachment in PDF file',
                                  'description': 'Enter PDF files only'
                                },
                                'attachmentName': {
                                  'type': 'string',
                                  'title': 'PDF Description'
                                }
                              }
                            }
                          }
                        }
                      }
                    ]
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

const uiSchema = {
  productList: {
    items: {
      // productImgFiles: {
      //   'ui:options': { accept: '.jpeg' }
      // },
      productOverview: {
        'ui:widget': 'textarea'
      },
      productDetails: {
        items: {
          accordionBody: {
            items: {
              accordionText: {
                'ui:widget': 'textarea'
              },
              accordionImages: {
                'ui:options': { accept: '.jpeg' }
              }
            }
          },
          accordionAttachment: {
            items: {
              attachmentFile: {
                'ui:options': { accept: '.pdf' }
              }
            }
          }

          // accordionImages: {
          //   items: {
          //     imageFile: {
          //       'ui:options': { accept: '.jpeg' }
          //     }
          //   }
          // }
        }
      }
    }
  }
};

class AddNewProduct extends Component {
  state = {
    formData: {},
    submitting: false
  };

  componentDidMount() {}

  onSubmit = async ({ formData }, e) => {
    try {
      e.preventDefault();
      const pathname = window.location.pathname;
      const pathnameArr = pathname.split('/');
      const companyName = pathnameArr[pathnameArr.length - 1];
      console.log('data submitting: ', formData);
      this.setState({
        submitting: true
      });
      const createdAt = timeStamp.now();
      const { productList } = formData;

      console.log(productList, 'productList');

      // productDetails upload
      const products = await this.asyncForEachAccordionFileUpload(
        productList,
        this.uploadTaskPromise
      );
      // product sample images upload
      const finalProducts = await this.asyncForEachFileUpload(
        products,
        this.uploadTaskPromise
      );
      console.log(finalProducts);

      //productList to Company Collections
      let productsToCompany = [];
      finalProducts.forEach(item => {
        productsToCompany.push(item.productName);
      });

      //updating company
      let companyRef = await db.collection('companies');
      companyRef
        .where('companyName', '==', companyName)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let productArray = doc.data().productList;
            const finalArray = productArray.concat(productsToCompany);
            console.log(finalArray);
            companyRef
              .doc(doc.id)
              .update({
                productList: finalArray
              })
              .then(() => {
                console.log('company successfully updated');
              })
              .catch(err => console.log(err));
          });
        })
        .catch(err => console.log(err));

      for (let index = 0; index < products.length; index++) {
        console.log('forloop called');
        const finalProduct = finalProducts[index];
        await db
          .collection('products')
          .doc(finalProduct.productName)
          .set({
            companyName,
            productName: finalProduct.productName,
            productCategory: finalProduct.productCategory,
            productSamples: finalProduct.productSamples,
            productThumbnailUrl: finalProduct.productThumbnailUrl,
            productOverview: finalProduct.productOverview,
            productDetails: finalProduct.productDetails,
            createdAt
          })
          .then(() => console.log('data upload successful async'))
          .catch(err => console.log(err));
      }

      this.setState({
        submitting: false
      });
      window.location.reload(true);
      toast.success('data submitted');
    } catch (err) {
      this.setState({
        submitting: false
      });
      console.log(err);
    }
  };

  asyncForEachFileUpload = async (array, callback) => {
    console.log(array);
    //productList -> WV1, WV2 , ....
    let productList = [...array];
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < array.length; i++) {
        // let url = await callback(array[index].productImgFiles);
        // products[index]['productSampleUrl'] = url;
        let newUrlObject = {
          productSamples: [],
          productThumbnailUrl: {}
        };
        Object.assign(productList[i], newUrlObject);
        for (let j = 0; j < array[i].productImgFiles.length; j++) {
          let arr = await callback(
            array[i].productImgFiles[j].imageFile,
            array[i].productName
          );

          productList[i].productSamples.push({ url: arr[0], ref: arr[1] });
          console.log(productList[i].productSamples);
        }
        let resolvedArr = await callback(
          array[i].productThumbnail,
          array[i].productName
        );
        productList[i].productThumbnailUrl = {
          url: resolvedArr[0],
          ref: resolvedArr[1]
        };
      }

      console.log(productList);
      resolve(productList);
    });
  };

  asyncForEachAccordionFileUpload = async (array, callback) => {
    try {
      let productList = [...array];
      console.log('HEYYY ACCORDION');
      return new Promise(async (resolve, reject) => {
        for (let i = 0; i < array.length; i++) {
          for (let j = 0; j < array[i].productDetails.length; j++) {
            for (
              let k = 0;
              k < array[i].productDetails[j].accordionBody.length;
              k++
            ) {
              if (
                'accordionImages' in array[i].productDetails[j].accordionBody[k]
              ) {
                console.log(
                  'it has accordion Image',
                  array[i].productDetails[j].accordionBody[k]
                );
                let newObject = {
                  accordionImagesUrls: []
                };
                Object.assign(
                  productList[i].productDetails[j].accordionBody[k],
                  newObject
                );
                for (
                  let x = 0;
                  x <
                  array[i].productDetails[j].accordionBody[k].accordionImages
                    .length;
                  x++
                ) {
                  console.log(
                    'accordionimage processing',
                    array[i].productDetails[j].accordionBody[k].accordionImages
                  );
                  console.log(
                    array[i].productDetails[j].accordionBody[k].accordionImages[
                      x
                    ]
                  );
                  let resolvedArr = await callback(
                    array[i].productDetails[j].accordionBody[k].accordionImages[
                      x
                    ].imageFile,
                    array[i].productDetails[j].accordionBody[k].accordionImages[
                      x
                    ].imageName
                  );
                  productList[i].productDetails[j].accordionBody[
                    k
                  ].accordionImagesUrls.push({
                    url: resolvedArr[0],
                    ref: resolvedArr[1]
                  });
                }
              }
              if (
                'accordionAttachments' in
                array[i].productDetails[j].accordionBody[k]
              ) {
                let newObject = {
                  accordionAttachmentsUrls: []
                };
                Object.assign(
                  productList[i].productDetails[j].accordionBody[k],
                  newObject
                );
                for (
                  let x = 0;
                  x <
                  array[i].productDetails[j].accordionBody[k]
                    .accordionAttachments.length;
                  x++
                ) {
                  let resolvedArr = await callback(
                    array[i].productDetails[j].accordionBody[k]
                      .accordionAttachments[x].attachmentFile,
                    array[i].productDetails[j].accordionBody[k]
                      .accordionAttachments[x].attachmentName
                  );
                  productList[i].productDetails[j].accordionBody[
                    k
                  ].accordionAttachmentsUrls.push({
                    url: resolvedArr[0],
                    ref: resolvedArr[1]
                  });
                }
              }
            }
          }
        }
        console.log(productList);
        resolve(productList);
        if (!productList) {
          reject();
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  uploadTaskPromise = async (imageFile, name) => {
    const uid = uniqid();
    let imageName = uid.concat(name);
    console.log(imageName);
    return new Promise((resolve, reject) => {
      const storageRef = storage.ref(`/images/` + imageName);
      const upLoadTask = storageRef.putString(imageFile, 'data_url');
      upLoadTask.on(
        'state_changed',
        snapshot => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        err => {
          console.log(err);
          reject();
        },
        () => {
          console.log(imageName);
          storage
            .ref('images')
            .child(imageName)
            .getDownloadURL()
            .then(url => {
              console.log(url);
              resolve([url, imageName, imageFile]);
            });
        }
      );
    });
  };

  render() {
    const pathname = window.location.pathname;
    const pathnameArr = pathname.split('/');
    const companyName = pathnameArr[pathnameArr.length - 1];

    return (
      <AdminPageLayout>
        <h1>{companyName}</h1>
        <Form
          formData={this.state.formData}
          onSubmit={this.onSubmit}
          schema={schema}
          uiSchema={uiSchema}
        />
        <Dimmer active={this.state.submitting}>
          <Loader size='massive' active={this.state.submitting}>
            Submitting
          </Loader>
        </Dimmer>
      </AdminPageLayout>
    );
  }
}

const mapStateToProps = state => ({
  fetchedCompanies: state.fetchedCompanies
});

export default connect(
  mapStateToProps,
  {}
)(AddNewProduct);
