import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link as ReactRouterDomLink } from 'react-router-dom';
import styled from 'styled-components';
import { Spinner, MarketplaceCard } from 'components/common';

const Container = styled.div`
  width: 100%;
  padding: 2rem 5rem;
  h1 {
    font-family: 'MuseoSans-700';
    font-size: 2rem;
  }
  /* background-color: grey; */
`;
const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-bottom: 3rem; */
`;

export class MarketplaceContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
    this.intervalID = 0;
  }

  componentDidMount() {
    console.log('componentDidmount');
    this.intervalID = setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  }
  UNSAFE_componentWillReceiveProps() {
    console.log('component will receive props');
    clearInterval(this.intervalID);
    this.setState({
      isLoading: true
    });
    this.intervalID = setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 500);
  }
  componentWillUnmount() {
    console.log('component Will Unmount');
    clearInterval(this.intervalID);
  }

  updateResults = (products, companies) => {
    let companyList = [];

    products.forEach(p => companyList.push(p.companyName));
    const uniqueSet = new Set(companyList);
    const filteredCompanyNames = [...uniqueSet];
    const filteredCompanies = companies.filter(company => {
      if (filteredCompanyNames.includes(company.companyName)) {
        return true;
      } else {
        return false;
      }
    });

    return filteredCompanies;
  };

  render() {
    // console.log(this.props);
    const { products, companies, filteredProducts } = this.props;
    const filteredCompanies = this.updateResults(filteredProducts, companies);

    // console.log(filteredCompanies);
    if (filteredCompanies.length === 0) {
      return (
        <Container>
          <h1>NO RESULTS FOUND</h1>
        </Container>
      );
    } else {
      return (
        <Container>
          <h1>RESULTS</h1>
          <CardsContainer>
            {filteredCompanies.map((company, index) => {
              const productList = filteredProducts.filter(product => {
                return product.companyName === company.companyName;
              });
              console.log(productList);
              return (
                <MarketplaceCard
                  key={company.companyName + index}
                  isLoading={this.state.isLoading}
                  company={company}
                  products={productList}
                />
              );
            })}
          </CardsContainer>
          {/* <Spinner
            isActive={this.state.isLoading}
            // style={{ padding: '5rem 0' }}
          /> */}
        </Container>
      );
    }
  }
}
