import React, { Component } from 'react';
import { Menu, Form, Button, Grid, Container } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../redux/authActions';
import { getAuthenticationStatus } from '../../../auth/authService';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  font-size: 10rem;
`;

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: ''
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    this.setState({ activeItem: pathname });
  }

  handleClick = () => {
    this.props
      .logout()
      .then(() => {
        toast.success('Logout successful');
      })
      .catch(err => {
        toast.error('Login failed');
        console.log(err);
      });
  };

  render() {
    const { activeItem } = this.state;
    getAuthenticationStatus();
    console.log(localStorage.isAuthenticated);

    return (
      <StyledMenu style={{ fontSize: '2rem' }}>
        <Menu.Item
          as={Link}
          to='/admin'
          name='Admin'
          active={activeItem === '/admin'}
        />
        <Menu.Item
          as={Link}
          to='/admin/add'
          name='Add'
          active={activeItem === '/admin/add'}
        />
        <Menu.Item
          as={Link}
          to='/admin/company'
          name='Company'
          active={activeItem === '/admin/company'}
        />
        <Menu.Item
          as={Link}
          to='/admin/product'
          name='Product'
          active={activeItem === '/admin/product'}
        />
        <Menu.Item
          as={Link}
          to='/admin/user'
          name='User'
          active={activeItem === 'User'}
        />
        <Menu.Item
          as={Link}
          to='/admin/contactus'
          name='ContactUs'
          active={activeItem === 'ContactUs'}
        />
        <Menu.Item
          as={Link}
          to='/admin/requestmore'
          name='RequestMore'
          active={activeItem === 'RequestMore'}
        />
        <Menu.Item
          as={Link}
          to='/admin/becomemember'
          name='MemberRequest'
          active={activeItem === 'MemberRequest'}
        />

        <Menu.Item position='right'>
          <Button as={Link} to='/login' primary onClick={this.handleClick}>
            Log Out
          </Button>
        </Menu.Item>
      </StyledMenu>
    );
  }
}

export default connect(
  null,
  { logout }
)(Nav);
