import React, { Component } from 'react';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  margin: 0 auto;
  /* position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); */
  margin-top: 17%;
  width: 8rem;
  height: 8rem;
  border-color: #ff5c0b rgba(226, 218, 218, 0.1) rgba(226, 218, 218, 0.1);
  border-width: 1rem;
  border-style: solid;
  border-radius: 50%;
  animation: spinner 0.5s infinite linear;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export class Spinner extends Component {
  render() {
    if (this.props.isActive) {
      return <SpinnerContainer></SpinnerContainer>;
    } else {
      return null;
    }
  }
}
