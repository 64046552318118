import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AdminPageLayout from './AdminPageLayout';
import './style.css';
import CompanyEditModal from './CompanyEditModal';

import {
  Dimmer,
  Loader,
  List,
  // Segment,
  // Container,
  // Modal,
  Header,
  Table,
  Image,
  Button
} from 'semantic-ui-react';

import styled from 'styled-components';

const StyledCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

class CompanyEdit extends Component {
  render() {
    const { companies, products } = this.props;
    if (companies && products) {
      console.log(companies);
      return (
        <AdminPageLayout>
          <Header>Company List</Header>

          {/* Table Component HERE ///////////////////////*/}
          <Table celled textAlign={'center'}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Company Logo</Table.HeaderCell>
                <Table.HeaderCell>Company Name</Table.HeaderCell>
                <Table.HeaderCell>Company Overview</Table.HeaderCell>
                <Table.HeaderCell>Product List (Change Order)</Table.HeaderCell>
                <Table.HeaderCell>Option</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {companies.map((company, index) => {
                return (
                  <Table.Row key={index + `row`}>
                    <Table.Cell key={index + `logo`} selectable>
                      <Image
                        src={company.companyLogo.url}
                        fluid
                        key={index + `image`}
                      />
                      <CompanyEditModal
                        key={index + 'companyLogo'}
                        companyName={company.companyName}
                        type={'companyLogo'}
                        data={company.companyLogo}
                      />
                    </Table.Cell>
                    <Table.Cell key={index + `name`} selectable>
                      {company.companyName}
                      <CompanyEditModal
                        key={index + 'companyName'}
                        companyName={company.companyName}
                        type={'companyName'}
                        data={company.companyName}
                      />
                    </Table.Cell>
                    <Table.Cell key={index + `overview`} width={5} selectable>
                      <StyledCell>
                        {company.companyOverview.slice(0, 100) + '...'}
                        <CompanyEditModal
                          key={index + 'overviewModal'}
                          companyName={company.companyName}
                          type={'overview'}
                          data={company.companyOverview}
                        />
                      </StyledCell>
                    </Table.Cell>
                    <Table.Cell key={index + `list`} selectable>
                      <List>
                        {company.productList.map((product, index) => (
                          <List.Item key={index + 'item'}>
                            {`${index + 1}: ` + product}
                          </List.Item>
                        ))}
                      </List>
                      <CompanyEditModal
                        key={index + 'productList'}
                        companyName={company.companyName}
                        type={'productList'}
                        data={company.productList}
                      />
                    </Table.Cell>
                    <Table.Cell key={index + `add&delete`}>
                      <Link to={`/admin/company/${company.companyName}`}>
                        <Button>Add Product</Button>
                      </Link>
                      <CompanyEditModal
                        key={index + 'delete'}
                        companyName={company.companyName}
                        type={'delete'}
                        data={company.companyName}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {/* MODAL Component HERE ///////////////////////*/}
        </AdminPageLayout>
      );
    } else {
      return (
        <AdminPageLayout>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </AdminPageLayout>
      );
    }
  }
}

const mapStateToProps = state => ({
  companies: state.fetchedCompanies.companies,
  products: state.fetchedProducts.products
});

export default connect(
  mapStateToProps,
  {}
)(CompanyEdit);
