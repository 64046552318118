import React from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem 2rem;
  width: 90vw;

  background-color: #1e1e1e;
  position: relative;
  top: -15%;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
`;

const Row1 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  p {
    width: 15vw;
    text-align: center;
    font-family: 'MuseoSans-500';
    font-size: 1.4rem;
    color: #707070;
    @media screen and (max-width: 1366px) {
      font-size: 1rem;
    }
  }
`;

const Row2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const Count = ({ children, ...props }) => {
  return <CountUp {...props}>{children}</CountUp>;
};

const StyledCountUp = styled(Count)`
  font-family: 'MuseoSans-700';
  font-size: 3.5rem;
  color: #fff;
  width: 15vw;
  text-align: center;
  @media screen and (max-width: 1366px) {
    font-size: 2.2rem;
  }
`;

const OrangeSlash = styled.p`
  color: #ff5c0b;
  font-size: 3rem;
  position: relative;
  line-height: 0;
  @media screen and (max-width: 1366px) {
    font-size: 2.2rem;
  }
`;

const InvisibleSlash = styled.h1`
  color: #ff5c0b;
  font-size: 3rem;
  position: relative;
  opacity: 0;
  line-height: 0;
  @media screen and (max-width: 1366px) {
    font-size: 1.8rem;
  }
`;

export function DesktopProfileBox() {
  return (
    <Container>
      <Row1>
        <p>EXPERIENCE (yrs)</p>
        <InvisibleSlash>/</InvisibleSlash>
        <p>PROJECT CONSULTING (cases)</p>
        <InvisibleSlash>/</InvisibleSlash>

        <p>SUPPORTED CONTRACT VOLUME</p>
        <InvisibleSlash>/</InvisibleSlash>

        <p>LONG-STANDING RELATIONSHIP WITH CLIENTS (avg. yrs)</p>
      </Row1>
      <Row2>
        <StyledCountUp
          start={0}
          end={25}
          duration={5}
          separator=' '
          decimal=','
          suffix='+ '
        />
        <OrangeSlash>/</OrangeSlash>
        <StyledCountUp
          start={0}
          end={30}
          duration={5}
          separator=' '
          suffix='+ '
        />
        <OrangeSlash>/</OrangeSlash>

        <StyledCountUp
          start={0}
          end={90}
          duration={2}
          separator=','
          prefix='$'
          suffix='M'
        />
        <OrangeSlash>/</OrangeSlash>

        <StyledCountUp
          start={0}
          end={10}
          duration={5}
          separator=','
          suffix='+ '
        />
      </Row2>
    </Container>
  );
}
