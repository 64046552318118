import React from 'react';
import { connect } from 'react-redux';

import { PageLayout } from 'components/common';
import styled from 'styled-components';
import { Button } from 'components/common';
import { Link } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import MaxarImg from 'images/landing/maxar_image.png';
import MdaImg from 'images/landing/mda_image.png';
import EgeosImg from 'images/landing/egeos_image.png';
import OrbitalImg from 'images/landing/orbitalinsight_image.png';
import PlanetImg from 'images/landing/planet_image.png';
import SiisImg from 'images/landing/siis_image.png';
import Background from 'images/landing/landing_background.png';

const Container = styled.div`
  padding: 5rem 0;
  width: 100%;
  height: auto;
  background-image: url(${Background});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  > h1 {
    font-family: 'MuseoSans-700';
    color: #fff;
    width: 55vw;
    font-size: 4rem;
    text-align: center;
  }
`;

const DisplayBoxWrapper = styled.div`
  height: auto;
  width: 60vw;
  margin: 1.5rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const RevealWrapper = styled.div`
  position: relative;
  transition: all 0.5s ease;
  > img {
    height: 25.5rem;
    width: 25.5rem;
  }
`;

const ProductList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #3c3c3c;
  height: 25rem;
  width: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const DisplayBox = styled.div`
  float: left;
  position: relative;
  overflow: hidden;
  height: 25rem;
  width: 25rem;
  margin: 2rem;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
  border-radius: 1rem;
  &:hover ${RevealWrapper} {
    transform: translate(-105%);
  }
`;

// const StyledLink = styled(Link)`
//   font-family: 'MuseoSans-700';
//   font-size: 1.6rem;
//   text-decoration: none;
//   color: ${p => (p.viewmore ? '#63d9d7' : '#fff')};
//   &:hover {
//     color: #ff5c0b;
//   }
// `;

class Landing extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    console.log('landing', this.props.fetchedProducts.isFetching);
    return (
      <PageLayout>
        {this.props.fetchedProducts.isFetching ||
        this.props.fetchedCompanies.isFetching ? (
          <Loader
            active
            inline='centered'
            size={'massive'}
            style={{ margin: '40rem auto' }}
          >
            Loading Page
          </Loader>
        ) : (
          <Container>
            <h1>
              The venue where your order and offer meet and get custom-tailored.
            </h1>
            <DisplayBoxWrapper>
              <DisplayBox>
                <ProductList>
                  {/* <StyledLink>WorldView 2</StyledLink>
              <StyledLink>WorldView 3</StyledLink>
              <StyledLink>WorldView Legion 1-6</StyledLink>
              <StyledLink>SecureWatch</StyledLink>
              <StyledLink>EIVS</StyledLink>
              <StyledLink viewmore>View More</StyledLink> */}
                </ProductList>
                <RevealWrapper>
                  <img src={MaxarImg} alt='maxar' />
                  <p>products</p>
                </RevealWrapper>
              </DisplayBox>
              <DisplayBox>
                <RevealWrapper>
                  <img src={MdaImg} alt='mda' />
                </RevealWrapper>
              </DisplayBox>
              <DisplayBox>
                <RevealWrapper>
                  <img src={EgeosImg} alt='Egeos' />
                </RevealWrapper>
              </DisplayBox>
              <DisplayBox>
                <RevealWrapper>
                  <img src={OrbitalImg} alt='orbital insight' />
                </RevealWrapper>
              </DisplayBox>
              <DisplayBox>
                <RevealWrapper>
                  <img src={PlanetImg} alt='Planet' />
                </RevealWrapper>
              </DisplayBox>
              <DisplayBox>
                <RevealWrapper>
                  <img src={SiisImg} alt='Siis' />
                </RevealWrapper>
              </DisplayBox>
            </DisplayBoxWrapper>
            <Link to='/marketplace'>
              <Button>GO TO MARKETPLACE</Button>
            </Link>
          </Container>
        )}
      </PageLayout>
    );
  }
}

const mapStateToProps = state => ({
  fetchedProducts: state.fetchedProducts,
  fetchedCompanies: state.fetchedCompanies
});

export default connect(
  mapStateToProps,
  null
)(Landing);
