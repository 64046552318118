import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Link as ReactRouterDomLink,
  useLocation,
  useHistory
} from 'react-router-dom';
import { auth } from '../../../auth/authService';
import LogoSrc from 'images/Logo-2x.png';
import { Button, ContactUsModal } from 'components/common';
import { toast } from 'react-toastify';
import ProductModalForm from '../ProductModalForm';

const HeaderWrapper = styled.header`
  width: 100%;
  height: 11.6rem;
  display: flex;
  position: fixed;
  top: 0;
  background-color: #1e1e1e;
  justify-content: center;
  align-items: center;
  z-index: 999;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
`;

const Menu = styled.nav`
  width: 136.7rem;
  display: flex;
  position: relative;
  margin: auto;
  align-items: center;
`;

const Link = ({ isActive, children, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};

const Nav = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  font-family: 'MuseoSans-700';
  font-size: 1.6rem;
  padding: 0 2.75rem;
  /* height: 1.6rem; */
  text-decoration: none;
  color: ${p => (p.isActive ? '#ff5c0b' : '#fff')};
  flex: none;

  &:hover {
    color: #ff5c0b;
  }
`;

const Logo = styled.img`
  width: 17rem;
  height: 4.2rem;
`;

const RequestQuoteLink = styled.a`
  font-family: 'MuseoSans-700';
  font-size: 1.6rem;
  padding: 0 2.75rem;
  /* height: 1.6rem; */
  text-decoration: none;
  color: ${p => (p.isActive ? '#ff5c0b' : '#fff')};
  flex: none;
  cursor: pointer;

  &:hover {
    color: #ff5c0b;
  }
`;

const ContactUsLink = styled.a`
  font-family: 'MuseoSans-700';
  font-size: 1.6rem;
  padding: 0 2.75rem;
  text-decoration: none;
  color: ${p => (p.isActive ? '#ff5c0b' : '#fff')};
  flex: none;
  cursor: pointer;

  &:hover {
    color: #ff5c0b;
  }
`;

export function DesktopNavbar() {
  const { pathname } = useLocation();
  let history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [isQuoteModalOpen, setQuoteModalOpen] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('isAuthenticated')) {
      setAuthenticated(true);
    }
  });
  function handleClick(e) {
    e.preventDefault();
    console.log('iwascalled');
    auth
      .signOut()
      .then(res => {
        console.log(res);
        toast.success('Sign out successful!');
        localStorage.removeItem('isAuthenticated');
        window.location.reload();
      })
      .catch(err => console.log(err));
  }
  function redirect() {
    console.log('redirecting');

    history.push('/login');
  }
  console.log(isAuthenticated);
  return (
    <>
      <HeaderWrapper>
        <Menu>
          <Link to='/' style={{ margin: '0 2rem' }}>
            <Logo src={LogoSrc} />
          </Link>
          <Nav>
            <StyledLink
              to='/marketplace'
              isActive={pathname === '/marketplace'}
            >
              Information Post
            </StyledLink>
            <RequestQuoteLink
              onClick={() => {
                if (isAuthenticated) {
                  setQuoteModalOpen(true);
                } else {
                  console.log('iwas called');
                  redirect();
                }
              }}
            >
              Request More
            </RequestQuoteLink>
            <StyledLink to='/partner' isActive={pathname === '/partner'}>
              Become a Member
            </StyledLink>
            <StyledLink to='/howto' isActive={pathname === '/howto'}>
              How to NOAH
            </StyledLink>
            <StyledLink to='/company' isActive={pathname === '/company'}>
              About Us
            </StyledLink>
            {/* <Button
              onClick={() => setOpen(true)}
              style={{ margin: '0 2.75rem' }}
            >
              CONTACT US
            </Button> */}

            <ContactUsLink onClick={() => setOpen(true)}>
              Contact Us
            </ContactUsLink>
            {localStorage.getItem('isAuthenticated') ? (
              <Button onClick={handleClick} style={{ margin: '0 2.75rem' }}>
                Logout
              </Button>
            ) : (
              <Link to='/login'>
                <Button style={{ margin: '0 2.75rem', width: '12rem' }}>
                  Sign In
                </Button>
              </Link>
            )}
            {/* <Link to='/login'>
              <Button style={{ margin: '0 2.75rem' }}>Sign In</Button>
            </Link> */}
          </Nav>
        </Menu>
      </HeaderWrapper>
      <ContactUsModal open={isOpen} handleClose={setOpen}></ContactUsModal>
      <ProductModalForm
        open={isQuoteModalOpen}
        handleClose={() => setQuoteModalOpen(false)}
      ></ProductModalForm>
    </>
  );
}
