import React, { Component } from 'react';
import styled from 'styled-components';
import he from 'he';

const AccordionContainer = styled.div`
  width: 100%;
  padding: 0 14%;
  margin-bottom: 5rem;
`;
const AccordionTitle = styled.div`
  cursor: pointer;
  height: 7rem;
  width: 100%;
  padding: 0 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3c3c3c;

  h1 {
    color: #fff;
    font-size: 2rem;
    font-family: 'MuseoSans-700';
    margin: 0;
  }
`;

const AccordionArrow = styled.div`
  font-family: 'MuseoSans-700';
  font-size: 2rem;
  color: #63d9d7;
  transform: ${p => (p.open ? `rotate(270deg)` : `rotate(90deg)`)};
`;

const AccordionContent = styled.div`
  display: ${p => (p.open ? `block` : 'none')};
  padding: 3rem 4rem;
`;

const AccordionText = styled.p`
  text-align: left;
  color: #3c3c3c;
  font-size: 1.8rem;
  font-family: 'MuseoSans-300';
  margin: 0;
  line-height: 3rem;
`;

const AccordionImageContainer = styled.div`
  margin: 1.5rem auto;
  width: 100%;
  overflow-x: scroll;
`;

const AccordionImage = styled.img`
  margin-left: auto;
`;

export class ProductAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    if (this.props.index === 0) {
      this.setState({ open: true });
    }
  }

  handleAccordionClick = () => {
    this.setState({ open: !this.state.open });
  };

  accordionBody = body => {
    console.log(body);
    //body is an array with objects;
    return body.map((content, index) => {
      if ('accordionText' in content) {
        let str = content.accordionText.split('•').join('&nbsp;&nbsp;•');
        return (
          <AccordionText style={{ whiteSpace: 'pre-line' }}>
            {he.decode(str)}
          </AccordionText>
        );
      } else if ('accordionImagesUrls' in content) {
        return content.accordionImagesUrls.map((image, index) => {
          return (
            <AccordionImageContainer>
              <AccordionImage src={image.url} alt='accordion image' />
            </AccordionImageContainer>
          );
        });
      }
    });

    // if ('accordionText' in body) {
    //   const text = this.props.body[0].accordionText || null;
    //   return (
    //     <AccordionContentText open={this.state.open}>
    //       <p style={{ whiteSpace: 'pre-line' }}>{he.decode(text)}</p>
    //     </AccordionContentText>
    //   );
    // }
  };

  render() {
    console.log(this.props.body);
    const text = this.props.body[0].accordionText || null;
    return (
      <AccordionContainer>
        <AccordionTitle onClick={this.handleAccordionClick} open={false}>
          <h1>{this.props.title}</h1>
          <AccordionArrow open={this.state.open}>&#10095;</AccordionArrow>
        </AccordionTitle>
        <AccordionContent open={this.state.open}>
          {this.accordionBody(this.props.body)}
        </AccordionContent>
      </AccordionContainer>
    );
  }
}
