import React, { Component } from 'react';
import styled from 'styled-components';
import {
  CardLogo,
  CardProductList,
  CardProductImage
} from 'components/common/';

import { Loader } from 'semantic-ui-react';

const Container = styled.div`
  display: ${props => (props.isLoading ? 'none' : 'flex')};
  min-width: 50rem;
  max-width: 100rem;
  background-color: #fff;
  height: 34rem;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
  margin-bottom: 3rem;
`;

const GridLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-width: 23rem;
  max-width: 27rem;
  width: 25%;

  /* background-color: red; */
`;

const GridLeft_1 = styled.div`
  width: 100%;
  height: 10.5rem;
  /* background-color: #fff; */
`;
const GridLeft_2 = styled.div`
  width: 100%;
  height: 25.5rem;
  /* background-color: whitesmoke; */
`;

const GridRight = styled.div`
  /* background-color: green; */
  width: 75%;
`;

export class MarketplaceCard extends Component {
  state = {
    index: 0,
    isLoading: true
  };

  componentDidMount() {
    // console.log(this.props.company.companyName, 'componentMount');
    this.setState({
      index: 0
    });
  }
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      index: 0
    });
    // console.log(this.props.company.companyName, 'will receive props');
  }

  // static getDerivedStateFromProps(props, state) {
  //   return (state = {
  //     index: 0
  //   });
  // }

  componentDidUpdate() {
    // console.log(this.props.company.companyName, 'updated');
  }

  componentWillUnmount() {
    this.setState({
      index: 0
    });
  }

  handleProductImageChange = e => {
    const index = parseInt(e.target.name);

    this.setState({
      index
    });
  };

  getImageUrls = () => {
    const { products } = this.props;
    // console.log(products);
    const productSampleUrls = products.map(product => {
      return product.productSamples[0].url;
    });
    // console.log(productSampleUrls);
    return productSampleUrls;
  };
  render() {
    // console.log('MarketplaceCard', this.props);

    const { company, products } = this.props;
    const productSampleUrls = this.getImageUrls();
    // console.log(productSampleUrls);
    // console.log(this.props.isLoading, company.companyName);
    return (
      <Container isLoading={this.props.isLoading}>
        <GridLeft>
          <GridLeft_1>
            <CardLogo
              companyName={company.companyName}
              companyLogoUrl={company.companyLogo.url}
            />
          </GridLeft_1>
          <GridLeft_2>
            <CardProductList
              productList={products}
              handleClick={this.handleProductImageChange.bind(this)}
            />
          </GridLeft_2>
        </GridLeft>
        <GridRight>
          <CardProductImage
            companyName={company.companyName}
            index={this.state.index}
            urls={productSampleUrls}
            products={products}
          />
        </GridRight>
      </Container>
    );
  }
}
