import React from 'react';
import styled from 'styled-components';
import { Header, Footer } from './index';

const Content = styled.main`
  margin-top: 11.6rem;
  width: 100vw;
  overflow-x: hidden;
`;

export function PageLayout({ children }) {
  // console.log(children);
  return (
    <>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </>
  );
}
