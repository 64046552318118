import { GET_ALL_COMPANIES } from './adminActions';

export function adminReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_COMPANIES:
      return action.companies;
    default:
      return state;
  }
}
