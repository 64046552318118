import React, { Component } from 'react';
import { db, timeStamp } from '../../../auth/authService';
import AdminPageLayout from './AdminPageLayout';
import './style.css';

import {
  Dimmer,
  Loader,
  List,
  Header,
  Table,
  Image,
  Button
} from 'semantic-ui-react';

import styled from 'styled-components';

const StyledCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default class RequestMore extends Component {
  constructor(props) {
    super();

    this.state = { requestMoreList: [] };
  }
  componentDidMount() {
    db.collection('quotes')
      .orderBy('createdAt', 'desc')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.setState({
            requestMoreList: [...this.state.requestMoreList, doc.data()]
          });
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    const { requestMoreList } = this.state;
    return (
      <AdminPageLayout>
        <Header>Request More List</Header>
        <Table celled textAlign={'center'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <Table.HeaderCell>Selected Products</Table.HeaderCell>

              <Table.HeaderCell>createdAt</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {requestMoreList.map((request, index) => {
              let createdAt = request.createdAt.toDate().toDateString();

              return (
                <Table.Row key={index + 'row'}>
                  <Table.Cell key={index + 'name'} selectable>
                    {request.name}
                  </Table.Cell>
                  <Table.Cell key={index + 'email'} selectable>
                    {request.email}
                  </Table.Cell>
                  <Table.Cell key={index + 'message'} selectable>
                    <p style={{ whiteSpace: 'pre-line' }}>{request.message}</p>
                  </Table.Cell>
                  <Table.Cell key={index + 'selectedProducts'} selectable>
                    <ul>
                      {request.selectedProducts.map(product => {
                        return <li>{product}</li>;
                      })}
                    </ul>
                  </Table.Cell>
                  <Table.Cell key={index + 'createdAt'} selectable>
                    {createdAt}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </AdminPageLayout>
    );
  }
}
