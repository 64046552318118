import React, { Component } from 'react';
import styled from 'styled-components';

const SlideContainer = styled.div`
  /* height: 65vh; */
  width: 100%;
  background-color: #1e1e1e;
  position: absolute;
`;
const Slide = styled.div`
  width: 100%;
  img {
    margin-top: 13vh;

    height: 60vh;
    width: 100%;
    object-fit: cover;
    display: none;
  }
`;

const SlideButtons = styled.div`
  height: 5rem;
  text-align: center;
  padding: 3rem 0;
  background-color: ${props => (props.isBlack ? '#1e1e1e' : '#fff')};

  a {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0 1rem;
    background-color: ${props => (props.isBlack ? '#1e1e1e' : '#fff')};
    border-radius: 50%;
    display: ${props => (props.show ? 'inline-block' : 'none')};
  }
`;

export class HeaderImage extends Component {
  constructor(props) {
    super(props);
    this.refsArray = [];
    this.refsButtons = [];
    this.state = {
      slideIndex: 0
    };
  }

  componentDidMount() {
    this.showSlide();
  }

  componentDidUpdate() {
    this.showSlide();
  }

  showSlide = () => {
    this.refsArray.forEach(ref => (ref.style.display = 'none'));
    this.refsArray[this.state.slideIndex].style.display = 'block';

    this.refsButtons.forEach(ref => (ref.style.backgroundColor = '#6e6d6d'));
    this.refsButtons[this.state.slideIndex].style.backgroundColor = '#D0D0D0';
  };

  onClick = e => {
    console.log(e.target.name);
    this.setState({
      slideIndex: parseInt(e.target.name)
    });
  };
  render() {
    console.log(this.state);

    return (
      <SlideContainer>
        {this.props.productUrls.map((img, index) => {
          return (
            <Slide key={'slide' + index}>
              <img
                key={'img' + index}
                src={img}
                alt='sample photo'
                ref={ref => (this.refsArray[index] = ref)}
              />
            </Slide>
          );
        })}
        <SlideButtons
          isBlack={this.props.location === 'seller'}
          show={this.props.productUrls.length > 1}
        >
          {this.props.productUrls.map((img, index) => {
            return (
              <a
                key={'button' + index}
                name={index}
                onClick={this.onClick}
                ref={ref => (this.refsButtons[index] = ref)}
              ></a>
            );
          })}
        </SlideButtons>
      </SlideContainer>
    );
  }
}
