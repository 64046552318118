import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import '../../font/MyFontsWebfontsKit/MyFontsWebfontsKit.css';

import {
  PageLayout,
  Button,
  Divider,
  ProductHeader,
  HeaderImage,
  ProductAccordion,
  ProductCarousel
} from 'components/common';
import ProductModalForm from '../common/ProductModalForm';

import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
  height: 80vh;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Main = styled.div`
  width: 100%;
  padding: 2rem 14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  h1 {
    font-size: 4rem;
    font-family: 'MuseoSans-500';
    color: #000;
    margin-bottom: 4rem;
  }
  p {
    text-align: center;
    font-size: 2rem;
    font-family: 'MuseoSans-500';
    color: #000;
    margin-bottom: 5rem;
  }
`;

class Product extends Component {
  constructor(props) {
    super(props);
    this.refsArray = [];
    this.state = {
      accordionOpen: true,
      modalOpen: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleAccordionClick = () => {};

  handleModal = () => {
    this.setState({
      modalOpen: true
    });
  };
  handleCloseModal = () => {
    this.setState({
      modalOpen: false
    });
  };

  render() {
    const urlName = this.props.match.params.productName;
    const { fetchedProducts, fetchedCompanies } = this.props;

    if (
      fetchedProducts.isFetching === false &&
      fetchedCompanies.isFetching === false
    ) {
      const currentProduct = fetchedProducts.products.filter(
        product => product.productName === urlName
      );

      const currentCompany = fetchedCompanies.companies.filter(company => {
        return company.companyName === currentProduct[0].companyName;
      });
      const {
        productName,
        productOverview,
        productDetails,
        productSamples
      } = currentProduct[0];
      const { companyLogo, companyName } = currentCompany[0];
      const otherProducts = fetchedProducts.products
        .filter(product => product.companyName === companyName)
        .filter(product => product.productName !== productName);

      const productSampleUrls = productSamples.map(product => {
        return product.url;
      });
      return (
        <PageLayout>
          <Header>
            {/* <SellerHeader logo={companyLogoUrl} products={productList} />
            <HeaderImage productUrls={productUrls} /> */}
            <ProductHeader
              allProducts={fetchedProducts}
              logo={companyLogo.url}
              productName={productName}
              companyName={companyName}
            />
            <HeaderImage productUrls={productSampleUrls} />
          </Header>
          <Main>
            <h1>{productName}</h1>
            <Divider style={{ marginBottom: '4rem' }} />
            <p style={{ whiteSpace: 'pre-line' }}>{productOverview}</p>
          </Main>
          {productDetails.map((item, index) => {
            console.log(item);
            return (
              <ProductAccordion
                key={'accordion-' + index}
                title={item.accordionTitle}
                body={item.accordionBody}
                index={index}
              />
            );
          })}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={this.handleModal}
              style={{ marginBottom: '20rem' }}
            >
              Request A Quote
            </Button>
          </div>

          <ProductCarousel otherProducts={otherProducts} />
          <ProductModalForm
            allProducts={null}
            productName={productName}
            open={this.state.modalOpen}
            handleClose={this.handleCloseModal}
          />
        </PageLayout>
      );
    } else {
      return (
        <PageLayout>
          <Loader
            active
            inline='centered'
            size={'massive'}
            style={{ margin: '40rem auto' }}
          >
            Loading Page
          </Loader>
        </PageLayout>
      );
    }
  }
}

const mapStateToProps = state => ({
  fetchedProducts: state.fetchedProducts,
  fetchedCompanies: state.fetchedCompanies
});

export default connect(
  mapStateToProps,
  {}
)(Product);
