import { auth } from '../auth/authService';

export const loginSuccess = () => {
  return {
    type: 'LOGIN_SUCCESS',
    currentUser: auth.currentUser.toJSON()
  };
};

export function login(email, password) {
  return async dispatch => {
    try {
      console.log('iwas called');
      await auth.signInWithEmailAndPassword(email, password);
      dispatch(loginSuccess());
      console.log('i was dispatched');
    } catch (error) {
      console.log('some error occured');
      throw error;
    }
  };
}

export const logout = () => async dispatch => {
  try {
    await auth.signOut();
    dispatch({ type: 'LOGOUT', currentUser: auth.currentUser });
  } catch (error) {
    throw error;
  }
};

export const signup = () => {};

export const fetchUser = () => async dispatch => {
  try {
    await auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        localStorage.setItem('isAuthenticated', true);
        dispatch({
          type: 'FETCH_USER',
          currentUser: currentUser.toJSON()
        });
      } else {
        localStorage.removeItem('isAuthenticated');
        dispatch({
          type: 'FETCH_USER',
          currentUser: null
        });
      }
    });
  } catch (error) {
    throw error;
  }
};
