import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { firebaseConfig } from '../firebaseConfig';

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const timeStamp = firebase.firestore.Timestamp;

const getAuthenticationStatus = () => {
  return localStorage.getItem('isAuthenticated');
};

export { auth, db, storage, getAuthenticationStatus, timeStamp };
