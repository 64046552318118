import React from 'react';
import { PageLayout } from 'components/common';
import styled from 'styled-components';

import { Button, Divider, ModalPartner } from 'components/common';
import HeaderImg from 'images/partner/become_member.jpg';

import MaxarLogo from 'images/partner/maxar_logo.png';
import MdaLogo from 'images/partner/mda_logo.png';
import OrbitalLogo from 'images/partner/orbital_logo.png';
import EgeosLogo from 'images/partner/egeos_logo.png';
import PlanetLogo from 'images/partner/planet_logo.png';
import SiisLogo from 'images/partner/siis_logo.png';

const Header = styled.div`
  width: 100vw;
  height: 50vh;
  background-image: url(${HeaderImg});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: relative;

  @media screen and (max-width: 460px) {
    font-size: 3rem;
    background: rgb(5, 3, 34);
    background: linear-gradient(
      180deg,
      rgba(5, 3, 34, 1) 0%,
      rgba(36, 36, 36, 1) 46%,
      rgba(52, 52, 52, 1) 86%
    );
  }

  > h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 80vw;
    text-align: center;
    font-family: 'MuseoSans-700';
    font-size: 5rem;
    color: #fff;
    @media screen and (max-width: 460px) {
      font-size: 3rem;
      line-height: 6rem;
    }
  }
`;

const ContentTitle = styled.div`
  width: 100%;
  padding: 13rem 12% 0rem 12%;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  > h1 {
    /* width: 50vw; */
    font-family: 'MuseoSans-500';
    font-size: 4rem;
    color: #fff;
    text-align: center;
  }
`;

const Content = styled.div`
  padding: 8rem 12%;
  width: 100%;
  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */

  > ul {
    padding-left: 2rem;
    /* width: 51rem; */
    height: 28.6rem;
    > li {
      font-family: 'MuseoSans-500';
      font-size: 1.8rem;
      color: #fff;
      margin-bottom: 2rem;
    }
  }
`;

const ContentElement = styled.div`
  width: 85vw;
  display: flex;
  justify-content: center;
  h2 {
    font-family: 'MuseoSans-500';
    color: #63d9d7;
    font-size: 2rem;

    margin-bottom: 3rem;
  }

  > ul {
    padding-left: 2rem;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    > li {
      font-family: 'MuseoSans-500';
      font-size: 1.8rem;
      color: #fff;
      margin-bottom: 2rem;
    }
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  padding: 5rem 12%;

  > h1 {
    text-align: center;
    font-family: 'MuseoSans-500';
    font-size: 4rem;
    color: #1e1e1e;

    margin: 2rem 0 2rem auto;
  }
`;
const LogoList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  > img {
    width: 40rem;
    @media screen and (max-width: 460px) {
      width: 200px;
    }
  }
`;

const LastContent = styled.div`
  padding-top: 10rem;
  height: 39.3rem;
  width: 100%;
  background-color: #1e1e1e;

  > h1 {
    margin: 0 auto;
    height: 4.3rem;
    text-align: center;
    font-family: 'MuseoSans-500';
    font-size: 4rem;
    color: #fff;
  }
`;

export default class Partner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    let { isOpen } = this.state;
    return (
      <PageLayout>
        <Header>
          <h1>We deliver information on Satellite Earth Observation</h1>
        </Header>
        <ContentTitle>
          <h1 style={{ margin: '2rem 0' }}>WHY BE A MEMBER AT NOAH?</h1>
          <Divider style={{ margin: '2rem 0 0 0' }} />
        </ContentTitle>
        <Content>
          <ContentElement>
            {/* <h2>SALES AGENTS</h2> */}
            <ul>
              <li>
                You can join the NOAH Information Post where boGO’s Clients
                search for information.
              </li>
              <li>You can further advertise your product through NOAH.</li>
              <li>
                You can reach out to the Clients in association with boGO
                Consulting.
              </li>
            </ul>
          </ContentElement>
          {/* <ContentElement>
            <h2>SELLERS</h2>
            <ul>
              <li>
                By playing the consulting roles in collaboration with NOAH, the
                ndependent sales agent can expand its profit model.
              </li>
            </ul>
          </ContentElement> */}
        </Content>
        <LogoWrapper>
          <h1>OUR MEMBERS</h1>
          <LogoList>
            <img src={MaxarLogo} alt='maxar logo' />
            <img src={MdaLogo} alt='mda logo' />
            <img src={EgeosLogo} alt='egeos logo' />
            <img src={OrbitalLogo} alt='orbital logo' />
            <img src={PlanetLogo} alt='planet logo' />
            <img src={SiisLogo} alt='siis logo' />
          </LogoList>
        </LogoWrapper>
        <LastContent>
          <h1>BECOME A MEMBER</h1>
          <Button
            className='button'
            onClick={() => this.setState({ isOpen: true })}
            style={{ display: 'block', margin: '8rem auto', width: '16rem' }}
          >
            JOIN TODAY
          </Button>
          <ModalPartner open={isOpen} onClose={this.handleClose}></ModalPartner>
        </LastContent>
      </PageLayout>
    );
  }
}
