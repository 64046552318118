import React, { useEffect } from 'react';
// import { Button } from 'components/common/';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//firebase
import { auth, db } from '../auth/authService';

// Redux
import { connect } from 'react-redux';
import { fetchUser } from '../redux/authActions';
import { fetchProducts, fetchCompanies } from '../redux/marketplaceActions';

import Landing from 'components/pages/Landing';
import Company from 'components/pages/Company';
import HowTo from 'components/pages/HowTo';
import Marketplace from 'components/pages/Marketplace';
import Partner from 'components/pages/Partner';
import Quote from 'components/pages/Quote';
import SignIn from 'components/pages/SignIn';
import Login from 'components/pages/admin/Login';
import Admin from 'components/pages/admin/Admin';
import AddNewForm from 'components/pages/admin/AddNewForm';
import AddNewProduct from 'components/pages/admin/AddNewProduct';
import ProductDetailEdit from 'components/pages/admin/ProductDetailEdit';
import User from 'components/pages/admin/User';
import RequestMore from 'components/pages/admin/RequestMore';
import ContactUs from 'components/pages/admin/ContactUs';
import BecomeMember from 'components/pages/admin/BecomeMember';
import CompanyEdit from 'components/pages/admin/CompanyEdit';
import ProductEdit from 'components/pages/admin/ProductEdit';
import Edit from 'components/pages/admin/Edit';
import PrivateRoute from 'components/pages/admin/PrivateRoute';
import Seller from 'components/pages/Seller';
import Product from 'components/pages/Product';
// import PrivateRoute from 'components/pages/admin/PrivateRoute';
// import Admin from 'components/pages/admin/Admin';
const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  font-size: 62.5%;

  @media screen and (max-width: 780px) {
    font-size: 50%;
  }
  @media screen and (max-width: 460px) {
    font-size: 40%;
  }

 
  
}

body {
  overflow: scroll;
  font-family: 'MuseoSans-300';
  font-weight: 400;
  line-height: 1.6;
  background-color: #fff;
  margin: 0;
}


.admin {
  box-sizing: inherit;
  font-size: 20px;
}

.ui {
  font-size: 20px;
}

.MuiTypography-root {
  font-size: 2rem !important;
}
.MuiInputBase-input {
  line-height: 2.7rem !important;
  font-size: 2rem !important;
}
.MuiFormLabel-root {
  font-size: 2rem !important;
}
.MuiButton-label {
  font-size: 2rem !important;
}
.MuiButtonBase-root {
  font-size: 2rem !important;
}
.MuiSvgIcon-root {
  font-size: 2rem !important;

}

`;

function App() {
  useEffect(() => {
    auth.onAuthStateChanged(function(user) {
      console.log(user);
      if (user) {
        localStorage.setItem('isAuthenticated', true);

        db.collection('users')
          .doc(user.email)
          .get()
          .then(user => {
            console.log('iwascalled! db');
            console.log(user.data());
            // console.log(user.data().email);

            let name = user.data().firstName + ' ' + user.data().lastName;

            localStorage.setItem('user_email', user.data().email);
            localStorage.setItem('user_name', name);

            if (user.data().admin) {
              localStorage.setItem('isAdmin', true);
            }
          });
      } else {
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('isAdmin');
      }
    });
  });
  return (
    <div>
      <GlobalStyle />
      <BrowserRouter forceRefresh={false}>
        <Switch>
          <Route path='/howto' exact component={HowTo} />
          <Route path='/company' exact component={Company} />
          <PrivateRoute path='/quote' exact component={Quote} />
          <Route path='/partner' exact component={Partner} />
          <Route path='/login' exact component={SignIn} />
          <Route path='/marketplace' exact component={Marketplace} />
          <Route path='/marketplace/:companyName' exact component={Seller} />
          <Route
            path='/marketplace/:companyName/:productName'
            exact
            component={Product}
          />
          {/* <PrivateRoute path='/marketplace' exact component={Marketplace} />
          <PrivateRoute
            path='/marketplace/:companyName'
            exact
            component={Seller}
          />
          <PrivateRoute
            path='/marketplace/:companyName/:productName'
            exact
            component={Product}
          /> */}
          {/* <Route path='/marketplace/:companyName/aa:productName' exact component={Seller} /> */}
          <Route path='/admin/login' exact component={Login} />
          <PrivateRoute path='/admin' exact component={Admin} />
          <PrivateRoute path='/admin/add' exact component={AddNewForm} />
          <PrivateRoute path='/admin/company' exact component={CompanyEdit} />
          <PrivateRoute
            path='/admin/company/:companyName'
            exact
            component={AddNewProduct}
          />
          <PrivateRoute
            path='/admin/product/:productName'
            exact
            component={ProductDetailEdit}
          />
          <PrivateRoute path='/admin/product' exact component={ProductEdit} />
          <PrivateRoute path='/admin/user' exact component={User} />
          <PrivateRoute
            path='/admin/requestmore'
            exact
            component={RequestMore}
          />
          <PrivateRoute path='/admin/contactus' exact component={ContactUs} />
          <PrivateRoute
            path='/admin/becomemember'
            exact
            component={BecomeMember}
          />
          <PrivateRoute
            path='/admin/edit/:companyName'
            exact
            component={Edit}
          />
          <Route path='/' exact component={Company} />
        </Switch>
        <ToastContainer autoClose={3000} hideProgressBar />
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = state => ({
  fetchedProducts: state.fetchedProducts,
  fetchedCompanies: state.fetchedCompanies
});

export default connect(
  mapStateToProps,
  { fetchUser, fetchProducts, fetchCompanies }
)(App);
