import React, { Component } from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from './Button';
import ProductModalForm from './ProductModalForm';

const Box = styled.div`
  margin-top: 2.5vh;

  width: 75%;
  z-index: 2;
  @media screen and (max-width: 700px) {
    margin-top: 2rem;
    width: 50%;
  }
`;
const Container = styled.div`
  height: 11.8rem;
  padding: 1rem 2rem;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  @media screen and (max-width: 700px) {
    height: 100%;
    flex-direction: column;
    padding: 3rem 0;
  }
  h1 {
    margin: 0;
    font-family: 'MuseoSans-500';
    font-size: 2rem;
    @media screen and (max-width: 700px) {
      margin: 3rem 0;
    }
  }
  img {
    height: 6rem;
    width: 20rem;
  }
`;

const Link = ({ isActive, children, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};

export class ProductHeader extends Component {
  state = {
    modalOpen: false
  };
  handleModal = () => {
    this.setState({
      modalOpen: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false
    });
  };
  render() {
    const { logo, productName, companyName } = this.props;
    return (
      <>
        <Box>
          <Container>
            <Link to={`/marketplace/${companyName}`}>
              <img src={logo} alt='logo' />
            </Link>
            <h1>{productName}</h1>
            <Button onClick={this.handleModal}>REQUEST MORE</Button>
          </Container>
        </Box>
        <ProductModalForm
          allProducts={this.props.allProducts.products}
          productName={productName}
          open={this.state.modalOpen}
          handleClose={this.handleCloseModal}
        />
      </>
    );
  }
}
