import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { PageLayout } from 'components/common';
import styled from 'styled-components';
import { Button, Divider } from 'components/common';
import SearchImg from 'images/howto/search_image.png';
import FormImg from 'images/howto/form_image.png';
import ContractImg from 'images/howto/contract_image.png';
import NetworkImg from 'images/howto/network_image.png';

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const StepWrapper = styled.div`
  margin: 3rem auto;
  width: 80vw;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > img {
    width: 30vw;
    height: auto;
    object-fit: scale-down;
    @media screen and (max-width: 400px) {
      order: 1;
      width: 70vw;
    }
  }
`;

const Step = styled.div`
  width: 35vw;
  @media screen and (max-width: 400px) {
    width: 80vw;
    order: 2;
  }
  > h1 {
    margin: 4rem 0;
    height: 7rem;
    font-family: 'MuseoSans-900';
    font-size: 10rem;
    color: #000;
    opacity: 10%;
  }
  > h2 {
    font-family: 'MuseoSans-500';
    font-size: 4rem;
    color: #000;
    width: 30vw;
    @media screen and (max-width: 400px) {
      width: 50vw;
    }
    margin-bottom: 6rem;
  }

  > p {
    margin-top: 5rem;
    font-family: 'MuseoSans-500';
    font-size: 1.8rem;
    color: #000;
    margin-bottom: 5rem;
  }
`;

const GreyBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 66.4rem;
  background-color: #f4f4f4;
  top: 124rem;

  z-index: -1;
`;
export default function HowTo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <PageLayout>
      <Container>
        <GreyBackground />
        <StepWrapper>
          <Step>
            <h1>1</h1>
            <h2>SEARCH FOR SELLERS AND ITS PRODUCTS</h2>
            <Divider />
            <p>
              To be a client at NOAH, please sign up. After a careful
              consideration, we will notify you of your access status via email.
              <br />
              <br />
              Browse and search for the products and/or Sellers of your interest
              by selecting one of categories on Information Post page.
            </p>
            <Link to='/marketplace'>
              <Button>GO TO INFORMATION POST </Button>
            </Link>
          </Step>
          <img src={SearchImg} alt='Search' style={{ marginTop: '8rem' }} />
        </StepWrapper>
        <StepWrapper>
          <img src={FormImg} alt='Form' style={{ marginTop: '12rem' }} />
          <Step>
            <h1>2</h1>
            <h2>FILL IN REQUEST MORE</h2>
            <Divider />
            <p>If you</p>
            <p>
              (1) find the products you plan to purchase, or <br></br>
              <br></br>(2) would like to have more detailed info on the product
              or Seller,
            </p>
            <p>Fill in the Request More and submit.</p>
            <Button>OPEN REQUEST MORE</Button>
          </Step>
        </StepWrapper>
        <StepWrapper style={{ marginTop: '15rem' }}>
          <Step>
            <h1>3</h1>
            <h2>PROCEED WITH CONSULTING</h2>
            <Divider />
            <p>Upon your Request More,</p>
            <p>
              (1) Coordinate with the member companies (the Sellers) and sort
              out the appropriate candidate who can provide the product that you
              are looking for
              <br></br>
              <br></br>
              (2) Provide the Seller's commercial and technical proposal
            </p>
          </Step>
          <img src={ContractImg} alt='Contract' style={{ marginTop: '8rem' }} />
        </StepWrapper>
        <StepWrapper style={{ marginTop: '15rem' }}>
          <img src={NetworkImg} alt='Network' style={{ marginTop: '8rem' }} />
          <Step>
            <h1>4</h1>
            <h2>FOLLOW UP AFTER PURCHASE ORDER OR CONTRACT</h2>
            <Divider />
            <p>
              After the purchase order or contract, provide the follow-up on the
              Seller’s product quality or contract performance.
            </p>
          </Step>
        </StepWrapper>
      </Container>
    </PageLayout>
  );
}
