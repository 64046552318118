import React, { useEffect } from 'react';
import { db, getAuthenticationStatus } from '../../../auth/authService';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  // Add your own authentication on the below line.
  if (path.includes('admin')) {
    console.log('iwas called from admin route');
    console.log(localStorage);
    console.log(localStorage.getItem('isAdmin'));
    return (
      <Route
        {...rest}
        render={props =>
          localStorage.getItem('isAdmin') ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/marketplace', state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
  return (
    <Route
      {...rest}
      render={props =>
        getAuthenticationStatus() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
