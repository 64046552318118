import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  filterProducts,
  VisibilityFilters
} from '../../redux/marketplaceActions';

import styled from 'styled-components';

const CategoryBox = styled.div`
  padding: 1rem 2rem;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
  width: 25rem;
  height: ${p => (p.open ? '34rem' : '8rem')};
  position: relative;
  background-color: #fff;
  top: -5rem;
  @media screen and (max-width: 550px) {
  }
`;

const CheckedBox = styled.em`
  position: absolute;
  display: ${p => (p.checked ? 'inline' : 'none')};
  top: 0.76rem;
  left: 0.3rem;
  height: 1.4rem;
  width: 1.4rem;
  background-color: #ff5c0b;
`;

const CategoryList = styled.div`
  display: ${p => (p.accordionOpen ? 'block' : 'none')};
  label {
    font-family: 'MuseoSans-500';
    font-size: 1.8rem;
    color: #505050;
    display: block;
    position: relative;
    padding-left: 3rem;
    margin-bottom: 1rem;
    &:hover {
      span {
        background-color: #ccc;
      }
    }
  }
  input {
    position: absolute;
    /* top: 0.4rem;
    left: 0; */
    opacity: 0;
    cursor: pointer;
  }
  span {
    position: absolute;
    top: 0.49rem;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: #eee;
    &:hover {
      background-color: #ccc;
    }
  }
`;

const CategoryAccordion = styled.div`
  width: 21rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  h1 {
    font-family: 'MuseoSans-700';
    font-size: 2rem;
    color: #1e1e1e;
  }
`;
const AccordionArrow = styled.h1`
  font-family: 'MuseoSans-700';
  font-size: 2rem;
  color: #1e1e1e;
  transform: ${p => (p.open ? `rotate(90deg)` : '')};
`;

class CategoryBar extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      accordionOpen: true,
      categories: [
        'All',
        'Very High Resolution',
        'High Resolution',
        'Medium Resolution',
        'RADAR',
        'Platform Service',
        'Application'
      ],
      selectedCategory: 'All'
    };
  }
  // componentDidMount() {
  //   const { fetchedProducts, filterProducts } = this.props;
  //   if (Object.keys(fetchedProducts).length !== 0) {
  //     filterProducts(fetchedProducts.products, VisibilityFilters.SHOW_ALL);
  //   }
  // }
  handleCategoryChange = event => {
    const { value } = event.target;
    const { products } = this.props.fetchedProducts;
    this.setState({ selectedCategory: value });

    const { filterProducts } = this.props;
    if (value === 'All') {
      filterProducts(products, VisibilityFilters.SHOW_ALL);
    } else if (value === 'Very High Resolution') {
      filterProducts(products, VisibilityFilters.SHOW_VERY_HIGH_RESOL);
    } else if (value === 'High Resolution') {
      filterProducts(products, VisibilityFilters.SHOW_HIGH_RESOL);
    } else if (value === 'Medium Resolution') {
      filterProducts(products, VisibilityFilters.SHOW_MEDIUM_RESOL);
    } else if (value === 'RADAR') {
      filterProducts(products, VisibilityFilters.SHOW_RADAR);
    } else if (value === 'Platform Service') {
      filterProducts(products, VisibilityFilters.SHOW_PLATFORM_SERVICE);
    } else if (value === 'Application') {
      filterProducts(products, VisibilityFilters.SHOW_APPLICATION);
    } else {
      alert('something went wrong');
    }
  };
  handleAccordionChange = () => {
    const { accordionOpen } = this.state;
    this.setState({ accordionOpen: !accordionOpen });
  };
  render() {
    const { selectedCategory, categories, accordionOpen } = this.state;
    console.log(selectedCategory);
    return (
      <>
        <CategoryBox open={this.state.accordionOpen}>
          <CategoryAccordion onClick={this.handleAccordionChange}>
            <h1>CATEGORIES</h1>
            <AccordionArrow open={this.state.accordionOpen}>
              &#10095;
            </AccordionArrow>
          </CategoryAccordion>
          <CategoryList accordionOpen={accordionOpen}>
            {categories.map(category => {
              return (
                <label key={category}>
                  {category}
                  <input
                    type='radio'
                    checked={selectedCategory === category}
                    onChange={this.handleCategoryChange}
                    value={category}
                    key={category}
                  />
                  <span></span>
                  <CheckedBox
                    checked={selectedCategory === category}
                  ></CheckedBox>
                </label>
              );
            })}
          </CategoryList>
        </CategoryBox>
      </>
    );
  }
}

const mapStateToProps = state => ({
  fetchedProducts: state.fetchedProducts
});
export default connect(
  mapStateToProps,
  { filterProducts }
)(CategoryBar);
