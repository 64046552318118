import React, { Component } from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import styled from 'styled-components';

import { Divider } from 'components/common';
const Link = ({ isActive, children, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};
const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000000;
  /* color: #1e1e1e; */
  /* padding-left: 1rem; */
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
  &:hover {
    color: #000;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5rem 14%;
  h1 {
    font-family: 'MuseoSans-700';
    font-size: 2rem;
    margin-bottom: 2em;
  }
`;

const CardsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  height: 54vh;
  @media screen and (max-width: 780px) {
    height: 45vh;
  }
  @media screen and (max-width: 460px) {
    height: 43vh;
  }
`;
const Card = styled.div`
  margin: 0 2rem;
  padding: 0 2rem;
  background-color: #fff;
  width: 45rem;
  flex-shrink: 0;
  height: 45.52rem;
  display: flex;
  flex-direction: column;
  &:hover {
    transform: translateY(-5%);
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 22.76rem;
  object-fit: cover;
`;

const CardContent = styled.div`
  width: 100%;
  height: 22.76rem;
  display: flex;
  padding: 2.5rem;
  flex-direction: column;
  h1 {
    /* margin: 0; */
    font-family: 'MuseoSans-700';
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
  }
  p {
    /* margin: 0; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 2.5rem;
    font-family: 'MuseoSans-500';
    font-size: 1.8rem;
    overflow: hidden;
  }
`;

export class ProductCarousel extends Component {
  handleClick = () => {
    window.scrollTo(0, 0);
  };
  render() {
    if (this.props.otherProducts.length === 0) {
      return <></>;
    } else {
      const { otherProducts } = this.props;
      return (
        <OuterContainer>
          <h1>View other products from {otherProducts[0].companyName}</h1>
          <CardsContainer>
            {otherProducts.map((product, index) => {
              return (
                <Card key={'card' + index}>
                  <StyledLink
                    key={'link' + index}
                    to={`/marketplace/${product.companyName}/${product.productName}`}
                    onClick={this.handleClick}
                  >
                    <StyledImage
                      key={'image' + index}
                      src={product.productSamples[0].url}
                    />
                    <CardContent key={'card_content' + index}>
                      <h1 key={'h1' + index}>{product.productName}</h1>
                      <Divider key={'divider' + index} />
                      <p key={'p' + index}>{product.productOverview}</p>
                    </CardContent>
                  </StyledLink>
                </Card>
              );
            })}
          </CardsContainer>
        </OuterContainer>
      );
    }
  }
}

// <Card>
//   <StyledLink>
//     <StyledImage
//       src={this.props.otherProducts[0].productSampleUrls[0]}
//     />
//     <CardContent>
//       <h1>{this.props.otherProducts[0].productName}</h1>
//       <Divider />
//       <p>{this.props.otherProducts[0].productOverview}</p>
//     </CardContent>
//   </StyledLink>
// </Card>
