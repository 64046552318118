import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import ProductModalForm from '../common/ProductModalForm';

import { PageLayout } from 'components/common';
import styled from 'styled-components';
import { Button } from 'components/common';
import { Multiselect } from 'multiselect-react-dropdown';

const Container = styled.div`
  background-color: black;
  height: 100vh;
`;

export class Quote extends Component {
  render() {
    const { fetchedProducts, fetchedCompanies } = this.props;
    if (
      fetchedProducts.isFetching === false &&
      fetchedCompanies.isFetching === false
    ) {
      return (
        <PageLayout>
          <Container>
            <ProductModalForm
              allProducts={null}
              open={true}
              handleClose={() => console.log('do nothing')}
            />
          </Container>
        </PageLayout>
      );
    } else {
      console.log('loading');
      return (
        <PageLayout>
          <Loader
            active
            inline='centered'
            size={'massive'}
            style={{ margin: '40rem auto' }}
          >
            Loading Page
          </Loader>
        </PageLayout>
      );
    }
  }
}

const mapStateToProps = state => ({
  fetchedProducts: state.fetchedProducts,
  fetchedCompanies: state.fetchedCompanies
});

export default connect(
  mapStateToProps,
  {}
)(Quote);
