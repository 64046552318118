import React, { Component } from 'react';
import styled from 'styled-components';
import { Spinner } from './Spinner';
import { Link } from 'react-router-dom';

const Container = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; */
  position: relative;
  width: 100%;
  height: 100%;
`;

const ProductOverview = styled.div`
  width: 100%;
  display: ${props =>
    props.stateIndex === props.currentIndex && props.isLoading === false
      ? 'flex'
      : 'none'};

  position: absolute;
  top: 0;

  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  h1 {
    text-align: center;
    padding: 2rem;
    width: 40%;
    font-family: 'MuseoSans-700';
    font-size: 1.8rem;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.49);
  }
`;

const Overview = styled.div`
  position: relative;
  padding: 1rem 2rem;
  height: 6rem;
  background-color: rgba(0, 0, 0, 0.49);
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  h2 {
    width: 80%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: relative;
    line-height: 1.92rem;
    max-height: 3.84rem;
    height: 4rem;
    font-family: 'MuseoSans-700';
    font-size: 1.4rem;
    color: #fff;
    margin-right: 1rem;
    margin-bottom: 0;
    padding-right: 1rem;
  }
  a {
    text-align: center;
    font-size: 1.4rem;
    font-family: 'MuseoSans-700';
    color: #63d9d7;
    cursor: pointer;
  }
`;

const ProductImage = styled.img`
  display: ${props =>
    props.stateIndex === props.currentIndex && props.isLoading === false
      ? 'block'
      : 'none'};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// const LoadingImage = styled.div`
//   display: ${props => (props.isLoading === true ? 'block' : 'none')};
//   width: 100%;
//   height: 100%;
//   background-color: black;
//   z-index: 9;
// `;

export class CardProductImage extends Component {
  intervalID = 0;
  state = {
    images: null,
    isLoading: true
  };
  componentDidMount() {
    console.log('cardproductImage Mounted', this.props.urls);
    const { urls } = this.props;
    Promise.all(this.loadImages(urls)).then(urls => {
      this.setState({
        images: urls
      });
      this.intervalID = setTimeout(() => {
        this.setState({
          isLoading: false
        });
      }, 300);
    });
    // this.showImage();
  }
  UNSAFE_componentWillReceiveProps() {
    const { urls } = this.props;
    this.setState({
      isLoading: true
    });
    Promise.all(this.loadImages(urls)).then(urls => {
      this.setState({
        images: urls
      });
      this.intervalID = setTimeout(() => {
        this.setState({
          isLoading: false
        });
      }, 300);
    });
  }
  loadImages = urls => {
    return urls.map(url => {
      return new Promise(function(resolve, reject) {
        let img = new Image();
        img.src = url;
        img.onload = () => {
          resolve(url);
        };
        img.onerror = () => {
          reject('error');
        };
      });
    });
  };

  componentDidUpdate() {
    // clearInterval(this.intervalID);
    // this.showImage();
  }
  componentWillUnmount() {
    console.log('images will unmount');
    this.setState({
      images: null,
      isLoading: true
    });
    clearInterval(this.intervalID);
  }
  //   showImage = () => {
  //     const index = this.props.index;
  //     this.refsArray.forEach(ref => (ref.style.display = 'none'));
  //     this.refsArray[index].style.display = 'block';
  //   };
  //   renderImage() {
  //     const { images } = this.state;
  //     // this.refsArray.forEach();

  //     return images.map((image, index) => {
  //       return (
  //         <ProductImage src={image} ref={ref => (this.refsArray[index] = ref)} />
  //       );
  //     });
  //   }
  renderLoading() {
    if (this.state.isLoading) {
      return <h1>Loading Pic</h1>;
    } else {
      return null;
    }
  }
  render() {
    const { images } = this.state;
    const { products, companyName } = this.props;
    const productOverviews = products.map(product => {
      return product.productOverview;
    });
    const productNames = products.map(product => {
      return product.productName;
    });
    // console.log(images);
    // console.log(this.props.index);

    if (this.state.images) {
      return (
        <Container>
          {this.state.images.map((image, index) => {
            return (
              <React.Fragment key={`div` + index}>
                <ProductImage
                  key={`productImg` + index}
                  src={image}
                  stateIndex={this.props.index}
                  currentIndex={index}
                  isLoading={this.state.isLoading}
                />
                <ProductOverview
                  key={`productOverview` + index}
                  stateIndex={this.props.index}
                  currentIndex={index}
                  isLoading={this.state.isLoading}
                >
                  <h1 key={`h1` + index}>{productNames[index]}</h1>
                  <Overview key={`Overview` + index}>
                    <h2 key={`h2` + index}>{productOverviews[index]}</h2>
                    <Link
                      key={`Link` + index}
                      to={`/marketplace/${companyName}/${productNames[index]}`}
                    >
                      Learn More
                    </Link>
                  </Overview>
                </ProductOverview>

                {/* <LoadingImage /> */}
              </React.Fragment>
            );
          })}
          <Spinner isActive={this.state.isLoading}></Spinner>
        </Container>
      );
    } else {
      return <Spinner>loading</Spinner>;
    }
  }
}
