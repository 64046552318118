import React, { Component, useState } from 'react';
import { db, storage, timeStamp } from '../../../auth/authService';
import { toast } from 'react-toastify';
import { Button, Header, Image, Modal, Form, List } from 'semantic-ui-react';
import './style.css';
import uniqid from 'uniqid';

export default function CompanyEditModal(props) {
  const { companyName, data, type } = props;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState(data);
  const [array, setArray] = useState([]);
  const [logo, setLogo] = useState([]);

  console.log(value);
  console.log(array);
  console.log(logo);
  console.log(logo.type);

  const handleUpdate = async field => {
    alert('Under development. Consult your developer');
  };
  const handleSubmit = async e => {
    e.preventDefault();
    console.log('i was submitted', value);
    if (type === 'overview') {
      handleUpdate('companyOverview');
    } else if (type === 'companyName') {
      handleUpdate('companyName');
    } else if (type === 'productList') {
      handleUpdateProductList();
    } else if (type === 'companyLogo') {
      handleUpdateLogo();
    } else if (type === 'delete') {
      handleDelete();
    }
  };

  const handleUpdateProductList = async () => {
    let companyRef = await db.collection('companies');
    companyRef
      .where('companyName', '==', companyName)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data());
          companyRef
            .doc(doc.id)
            .update({
              productList: array
            })
            .then(() => {
              toast.success('data updated');
              setOpen(false);
              window.location.reload(true);
            })
            .catch(err => console.log(err));
        });
      })
      .catch(err => console.log(err));
  };

  const handleUpdateLogo = async () => {
    const uid = uniqid();
    let imageName = uid.concat(logo.name);
    const storageRef = storage.ref();
    var uploadTask = storageRef.child(`/images/` + imageName).put(logo);

    uploadTask.on(
      'state_changed',
      snapshot => {},
      error => {
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          let companyRef = db.collection('companies');
          companyRef
            .where('companyName', '==', companyName)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                companyRef
                  .doc(doc.id)
                  .update({
                    companyLogo: { url: downloadURL, ref: imageName }
                  })
                  .then(() => {
                    toast.success('data updated');
                    setOpen(false);
                    window.location.reload(true);
                  })
                  .catch(err => console.log(err));
              });
            })
            .catch(err => console.log(err));
          let deleteRef = storageRef.child('images/' + data.ref);
          deleteRef
            .delete()
            .then(() => {
              console.log('delete successful');
            })
            .catch(err => console.log(err));
        });
      }
    );
  };

  const handleDelete = () => {};

  if (type === 'overview') {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Edit</Button>}
      >
        <Modal.Header>Edit Company Overview</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <Form.TextArea
                style={{ height: '300px' }}
                label='Company Overview'
                // placeholder='Tell us more about you...'
                value={value}
                onChange={e => setValue(e.target.value)}
              />
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
              >
                Submit
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else if (type === 'companyName') {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Edit</Button>}
      >
        <Modal.Header>Edit Name</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <Form.Input
                // style={{ height: '300px' }}
                label='Company Name'
                // placeholder='Tell us more about you...'
                value={value}
                onChange={e => setValue(e.target.value)}
              />
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
              >
                Submit
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else if (type === 'productList') {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Edit</Button>}
      >
        <Modal.Header>Edit Order of Products</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <h1>Click on items in order you want</h1>
              <List>
                {data.map((product, index) => {
                  return (
                    <List.Item
                      style={{
                        padding: '10px',
                        fontSize: '15px',
                        backgroundColor: 'lightgrey',
                        textAlign: 'center',
                        marginBottom: '5px'
                      }}
                      onClick={() => {
                        console.log(product);
                        setValue(value.filter(item => item !== product));
                        setArray(state => [...state, product]);
                      }}
                      key={'product' + index}
                      disabled={!value.includes(product)}
                    >
                      {product}
                    </List.Item>
                  );
                })}
              </List>
              <h1>
                Updated list of products in order (Click on an item to remove):{' '}
              </h1>
              <List>
                {array.map((product, index) => {
                  return (
                    <List.Item
                      style={{
                        padding: '10px',
                        fontSize: '15px',
                        backgroundColor: 'lightblue',
                        textAlign: 'center',
                        marginBottom: '5px'
                      }}
                      onClick={() => {
                        console.log(product);
                        setValue(state => [...state, product]);
                        setArray(array.filter(item => item !== product));
                      }}
                      key={'productUpdated' + index}
                      // disabled={!value.includes(product)}
                    >
                      {product}
                    </List.Item>
                  );
                })}
              </List>
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
                disabled={array.length !== data.length}
              >
                Submit
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else if (type === 'companyLogo') {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Edit</Button>}
      >
        <Modal.Header>Replace Company Logo</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <h1>Current company logo:</h1>
            <Image src={data.url} />
            <hr></hr>
            <h1>
              Attach the new company logo. Note that this will delete the
              original logo.
            </h1>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <Form.Input
                type='file'
                name='logo'
                accept='image'
                onChange={e => setLogo(e.target.files[0])}
              />
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
                disabled={
                  logo.type !== 'image/png' &&
                  logo.type !== 'image/jpeg' &&
                  logo.type !== 'image/jpg'
                }
              >
                Submit
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else if (type === 'delete') {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>DELETE</Button>}
      >
        <Modal.Header>
          Delete the company. This will delete all the data associated with the
          company including the products.
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <h1>
              WARNING... This will completely delete all the data of the company
            </h1>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
              >
                DELETE
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
