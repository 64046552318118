import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/common';
import { toast } from 'react-toastify';
import { db, timeStamp } from '../../auth/authService';
import uniqid from 'uniqid';
import emailjs from 'emailjs-com';

const ModalDialog = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99999;
  opacity: ${props => (props.open ? '1' : '0')};

  -webkit-transition: opacity 100ms ease-in;
  -moz-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;
  position: relative;
  margin: 2.5% auto;
  padding: 5rem 10rem;
  border-radius: 3px;
  background: #434242;

  > a {
    position: absolute;
    right: 3rem;
    top: 2rem;
    font-size: 2rem;
    background: none;
    color: #fff;
    border: none;
    &:hover {
      color: #ff5c0b;
      cursor: pointer;
    }
  }
  > div {
    width: 40vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  > h1 {
    font-family: 'MuseoSans-500';
    font-size: 4rem;
    text-align: center;
    width: 30vw;
    color: #fff;
  }
  > p {
    font-family: 'MuseoSans-300';
    font-size: 1.6rem;
    text-align: center;
    width: 35vw;
    color: #fff;
  }
`;

const StyledField = styled.div`
  margin-top: 3.5rem;
  width: 30rem;
  height: 5rem;
  position: relative;
  margin-bottom: 1rem;
  padding: 0 2rem;
  @media screen and (max-width: 1500px) {
    width: 25rem;
  }
  > span {
    color: red;
    font-family: 'MuseoSans-300';
    font-size: 1.4rem;
    margin-top: 1rem;
  }
`;

const StyledInput = styled.input`
  float: left;
  font-size: 1.8rem;
  font-family: 'MuseoSans-300';
  padding: 1rem;
  display: block;
  width: 100%;
  color: #fff;
  background-color: transparent;

  border-style: none none solid none;
  border-width: 2px;
  border-color: ${props => (props.typed ? '#63d9d7' : '#fff')};

  &:focus {
    outline: 0;
    box-shadow: 0;
    border-style: none none solid none;
    border-width: 2px;
    border-color: #63d9d7;
  }
  &::placeholder {
    color: transparent;
  }

  &:focus ~ label {
    color: #63d9d7;
    top: -2rem;
    font-size: 1.2rem;
  }
`;

const StyledLabel = styled.label`
  color: ${props => (props.typed ? '#63d9d7' : '#fff')};
  position: absolute;
  font-size: ${props => (props.typed ? '1.2rem' : '1.8rem')};
  font-family: 'MuseoSans-300';
  left: 2rem;
  top: ${props => (props.typed ? '-2rem' : '0rem')};

  pointer-events: none;

  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
`;

const StyledTextField = styled.div`
  margin-top: 3.5rem;
  width: 100%;
  height: 35rem;
  position: relative;
  > span {
    display: inline-block;
    color: red;
    font-family: 'MuseoSans-300';
    font-size: 1.4rem;
  }
`;

const StyledTextLabel = styled.label`
  display: inline-block;
  margin: 1rem;
  color: #ff5c0b;
  font-size: 1.8rem;
  font-family: 'MuseoSans-300';

  pointer-events: none;
`;

const StyledTextArea = styled.textarea`
  float: left;
  font-size: 1.8rem;
  font-family: 'MuseoSans-300';
  padding: 1rem;
  display: block;
  width: 40vw;
  color: #fff;

  background-color: transparent;

  border-style: solid;
  border-width: 2px;
  border-color: ${props => (props.typed ? '#63d9d7' : '#fff')};
  resize: none;

  &:focus {
    outline: 0;
    box-shadow: 0;
    border-color: #63d9d7;
  }
  &::placeholder {
    color: transparent;
  }
`;

export class ModalPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      typed: {},
      formSubmitted: false
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    let fields = this.state.fields;
    let typed = this.state.typed;
    fields[name] = value;
    typed[name] = value.length !== 0;
    this.setState({ fields, typed });
    // console.log(this.state.typed);
  };

  handleSubmit = event => {
    console.log('handling submit', event);
    event.preventDefault();

    if (this.validateForm()) {
      let { firstName, lastName, email, company, message } = this.state.fields;
      let createdAt = timeStamp.now();
      let uid = uniqid();

      db.collection('partnerRequest')
        .doc(uid)
        .set({
          firstName,
          lastName,
          email,
          company,
          message,
          createdAt,
          ref: uid
        })
        .then(() => {
          this.setState({
            fields: {},
            errors: {},
            typed: {},
            formSubmitted: true
          });
          toast.success('form submitted');
        })
        .catch(err => console.log(err));
      this.props.onClose();
    }

    this.setState({
      formSubmitted: false
    });
  };

  validateForm() {
    console.log('validating');
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    //FIRST NAME VALIDATION
    if (!fields['firstName'] || fields['firstName'].trim().length === 0) {
      formIsValid = false;
      errors['firstName'] = '*Please enter your First Name.';
    }

    //LAST NAME VALIDATION
    if (!fields['lastName'] || fields['lastName'].trim().length === 0) {
      formIsValid = false;
      errors['lastName'] = '*Please enter your Last Name.';
    }

    //EMAIL VALIDATION
    if (!fields['email'] || fields['email'].trim().length === 0) {
      formIsValid = false;
      errors['email'] = '*Please enter your E-mail Address.';
    } else {
      if (!validEmailRegex.test(fields['email'])) {
        formIsValid = false;
        errors['email'] = '*Please enter a valid E-mail Address.';
      }
    }

    //COMPANY VALIDATION
    if (!fields['company'] || fields['company'].trim().length === 0) {
      formIsValid = false;
      errors['company'] = '*Please enter your Company Name.';
    }

    //MESSAGE VALIDATION
    if (!fields['message'] || fields['message'].trim().length === 0) {
      formIsValid = false;
      errors['message'] = '*Please enter a message.';
    }

    // console.log(errors);

    this.setState({
      errors: errors
    });

    // console.log(this.state);

    return formIsValid;
  }

  render() {
    let { typed } = this.state;
    return (
      <>
        <ModalDialog open={this.props.open}>
          <StyledForm onSubmit={this.handleSubmit}>
            <a type='close' onClick={this.props.onClose}>
              X
            </a>

            <h1>BECOME A PARTNER</h1>
            <p>
              To better locate you to the loyal customer, we welcome your
              fill-in of the form below and submit.
            </p>
            <div>
              <StyledField>
                <StyledInput
                  type='text'
                  name='firstName'
                  id='firstName'
                  placeholder=''
                  onChange={this.handleChange}
                  typed={typed.firstName}
                />
                <StyledLabel htmlFor='firstName' typed={typed.firstName}>
                  First Name
                </StyledLabel>
                {<span>{this.state.errors.firstName}</span>}
              </StyledField>
              <StyledField>
                <StyledInput
                  type='text'
                  name='lastName'
                  id='lastName'
                  placeholder=''
                  onChange={this.handleChange}
                  typed={typed.lastName}
                />
                <StyledLabel htmlFor='lastName' typed={typed.lastName}>
                  Last Name
                </StyledLabel>
                {<span>{this.state.errors.lastName}</span>}
              </StyledField>
              <StyledField>
                <StyledInput
                  type='text'
                  name='email'
                  id='email'
                  placeholder=''
                  onChange={this.handleChange}
                  typed={typed.email}
                />
                <StyledLabel htmlFor='email' typed={typed.email}>
                  E-mail Address
                </StyledLabel>
                {<span>{this.state.errors.email}</span>}
              </StyledField>
              <StyledField>
                <StyledInput
                  type='text'
                  name='company'
                  id='company'
                  placeholder=''
                  onChange={this.handleChange}
                  typed={typed.company}
                />
                <StyledLabel htmlFor='company' typed={typed.company}>
                  Company
                </StyledLabel>
                {<span>{this.state.errors.company}</span>}
              </StyledField>
              <StyledTextField>
                <StyledTextLabel htmlFor='message' typed={typed.message}>
                  MESSAGE
                </StyledTextLabel>
                <StyledTextArea
                  name='message'
                  id='message'
                  typed={typed.message}
                  onChange={this.handleChange}
                  cols='30'
                  rows='10'
                />
                {<span>{this.state.errors.message}</span>}
              </StyledTextField>
              <Button style={{ margin: '0 auto' }}>SUBMIT</Button>
            </div>
          </StyledForm>
        </ModalDialog>
      </>
    );
  }
}
