import { db } from '../auth/authService';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const REQUEST_COMPANIES = 'REQUEST_COMPANIES';
export const RECEIVE_COMPANIES = 'RECEIVE_COMPANIES';

export const setVisibilityFilter = filter => ({
  type: 'SET_VISIBILITY_FILTER',
  filter
});

export const VisibilityFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_VERY_HIGH_RESOL: 'SHOW_VERY_HIGH_RESOL',
  SHOW_HIGH_RESOL: 'SHOW_HIGH_RESOL',
  SHOW_MEDIUM_RESOL: 'SHOW_MEDIUM_RESOL',
  SHOW_RADAR: 'SHOW_RADAR',
  SHOW_PLATFORM_SERVICE: 'SHOW_PLATFORM_SERVICE',
  SHOW_APPLICATION: 'SHOW_APPLICATION'
};

export const filterProducts = (
  products,
  filter = VisibilityFilters.SHOW_ALL
) => dispatch => {
  switch (filter) {
    case VisibilityFilters.SHOW_ALL:
      return dispatch({ type: VisibilityFilters.SHOW_ALL, products });
    case VisibilityFilters.SHOW_VERY_HIGH_RESOL:
      return dispatch({
        type: VisibilityFilters.SHOW_VERY_HIGH_RESOL,
        products
      });
    case VisibilityFilters.SHOW_HIGH_RESOL:
      return dispatch({ type: VisibilityFilters.SHOW_HIGH_RESOL, products });
    case VisibilityFilters.SHOW_MEDIUM_RESOL:
      return dispatch({ type: VisibilityFilters.SHOW_MEDIUM_RESOL, products });
    case VisibilityFilters.SHOW_RADAR:
      return dispatch({ type: VisibilityFilters.SHOW_RADAR, products });
    case VisibilityFilters.SHOW_PLATFORM_SERVICE:
      return dispatch({
        type: VisibilityFilters.SHOW_PLATFORM_SERVICE,
        products
      });
    case VisibilityFilters.SHOW_APPLICATION:
      return dispatch({ type: VisibilityFilters.SHOW_APPLICATION, products });
    default:
      throw new Error('Unknown filter: ' + filter);
  }
};

export function fetchProducts() {
  return async dispatch => {
    dispatch({ type: REQUEST_PRODUCTS });
    try {
      let querySnapshot = await db.collection('products').get();
      let products = [];
      querySnapshot.forEach(doc => {
        products.push(doc.data());
      });
      dispatch({
        type: RECEIVE_PRODUCTS,
        products: products
      });
      dispatch({
        type: VisibilityFilters.SHOW_ALL,
        products: products
      });
    } catch (err) {
      throw err;
    }
  };
}
export function fetchCompanies() {
  return async dispatch => {
    dispatch({ type: REQUEST_COMPANIES });
    try {
      let querySnapshot = await db.collection('companies').get();
      let companies = [];
      querySnapshot.forEach(doc => {
        // companies.push(doc.data());
        console.log(doc.data());
        const rank = doc.data().companyRank - 1;
        companies[rank] = doc.data();
      });
      console.log(companies);
      dispatch({
        type: RECEIVE_COMPANIES,
        companies: companies
      });
    } catch (err) {
      throw err;
    }
  };
}
