import React, { Component } from 'react';
import Nav from './Nav';
import { Container } from 'semantic-ui-react';
import './style.css';

export default class AdminPageLayout extends Component {
  render() {
    return (
      <div className='admin'>
        <Nav />
        <Container>{this.props.children}</Container>
      </div>
    );
  }
}
