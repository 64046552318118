import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../font/MyFontsWebfontsKit/MyFontsWebfontsKit.css';
import he from 'he';

import {
  PageLayout,
  Button,
  Divider,
  SellerHeader,
  HeaderImage
} from 'components/common';
import styled from 'styled-components';

import { Loader } from 'semantic-ui-react';

const Header = styled.div`
  width: 100%;
  height: 80vh;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Main = styled.div`
  width: 100%;
  padding: 2rem 14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1e1e;

  h1 {
    font-size: 4rem;
    font-family: 'MuseoSans-500';
    color: #fff;
    margin-bottom: 4rem;
  }
  p {
    font-size: 2rem;
    font-family: 'MuseoSans-500';
    color: #fff;
    margin-bottom: 10rem;
  }
`;

class Seller extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const urlName = this.props.match.params.companyName;

    const { fetchedCompanies, fetchedProducts } = this.props;

    if (
      fetchedCompanies.isFetching === false &&
      fetchedProducts.isFetching === false
    ) {
      const currentCompany = fetchedCompanies.companies.filter(
        company => company.companyName === urlName
      );
      const {
        companyName,
        companyOverview,
        productList,
        companyLogo
      } = currentCompany[0];
      const filteredProducts = fetchedProducts.products.filter(p => {
        return p.companyName === companyName;
      });
      let productUrls = [];
      let orderedProducts = [];
      filteredProducts.forEach(product => {
        for (let i = 0; i < productList.length; i++) {
          if (productList[i] === product.productName) {
            productUrls[i] = product.productSamples[0].url;
          }
        }
      });
      console.log(filteredProducts);
      console.log(productUrls);
      // console.log(filteredProducts);
      return (
        <PageLayout>
          <Header>
            <SellerHeader
              companyName={companyName}
              logo={companyLogo.url}
              products={productList}
            />
            <HeaderImage productUrls={productUrls} location={'seller'} />
          </Header>
          <Main>
            <h1>{companyName}</h1>
            <Divider style={{ marginBottom: '4rem' }} />
            <p style={{ whiteSpace: 'pre-line' }}>
              {he.decode(companyOverview)}
            </p>
            <Link to={'/marketplace'}>
              <Button style={{ marginBottom: '8rem' }}>
                GO TO INFORMATION POST
              </Button>
            </Link>
          </Main>
        </PageLayout>
      );
    } else {
      console.log('loading');
      return (
        <PageLayout>
          {' '}
          <Loader
            active
            inline='centered'
            size={'massive'}
            style={{ margin: '40rem auto' }}
          >
            Loading Page
          </Loader>
        </PageLayout>
      );
    }
  }
}
const mapStateToProps = state => ({
  fetchedProducts: state.fetchedProducts,
  fetchedCompanies: state.fetchedCompanies
});

export default connect(
  mapStateToProps,
  {}
)(Seller);
