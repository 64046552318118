import { combineReducers } from 'redux';
import currentUser from './authReducer';

import { productsReducer as fetchedProducts } from './marketplaceReducer';
import { companiesReducer as fetchedCompanies } from './marketplaceReducer';
import { filterReducer as filteredProducts } from './marketplaceReducer';
import { adminReducer as admin } from './adminReducer';

const combinedReducers = combineReducers({
  currentUser,
  fetchedProducts,
  fetchedCompanies,
  filteredProducts,
  admin
});

export default combinedReducers;
