import { createStore, applyMiddleware, compose } from 'redux';
import combinedReducers from './combinedReducers';
import thunkMiddleware from 'redux-thunk';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunkMiddleware)
  // other store enhancers if any
);

export default function configureStore() {
  return createStore(combinedReducers, enhancer);
}
