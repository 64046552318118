import styled from 'styled-components';

const Divider = styled.div`
  border-bottom-color: #ff5c0b;
  width: 4rem;
  border-bottom-style: solid;
  border-width: 0.5rem;
`;

export { Divider };
