import React, { Component, useState } from 'react';
import { db, storage, timeStamp } from '../../../auth/authService';
import { toast } from 'react-toastify';
import {
  Button,
  Header,
  Image,
  Modal,
  Form,
  List,
  Dropdown
} from 'semantic-ui-react';
import './style.css';
import uniqid from 'uniqid';

export default function ProductEditModal(props) {
  const { productName, data, type } = props;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState(data);
  const [array, setArray] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [sample, setSample] = useState([]);

  console.log('original array', value);
  console.log('selected array', array);

  const categoryOptions = [
    {
      key: 'Very High Resolution',
      text: 'Very High Resolution',
      value: 'Very High Resolution'
    },
    {
      key: 'High Resolution',
      text: 'High Resolution',
      value: 'High Resolution'
    },
    {
      key: 'Medium Resolution',
      text: 'Medium Resolution',
      value: 'Medium Resolution'
    },
    {
      key: 'RADAR',
      text: 'RADAR',
      value: 'RADAR'
    },
    {
      key: 'Platform Service',
      text: 'Platform Service',
      value: 'Platform Service'
    },
    {
      key: 'Application',
      text: 'Application',
      value: 'Application'
    }
  ];

  const handleUpdate = async field => {
    let productRef = await db.collection('products');
    productRef
      .where('productName', '==', productName)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data());
          productRef
            .doc(doc.id)
            .update({
              [field]: value
            })
            .then(() => {
              toast.success('data updated');
              setOpen(false);
              window.location.reload(true);
            })
            .catch(err => console.log(err));
        });
      })
      .catch(err => console.log(err));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    console.log('type', type);
    console.log('i was submitted', value);
    if (
      type === 'productName' ||
      type === 'productCategory' ||
      type === 'productOverview'
    ) {
      console.log('updating');
      handleUpdate(type);
    } else if (type === 'productThumbnail') {
      console.log('thumbnail update');
      handleUpdateThumbnail();
    } else if (type === 'productSamples') {
      handleUpdateProductSamples();
    } else if (type === 'delete') {
      console.log('deleting product');
      handleDelete();
    } else if (type === 'addProductSample') {
      handleAddSample();
    }

    // else if (type === 'productSamples') {
    //   handleUpdateThumbnail();
    // } else if (type === 'companyLogo') {
    //   handleUpdateLogo();
    // } else if (type === 'delete') {
    //   handleDelete();
    // }
  };
  const handleAddSample = async () => {
    const uid = uniqid();
    let imageName = uid.concat(sample.name);
    const storageRef = storage.ref();
    var uploadTask = storageRef.child(`/images/` + imageName).put(sample);

    uploadTask.on(
      'state_changed',
      snapshot => {},
      error => {
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          let productRef = db.collection('products');
          productRef
            .where('productName', '==', productName)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                let productSamplesList = doc.data().productSamples;
                productSamplesList.push({ url: downloadURL, ref: imageName });
                productRef
                  .doc(doc.id)
                  .update({
                    productSamples: productSamplesList
                  })
                  .then(() => {
                    toast.success('data added');
                    setOpen(false);
                    window.location.reload(true);
                  })
                  .catch(err => console.log(err));
              });
            })
            .catch(err => console.log(err));
        });
      }
    );
  };

  const handleUpdateProductSamples = async () => {
    //delete the excluded ones
    const selectedList = array;
    const deleteList = value;

    deleteList.forEach(sample => {
      let sampleRef = storage.ref().child(`images/${sample.ref}`);
      sampleRef
        .delete()
        .then(() => {
          console.log('delete successful');
        })
        .catch(err => console.log(err));
    });

    let productRef = await db.collection('products');
    productRef
      .where('productName', '==', productName)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          productRef
            .doc(doc.id)
            .update({
              productSamples: selectedList
            })
            .then(() => {
              toast.success('data updated');
              setOpen(false);
              window.location.reload(true);
            })
            .catch(err => console.log(err));
        });
      });
  };

  const handleUpdateThumbnail = async () => {
    const uid = uniqid();
    let imageName = uid.concat(thumbnail.name);
    const storageRef = storage.ref();
    var uploadTask = storageRef.child(`/images/` + imageName).put(thumbnail);

    uploadTask.on(
      'state_changed',
      snapshot => {},
      error => {
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          let productRef = db.collection('products');
          productRef
            .where('productName', '==', productName)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                productRef
                  .doc(doc.id)
                  .update({
                    productThumbnailUrl: { url: downloadURL, ref: imageName }
                  })
                  .then(() => {
                    toast.success('data updated');
                    setOpen(false);
                    window.location.reload(true);
                  })
                  .catch(err => console.log(err));
              });
            })
            .catch(err => console.log(err));
          let deleteRef = storageRef.child('images/' + data.ref);
          deleteRef
            .delete()
            .then(() => {
              console.log('delete successful');
            })
            .catch(err => console.log(err));
        });
      }
    );
  };

  const handleDelete = async () => {
    console.log('handle delete');
    //delete company
    //get companyRef
    const companyName = data.companyName;

    let companyRef = await db.collection('companies');
    companyRef
      .where('companyName', '==', companyName)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data());
          const filteredProductList = doc
            .data()
            .productList.filter(product => product !== data.productName);
          companyRef
            .doc(doc.id)
            .update({
              productList: filteredProductList
            })
            .then(() => {
              toast.success('data updated');
            })
            .catch(err => console.log(err));
        });
      })
      .catch(err => console.log(err));

    //delete products
    let productRef = await db.collection('products');
    productRef
      .where('productName', '==', productName)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const { productSamples, productThumbnailUrl } = doc.data();
          productSamples.forEach(sample => {
            let sampleRef = storage.ref().child(`images/${sample.ref}`);
            sampleRef
              .delete()
              .then(() => {
                console.log('sample successfully deleted');
              })
              .catch(err => console.log(err));
          });
          let thumbnailRef = storage
            .ref()
            .child(`images/${productThumbnailUrl.ref}`);
          thumbnailRef
            .delete()
            .then(() => {
              console.log('thumbnail successfully delted');
              productRef
                .doc(doc.id)
                .delete()
                .then(() => {
                  console.log('document successfully deleted');
                  setOpen(false);
                  window.location.reload(true);
                })
                .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
        });
      })
      .catch(err => console.log(err));
  };
  //delete files from firestore

  if (type === 'productName') {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Edit</Button>}
      >
        <Modal.Header>Edit Product Name</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <Form.Input
                // style={{ height: '300px' }}
                label='Product Name'
                // placeholder='Tell us more about you...'
                value={value}
                onChange={e => {
                  setValue(e.target.value);
                }}
              />
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
                disabled={value === data}
              >
                Submit
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else if (type === 'productCategory') {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Edit</Button>}
      >
        <Modal.Header>Edit Product Category</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <Dropdown
                placeholder='Select Category'
                fluid
                selection
                options={categoryOptions}
                onChange={(event, data) => {
                  setValue(data.value);
                }}
              />
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
                disabled={value === data}
              >
                Submit
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else if (type === 'productOverview') {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Edit</Button>}
      >
        <Modal.Header>Edit Product Overview</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <Form.TextArea
                style={{ height: '200px' }}
                label='Product Overview'
                // placeholder='Tell us more about you...'
                value={value}
                onChange={e => setValue(e.target.value)}
              />
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
                disabled={value === data}
              >
                Submit
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else if (type === 'productThumbnail') {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Edit</Button>}
      >
        <Modal.Header>Replace Product Thumbnail</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <h1>Current product thumbnail:</h1>
            <Image src={data.url} size='medium' />
            <hr></hr>
            <h1>
              Attach the new product thumbnail. Note that this will delete the
              original thumbnail.
            </h1>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <Form.Input
                type='file'
                name='thumbnail'
                accept='image'
                onChange={e => setThumbnail(e.target.files[0])}
              />
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
                disabled={
                  thumbnail.type !== 'image/png' &&
                  thumbnail.type !== 'image/jpeg' &&
                  thumbnail.type !== 'image/jpg'
                }
              >
                Submit
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else if (type === 'productSamples') {
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Edit</Button>}
      >
        <Modal.Header>Edit Samples</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <h1>
                Click on items in order you want. You can also delete samples
                here by not clicking them. (Must click at least one sample)
              </h1>

              <List>
                {data.map((sample, index) => {
                  return (
                    <List.Item
                      style={{
                        padding: '10px',
                        fontSize: '15px',
                        backgroundColor: '#fffcf2',
                        textAlign: 'center',
                        marginBottom: '5px'
                      }}
                      key={'sample' + index}
                    >
                      <Button
                        style={{
                          backgroundColor: '#fff'
                        }}
                        key={'sampleButton' + index}
                        onClick={() => {
                          console.log(sample);
                          setValue(
                            value.filter(item => item.url !== sample.url)
                          );
                          setArray(state => [...state, sample]);
                        }}
                        disabled={!value.includes(sample)}
                      >
                        <Image src={sample.url} size='medium' />
                      </Button>
                    </List.Item>
                  );
                })}
              </List>
              <h1>
                Updated list of samples in order (Click on an item to remove):{' '}
              </h1>
              <List>
                {array.map((sample, index) => {
                  return (
                    <List.Item
                      style={{
                        padding: '10px',
                        fontSize: '15px',
                        backgroundColor: 'lightblue',
                        textAlign: 'center',
                        marginBottom: '5px'
                      }}
                      key={'sampleUpdated' + index}
                    >
                      <Button
                        style={{
                          backgroundColor: '#fff'
                        }}
                        key={'sampleUpdated' + index}
                        onClick={() => {
                          console.log(sample);
                          setValue(state => [...state, sample]);
                          setArray(
                            array.filter(item => item.url !== sample.url)
                          );
                        }}
                        key={'productUpdated' + index}
                      >
                        <h1>{`${index + 1}:  `}</h1>
                        <Image src={sample.url} size='medium' />
                      </Button>
                    </List.Item>
                  );
                })}
              </List>
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
                disabled={array.length !== data.length}
              >
                Submit
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else if (type === 'addProductSample') {
    console.log('am i returned');
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Add</Button>}
      >
        <Modal.Content>
          <Modal.Description>
            <h1>{`Add a sample to ${productName} for company ${data.companyName}`}</h1>
            <h1>Attach a new sample image.</h1>
            <hr></hr>

            <Form onSubmit={handleSubmit} size={'huge'}>
              <Form.Input
                type='file'
                name='newSample'
                accept='image'
                onChange={e => setSample(e.target.files[0])}
              />
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
                disabled={
                  sample.type !== 'image/png' &&
                  sample.type !== 'image/jpeg' &&
                  sample.type !== 'image/jpg'
                }
              >
                Submit
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else if (type === 'delete') {
    console.log('am i returned');

    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Delete</Button>}
      >
        <Modal.Content>
          <Modal.Description>
            <h1>{`Deleting the product,  ${data.productName} from company, ${data.companyName}`}</h1>
            <hr></hr>
            <h1>
              Warning: Deleted product can not be recovered. This will also
              delete the product from the company product list.
            </h1>
            <Form onSubmit={handleSubmit} size={'huge'}>
              <Form.Button
                size='huge'
                onClick={() => {
                  // setOpen(false);
                }}
              >
                DELETE
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
