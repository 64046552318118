import { db } from '../auth/authService';

export const GET_ALL_COMPANIES = 'GET_ALL_COMPANIES';
export const GET_COMPANY = 'GET_COMPANY';

export function getAllCompanies() {
  return async dispatch => {
    try {
      let querySnapshot = await db.collection('companies').get();
      let companies = [];
      querySnapshot.forEach(doc => {
        companies.push(doc.data());
      });
      dispatch({
        type: GET_ALL_COMPANIES,
        companies: companies
      });
    } catch (err) {
      throw err;
    }
  };
}

export function getCompanyByName(companyName) {
  return async dispatch => {
    try {
    } catch (err) {
      throw err;
    }
  };
}
