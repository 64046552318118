import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { DesktopNavbar, TabletNavbar } from './index';

export function Header() {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1226 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1226 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  // const Default = ({ children }) => {
  //   const isNotMobile = useMediaQuery({ minWidth: 768 });
  //   return isNotMobile ? children : null;
  // };

  return (
    <>
      <Desktop>
        <DesktopNavbar />
      </Desktop>
      <Tablet>
        <TabletNavbar />
      </Tablet>
      <Mobile>
        <TabletNavbar />
      </Mobile>
    </>
  );
}
