import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterWrapper = styled.footer`
  height: auto;
  width: 100%;
  background-color: #3c3c3c;
`;

const Content = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 7.5rem 5rem;
  width: 80vw;
  height: auto;
`;

const InfoWrapper = styled.div`
  width: auto;
  margin-right: 2rem;

  > h1,
  h2,
  p {
    line-height: 1rem;
    color: #fff;
  }

  > h1 {
    font-family: 'MuseoSans-900';
    font-size: 2.5rem;
  }
  > h2 {
    font-family: 'MuseoSans-700';
    font-size: 1.6rem;
  }
  > p {
    font-family: 'MuseoSans-500';
    font-size: 1.4rem;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 4rem;

  @media screen and (max-width: 768px) {
    height: 18rem;
  }
`;
const StyledLink = styled(Link)`
  font-family: 'MuseoSans-700';
  font-size: 1.6rem;
  text-decoration: none;
  color: #fff;

  margin-bottom: 3rem;
  &:hover {
    color: #ff5c0b;
  }
`;
const Copyright = styled.div`
  width: 100%;
  height: 5rem;
  background-color: #1e1e1e;
  display: flex;
  align-items: center;

  > p {
    font-family: 'MuseoSans-700';
    font-size: 1.4rem;
    color: #fff;
    margin-left: 4rem;
  }
`;

export function Footer() {
  return (
    <>
      <FooterWrapper>
        <Content>
          <InfoWrapper>
            <h1>CONTACT US</h1>
            <br />
            <h2>boGO Consulting Corp</h2>
            <p>Vancouver, BC, Canada</p>
            <br />
            {/* <p>Phone: +1 778-580-7403</p> */}
            <p>Email: info@bogoconsulting.com</p>
          </InfoWrapper>
          <LinkWrapper>
            <StyledLink to='/'>HOME</StyledLink>

            <StyledLink to='/company'>ABOUT US</StyledLink>

            <StyledLink to='/marketplace'>INFORMATION POST</StyledLink>
          </LinkWrapper>
          <LinkWrapper>
            <StyledLink to='/howto'>HOW TO NOAH</StyledLink>

            <StyledLink to='/partner'>BECOME A MEMBER</StyledLink>
          </LinkWrapper>
        </Content>
      </FooterWrapper>
      <Copyright>
        <p>&copy; boGO Consulting Corp 2023</p>
      </Copyright>
    </>
  );
}
