import React, { Component } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { Button } from 'components/common';
import { Multiselect } from 'multiselect-react-dropdown';
import { db, timeStamp } from '../../auth/authService';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import emailjs from 'emailjs-com';
import uniqid from 'uniqid';

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  position: fixed;
  /* padding: 0 20rem; */
  
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: ${props => (props.open ? 'block' : 'none')};

  /* -webkit-transition: opacity 100ms ease-in;
  -moz-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
  pointer-events: ${props => (props.open ? 'auto' : 'none')}; */
`;
const Modal = styled.div`
  background-color: #434242;
  width: 80rem;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12vh;

  position: relative;
  left: 50%;
  margin-left: -40rem;

  h1 {
    color: #fff;
    font-size: 4rem;
    font-family: 'MuseoSans-500';
    margin-bottom: 5rem;
  }
  p {
    color: #fff;
    font-size: 1.8rem;
    font-family: 'MuseoSans-300';
    margin-bottom: 5rem;
  }
`;
const TextInputSection = styled.div`
  width: 64rem;
  /* padding: 0 5%; */
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  margin-bottom: 5rem;
`;

const StyledInputField = styled.div`
  margin-top: 3.5rem;
  width: 100%;
  height: 5rem;
  position: relative;
  margin-bottom: 1rem;
  padding: 0 2rem;
  > span {
    display: inline-block;
    color: red;
    font-family: 'MuseoSans-300';
    font-size: 1.4rem;
  }
`;
const StyledInput = styled.input`
  float: left;
  font-size: 1.8rem;
  font-family: 'MuseoSans-300';
  padding: 1rem;
  display: block;
  width: 100%;
  color: #fff;
  background-color: transparent;

  border-style: none none solid none;
  border-width: 2px;
  border-color: ${props => (props.typed ? '#fff' : '#fff')};

  /* -webkit-transition: opacity 100ms ease-in;
  -moz-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in; */

  &:focus {
    outline: 0;
    box-shadow: 0;
    border-style: none none solid none;
    border-width: 2px;
    /* border-color: #63d9d7; */
  }
  &::placeholder {
    /* color: transparent; */
  }

  &:focus ~ label {
    /* color: #63d9d7; */
    top: -2rem;
    font-size: 1.4rem;
  }
`;

const StyledLabel = styled.label`
  color: ${props => (props.typed ? '#fff' : '#fff')};
  position: absolute;
  font-size: ${props => (props.typed ? '1.4rem' : '1.4rem')};
  font-family: 'MuseoSans-300';
  left: 2rem;
  top: ${props => (props.typed ? '-2rem' : '-2rem')};

  pointer-events: none;

  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
`;

const ExitButton = styled.a`
  position: absolute;
  right: 3rem;
  top: 2rem;
  font-size: 2rem;
  background: none;
  color: #fff;
  &:hover {
    color: #ff5c0b;
    cursor: pointer;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 64rem;
  /* padding: 0 5%; */

  h1 {
    font-family: 'MuseoSans-300';
    font-size: 2rem;
    color: #63d9d7;
    margin-bottom: 3rem;
  }
`;

const SelectorContainer = styled.div`
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  /* flex-wrap: wrap; */
`;

const MultiselectCategory = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-bottom: 3rem; */
  width: 28rem;

  h1 {
    font-family: 'MuseoSans-300';
    font-size: 1.4rem;
    margin: 0;
    color: #fff;
    margin-bottom: 1.2rem;
  }
`;

const MultiSelectCss = {
  multiselectContainer: {
    // To change css for multiselect (Width,height,etc..)
    width: '28rem',
    'padding': '0'
  },
  chips: { background: 'rgba(119, 118, 118, 0.84)' },
  searchBox: {
    border: 'none',
    'font-size': '1.8rem',
    'border-bottom': '2px solid white',
    'border-radius': '0px'
  }
};
const StyledTextField = styled.div`
  margin-top: 3.5rem;
  width: 100%;
  height: 35rem;
  position: relative;
  > span {
    display: inline-block;
    color: red;
    font-family: 'MuseoSans-300';
    font-size: 1.4rem;
  }
`;
const StyledTextLabel = styled.label`
  display: inline-block;
  margin: 1rem;
  color: #ff5c0b;
  font-size: 1.8rem;
  font-family: 'MuseoSans-300';
  margin-top: 1rem;
  pointer-events: none;
`;

const StyledTextArea = styled.textarea`
  float: left;
  font-size: 1.8rem;
  font-family: 'MuseoSans-300';
  padding: 1rem;
  display: block;
  width: 100%;
  color: #fff;

  background-color: transparent;

  border-style: solid;
  border-width: 2px;
  border-color: ${props => (props.typed ? '#63d9d7' : '#fff')};
  resize: none;

  &:focus {
    outline: 0;
    box-shadow: 0;
    border-color: #63d9d7;
  }
  &::placeholder {
    color: transparent;
  }
`;

export class ProductModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      typed: {},
      formSubmitted: false,
      veryHighResolutionList: [],
      highResolutionList: [],
      mediumResolutionList: [],
      radarList: [],
      platformServiceList: [],
      applicationsList: [],
      selectedValues: [{ productName: '' }],
      user: {},
      selectedList: []
    };
  }
  componentDidMount() {
    // this.getUserData();
    // console.log(this.state.user);
    this.setState({
      selectedValues: [{ productName: this.props.productName }],
      selectedList: [this.props.productName]
    });
  }

  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      formSubmitting: true
    });

    const uid = uniqid();

    const userEmail = localStorage.getItem('user_email');
    const userName = localStorage.getItem('user_name');
    const message = this.state.fields.message;
    const selectedItems = this.state.selectedList;

    const quoteRef = db.collection('quotes').doc(uid);
    const templateParams = {
      name: userName,
      email: userEmail,
      message,
      selectedProducts: selectedItems,
      createdAt: timeStamp.now(),
      ref: uid
    };
    quoteRef
      .set({
        name: userName,
        email: userEmail,
        message,
        selectedProducts: selectedItems,
        createdAt: timeStamp.now(),
        ref: uid
      })
      .then(() => {
        // emailjs.init('user_t4OCCsC1R3vIvsBhDmumK');
        // emailjs
        //   .send('service_cz6erjd', 'template_jfryd7o', templateParams)
        //   .then(
        //     function(response) {
        //       console.log('success', response.status, response.text);
        //     },
        //     function(error) {
        //       console.log('failed', error);
        //     }
        //   );
        this.props.handleClose(false);
      })
      .then(() => {
        toast.success('Request submitted!', { autoClose: 6000 });
        this.setState({
          fields: {},
          errors: {},
          typed: {},
          formSubmitted: false,
          veryHighResolutionList: [],
          highResolutionList: [],
          mediumResolutionList: [],
          radarList: [],
          platformServiceList: [],
          applicationsList: [],
          selectedValues: [{ productName: '' }],
          user: {},
          selectedList: null
        });
      });
  };

  onSelect = (selectedList, selectedItem) => {
    // console.log(selectedItem.productName);
    // console.log(selectedList);
    const selectedProducts = selectedList.map(item => item.productName);
    this.setState({
      selectedList: selectedProducts
    });
  };

  onRemove = (selectedList, removedItem) => {
    const selectedProducts = selectedList.map(item => item.productName);
    this.setState({
      selectedList: selectedProducts
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    let fields = this.state.fields;
    let typed = this.state.typed;
    fields[name] = value;
    typed[name] = value.length !== 0;
    this.setState({ fields, typed });
    // console.log(this.state.typed);
  };

  render() {
    const userEmail = localStorage.getItem('user_email');
    const userName = localStorage.getItem('user_name');
    let { typed } = this.state;
    const { productName } = this.props;

    if (this.props.fetchedProducts.products) {
      const veryHighResolutionList = this.props.fetchedProducts.products.filter(
        product => product.productCategory === 'Very High Resolution'
      );
      const highResolutionList = this.props.fetchedProducts.products.filter(
        product => product.productCategory === 'High Resolution'
      );
      const mediumResolutionList = this.props.fetchedProducts.products.filter(
        product => product.productCategory === 'Medium Resolution'
      );
      const radarList = this.props.fetchedProducts.products.filter(
        product => product.productCategory === 'RADAR'
      );
      const platformServiceList = this.props.fetchedProducts.products.filter(
        product => product.productCategory === 'Platform Service'
      );
      const applicationsList = this.props.fetchedProducts.products.filter(
        product => product.productCategory === 'Applications'
      );

      console.log(veryHighResolutionList);
      const veryHighResolSelectedProduct = veryHighResolutionList.filter(
        product => product.productName === productName
      );

      const highResolSelectedProoduct = highResolutionList.filter(
        product => product.productName === productName
      );
      const mediumResolSelectedProduct = mediumResolutionList.filter(
        product => product.productName === productName
      );
      const radarResolSelectedProduct = radarList.filter(
        product => product.productName === productName
      );
      const platformResolSelectedProduct = platformServiceList.filter(
        product => product.productName === productName
      );
      const applicationSelectedProduct = applicationsList.filter(
        product => product.productName === productName
      );

      const isVHRSelected = veryHighResolSelectedProduct[0] ? true : false;
      const isHRSelected = highResolSelectedProoduct[0] ? true : false;
      const isMRSelected = mediumResolSelectedProduct[0] ? true : false;
      const isRADARSelected = radarResolSelectedProduct[0] ? true : false;
      const isPLATFORMSelected = platformResolSelectedProduct[0] ? true : false;
      const isAPPSelected = applicationSelectedProduct[0] ? true : false;

      return (
        <ModalContainer open={this.props.open}>
          {/* <ModalContainer open={true}> */}
          <Modal>
            <h1>REQUEST MORE</h1>
            <p>
              To better address your purchase requirements, we welcome your
              fill-in of the form below and submit.
            </p>
            <ExitButton onClick={this.props.handleClose}>X</ExitButton>
            <form onSubmit={this.handleSubmit}>
              <TextInputSection>
                <StyledInputField>
                  <StyledInput
                    type='text'
                    name='name'
                    id='name'
                    // placeholder='Bryan'
                    onChange={this.handleChange}
                    typed={typed.name}
                    value={userName}
                    disabled
                  />
                  <StyledLabel htmlFor='name' typed={typed.name}>
                    Name
                  </StyledLabel>
                </StyledInputField>
                {/* <StyledInputField>
                  <StyledInput
                    type='text'
                    name='companyName'
                    id='companyName'
                    placeholder=''
                    onChange={this.handleChange}
                    typed={typed.companyName}
                  />
                  <StyledLabel htmlFor='companyName' typed={typed.companyName}>
                    Company Name
                  </StyledLabel>
                </StyledInputField> */}
                <StyledInputField>
                  <StyledInput
                    type='text'
                    name='email'
                    id='email'
                    placeholder=''
                    onChange={this.handleChange}
                    value={userEmail}
                    typed={typed.email}
                    disabled
                  />
                  <StyledLabel htmlFor='email' typed={typed.email}>
                    E-mail Address
                  </StyledLabel>
                </StyledInputField>
              </TextInputSection>
              <CategoryContainer>
                <h1>PRODUCT OF INTEREST</h1>
                <SelectorContainer>
                  <MultiselectCategory>
                    <h1>Very High Resolution</h1>
                    <Multiselect
                      placeholder='Select a product'
                      options={veryHighResolutionList} // Options to display in the dropdown
                      selectedValues={
                        isVHRSelected ? this.state.selectedValues : null
                      } // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue='productName' // Property productName to display in the dropdown options
                      avoidHighlightFirstOption
                      style={MultiSelectCss}
                    />
                  </MultiselectCategory>
                  <MultiselectCategory>
                    <h1>High Resolution</h1>
                    <Multiselect
                      placeholder='Select a product'
                      options={highResolutionList} // Options to display in the dropdown
                      selectedValues={
                        isHRSelected ? this.state.selectedValues : null
                      } // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue='productName' // Property productName to display in the dropdown options
                      avoidHighlightFirstOption
                      style={MultiSelectCss}
                    />
                  </MultiselectCategory>
                </SelectorContainer>
                <SelectorContainer>
                  <MultiselectCategory>
                    <h1>Medium Resolution</h1>
                    <Multiselect
                      placeholder='Select a product'
                      options={mediumResolutionList} // Options to display in the dropdown
                      selectedValues={
                        isMRSelected ? this.state.selectedValues : null
                      } //  // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue='productName' // Property productName to display in the dropdown options
                      avoidHighlightFirstOption
                      style={MultiSelectCss}
                    />
                  </MultiselectCategory>
                  <MultiselectCategory>
                    <h1>RADAR</h1>
                    <Multiselect
                      placeholder='Select a product'
                      options={radarList} // Options to display in the dropdown
                      selectedValues={
                        isRADARSelected ? this.state.selectedValues : null
                      } //  // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue='productName' // Property productName to display in the dropdown options
                      avoidHighlightFirstOption
                      style={MultiSelectCss}
                    />
                  </MultiselectCategory>
                </SelectorContainer>
                <SelectorContainer>
                  <MultiselectCategory>
                    <h1>Platform Service</h1>
                    <Multiselect
                      placeholder='Select a product'
                      options={platformServiceList} // Options to display in the dropdown
                      selectedValues={
                        isPLATFORMSelected ? this.state.selectedValues : null
                      } // // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue='productName' // Property productName to display in the dropdown options
                      avoidHighlightFirstOption
                      style={MultiSelectCss}
                    />
                  </MultiselectCategory>
                  <MultiselectCategory>
                    <h1>Application</h1>
                    <Multiselect
                      placeholder='Select a product'
                      options={applicationsList} // Options to display in the dropdown
                      selectedValues={
                        isAPPSelected ? this.state.selectedValues : null
                      } //  // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue='productName' // Property productName to display in the dropdown options
                      avoidHighlightFirstOption
                      style={MultiSelectCss}
                      // showCheckbox={true}
                    />
                  </MultiselectCategory>
                </SelectorContainer>
              </CategoryContainer>
              <StyledTextField>
                <StyledTextLabel htmlFor='message' typed={typed.message}>
                  MESSAGE
                </StyledTextLabel>
                <StyledTextArea
                  name='message'
                  id='message'
                  typed={typed.message}
                  onChange={this.handleChange}
                  cols='30'
                  rows='10'
                />
              </StyledTextField>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* <Button
                  style={{ margin: '0 auto' }}
                  disabled={this.state.formSubmitting}
                >
                  SUBMIT
                </Button> */}
                <Button
                  disabled={this.state.formSubmitting}
                  style={{ borderRadius: '2rem', marginTop: '2rem' }}
                >
                  {this.state.formSubmitting ? (
                    <Loader active inline='centered' />
                  ) : (
                    'SUBMIT'
                  )}
                </Button>
              </div>
            </form>
          </Modal>
        </ModalContainer>
      );
    } else {
      return <div>Loading</div>;
    }
  }
}

const mapStateToProps = state => ({
  fetchedProducts: state.fetchedProducts
});

export default connect(
  mapStateToProps,
  {}
)(ProductModalForm);
