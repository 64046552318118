import { VisibilityFilters } from './marketplaceActions';
import {
  RECEIVE_PRODUCTS,
  REQUEST_PRODUCTS,
  REQUEST_COMPANIES,
  RECEIVE_COMPANIES
} from './marketplaceActions';

export function productsReducer(state = {}, action) {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_PRODUCTS:
      return Object.assign({}, state, {
        isFetching: false,
        products: action.products
      });
    default:
      return state;
  }
}
export function companiesReducer(state = {}, action) {
  switch (action.type) {
    case REQUEST_COMPANIES:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_COMPANIES:
      return Object.assign({}, state, {
        isFetching: false,
        companies: action.companies
      });
    default:
      return state;
  }
}

export function filterReducer(state = {}, action) {
  switch (action.type) {
    case VisibilityFilters.SHOW_ALL:
      return action.products;
    case VisibilityFilters.SHOW_VERY_HIGH_RESOL:
      return action.products.filter(
        p => p.productCategory === 'Very High Resolution'
      );
    case VisibilityFilters.SHOW_HIGH_RESOL:
      return action.products.filter(
        p => p.productCategory === 'High Resolution'
      );
    case VisibilityFilters.SHOW_MEDIUM_RESOL:
      return action.products.filter(
        p => p.productCategory === 'Medium Resolution'
      );
    case VisibilityFilters.SHOW_RADAR:
      return action.products.filter(p => p.productCategory === 'RADAR');
    case VisibilityFilters.SHOW_PLATFORM_SERVICE:
      return action.products.filter(
        p => p.productCategory === 'Platform Service'
      );
    case VisibilityFilters.SHOW_APPLICATION:
      return action.products.filter(p => p.productCategory === 'Applications');
    default:
      return state;
  }
}
