import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Link as ReactRouterDomLink,
  useLocation,
  useHistory
} from 'react-router-dom'; // import HamburgerMenu from 'react-hamburger-menu';
// import { slide as Dropdown } from 'react-burger-menu';
import { auth } from '../../../auth/authService';
import { toast } from 'react-toastify';

import LogoSrc from 'images/Logo-2x.png';
import { Button, ContactUsModal } from 'components/common';
import ProductModalForm from '../ProductModalForm';

const HeaderWrapper = styled.header`
  width: 100vw;
  height: 11.6rem;
  display: flex;
  position: fixed;
  top: 0;
  background-color: #1e1e1e;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const Link = ({ isActive, children, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};

const StyledLink = styled(Link)`
  font-family: 'MuseoSans-700';
  font-size: 2rem;
  padding: 3rem 1rem;
  height: 1.6rem;
  text-decoration: none;
  color: ${p => (p.isActive ? '#ff5c0b' : '#fff')};
  flex: none;

  &:hover {
    color: #ff5c0b;
  }
`;

const Logo = styled.img`
  width: 17rem;
  height: 4.2rem;
`;

const Ul = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  background-color: #434242;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 30rem;
  padding: 10rem 5rem;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
`;

const StyledBurger = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  width: 5rem;
  height: 4rem;
  position: absolute;

  top: 4rem;
  right: 5rem;
  z-index: 20;
  &:hover {
    cursor: pointer;
  }

  div {
    width: 4rem;
    height: 0.5rem;
    background-color: ${({ open }) => (open ? '#fff' : '#63D9D7')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
const ContactUsLink = styled.a`
  font-family: 'MuseoSans-700';
  font-size: 2rem;
  padding: 3rem 1rem;
  text-decoration: none;
  height: 1.6rem;
  color: ${p => (p.isActive ? '#ff5c0b' : '#fff')};
  flex: none;
  cursor: pointer;
  margin-bottom: 3rem;

  &:hover {
    color: #ff5c0b;
  }
`;

const RequestQuoteLink = styled.a`
  font-family: 'MuseoSans-700';
  font-size: 2rem;
  padding: 3rem 1rem;
  height: 1.6rem;
  text-decoration: none;
  color: ${p => (p.isActive ? '#ff5c0b' : '#fff')};
  flex: none;
  cursor: pointer;

  &:hover {
    color: #ff5c0b;
  }
`;

export function TabletNavbar() {
  const { pathname } = useLocation();
  let history = useHistory();

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);

  const [isQuoteModalOpen, setQuoteModalOpen] = useState(false);
  //dropdown menu styles
  useEffect(() => {
    if (localStorage.getItem('isAuthenticated')) {
      setAuthenticated(true);
    }
  });

  function handleClick(e) {
    e.preventDefault();
    console.log('iwascalled');
    auth
      .signOut()
      .then(res => {
        console.log(res);
        toast.success('Sign out successful!');
        localStorage.removeItem('isAuthenticated');
        window.location.reload();
      })
      .catch(err => console.log(err));
  }
  function redirect() {
    console.log('redirecting');

    history.push('/login');
  }

  return (
    <>
      <HeaderWrapper>
        <Link to='/' style={{ margin: '0 2rem' }}>
          <Logo src={LogoSrc} />
        </Link>

        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>

        <Ul open={open}>
          <StyledLink to='/marketplace' isActive={pathname === '/marketplace'}>
            Information Post
          </StyledLink>
          <RequestQuoteLink
            onClick={() => {
              if (isAuthenticated) {
                setQuoteModalOpen(true);
              } else {
                console.log('iwas called');
                redirect();
              }
            }}
          >
            Request More
          </RequestQuoteLink>
          <StyledLink to='/partner' isActive={pathname === '/partner'}>
            Become a Member
          </StyledLink>
          <StyledLink to='/howto' isActive={pathname === '/howto'}>
            How to NOAH
          </StyledLink>
          <StyledLink
            to='/company'
            isActive={pathname === '/company' || pathname === '/'}
          >
            Company
          </StyledLink>
          <ContactUsLink onClick={() => setModalOpen(true)}>
            Contact Us
          </ContactUsLink>

          {localStorage.getItem('isAuthenticated') ? (
            <Button onClick={handleClick} style={{ margin: '0 2.75rem' }}>
              Logout
            </Button>
          ) : (
            <Link to='/login'>
              <Button style={{ margin: '0 2.75rem' }}>Sign In</Button>
            </Link>
          )}
        </Ul>
      </HeaderWrapper>
      <ContactUsModal
        open={modalOpen}
        handleClose={setModalOpen}
      ></ContactUsModal>
      <ProductModalForm
        open={isQuoteModalOpen}
        handleClose={() => setQuoteModalOpen(false)}
      ></ProductModalForm>
    </>
  );
}
