// src/views/LoginPage.js

import React from 'react';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { login } from '../../../redux/authActions';
import styled from 'styled-components';
import { Button } from 'components/common';

const Container = styled.div`
  width: 100vw;
  background-color: #fff;
  display: flex;
  justify-content: center;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;
  position: relative;
  margin: 2.5% auto;
  padding: 5rem 10rem;
  border-radius: 3px;
  background: #434242;

  > a {
    position: absolute;
    right: 3rem;
    top: 2rem;
    font-size: 3rem;
    background: none;
    color: #fff;
    border: none;
    &:hover {
      color: #ff5c0b;
      cursor: pointer;
    }
  }
  > div {
    width: 40vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  > h1 {
    font-family: 'MuseoSans-500';
    font-size: 4rem;
    text-align: center;
    width: 30vw;
    color: #fff;
  }
  > p {
    font-family: 'MuseoSans-300';
    font-size: 1.6rem;
    text-align: center;
    width: 35vw;
    color: #fff;
  }
`;

const StyledField = styled.div`
  width: 30rem;
  height: 5rem;
  position: relative;
  margin-bottom: 6rem;
  padding: 0 2rem;

  > span {
    color: red;
    font-family: 'MuseoSans-300';
    font-size: 1.4rem;
    margin-top: 1rem;
  }
`;

const StyledInput = styled.input`
  float: left;
  font-size: 1.8rem;
  font-family: 'MuseoSans-300';
  padding: 1rem;
  display: block;
  width: 100%;
  color: #fff;
  background-color: transparent;

  border-style: none none solid none;
  border-width: 2px;
  border-color: ${props => (props.typed ? '#63d9d7' : '#fff')};

  &:focus {
    outline: 0;
    box-shadow: 0;
    border-style: none none solid none;
    border-width: 2px;
    border-color: #63d9d7;
  }
  &::placeholder {
    color: transparent;
  }

  &:focus ~ label {
    color: #63d9d7;
    top: -2rem;
    font-size: 1.2rem;
  }
`;

const StyledLabel = styled.label`
  color: ${props => (props.typed ? '#63d9d7' : '#fff')};
  position: absolute;
  font-size: ${props => (props.typed ? '1.2rem' : '1.8rem')};
  font-family: 'MuseoSans-300';
  left: 2rem;
  top: ${props => (props.typed ? '-2rem' : '0rem')};

  pointer-events: none;

  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      typed: {},
      formSubmitted: false
    };
  }
  handleChange = event => {
    const { name, value } = event.target;
    let fields = this.state.fields;
    let typed = this.state.typed;
    fields[name] = value;
    typed[name] = value.length !== 0;
    this.setState({ fields, typed });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { email, password } = this.state.fields;
    const { login, history } = this.props;
    console.log(email, password);

    login(email, password)
      .then(() => {
        toast.success('Login successful');
        history.push('/admin');
      })
      .catch(error => {
        toast.error('Login failed');
        console.error(error);
      });
  };

  render() {
    let { typed } = this.state;

    return (
      <Container>
        <StyledForm onSubmit={this.handleSubmit}>
          <h1>Admin Log In</h1>
          <StyledField>
            <StyledInput
              type='text'
              name='email'
              id='email'
              placeholder=''
              onChange={this.handleChange}
              typed={typed.email}
            />
            <StyledLabel htmlFor='email' typed={typed.email}>
              Email Address
            </StyledLabel>
            {<span>{this.state.errors.email}</span>}
          </StyledField>
          <StyledField>
            <StyledInput
              type='password'
              name='password'
              id='password'
              placeholder=''
              onChange={this.handleChange}
              typed={typed.password}
            />
            <StyledLabel htmlFor='password' typed={typed.password}>
              Password
            </StyledLabel>
            {<span>{this.state.errors.password}</span>}
          </StyledField>
          <Button style={{ margin: '0 auto' }}>Login</Button>
        </StyledForm>
      </Container>
    );
  }
}
export default connect(
  null,
  { login }
)(Login);
