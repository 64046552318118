import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link as ReactRouterDomLink } from 'react-router-dom';
import styled from 'styled-components';
import { Divider, CardSample } from 'components/common';
import { Loader } from 'semantic-ui-react';

const Content = styled.div`
  padding: 2rem 5rem;
  h1 {
    font-family: 'MuseoSans-700';
    font-size: 2rem;
  }
`;
const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  width: 100%;
`;
const Card = styled.div`
  display: flex;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
`;
const CardElementBox = styled.div`
  width: 35rem;
  height: 35rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  img {
    width: 18.5rem;
    height: 6.5rem;
    object-fit: fill;
  }
`;

const Link = ({ isActive, children, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};

const StyledLink = styled(Link)`
  font-family: 'MuseoSans-700';
  font-size: 1.8rem;
  text-decoration: none;
  color: #1e1e1e;
  padding-left: 1rem;
`;
class MarketList extends Component {
  constructor(props) {
    super(props);
    this.refsArray = [];
    this.activeProductRefs = [];
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {}

  cacheImages = srcArray => {
    srcArray.forEach(src => {
      new Promise(function(resolve, reject) {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    this.setState({
      isLoading: false
    });
  };

  handleClick = e => {
    e.preventDefault();
    const strArray = e.target.name.split(',');
    const companyIndex = parseInt(strArray[0]);
    // const productIndex = parseInt(strArray[1]);
    const productName = strArray[1];

    this.refsArray[companyIndex].changeProduct(productName);
  };

  updateResults(products, fetchedCompanies) {
    let companyList = [];

    products.forEach(p => companyList.push(p.companyName));
    const uniqueSet = new Set(companyList);
    const filteredCompanyNames = [...uniqueSet];
    const filteredCompanies = fetchedCompanies.companies.filter(company => {
      if (filteredCompanyNames.includes(company.companyName)) {
        return true;
      } else {
        return false;
      }
    });

    return filteredCompanies;
  }

  render() {
    const { filteredProducts, fetchedCompanies } = this.props;
    if (
      Object.keys(filteredProducts).length !== 0 &&
      Object.keys(fetchedCompanies).length !== 0
    ) {
      const filteredCompanies = this.updateResults(
        filteredProducts,
        fetchedCompanies
      );
      return (
        <Content>
          <h1>RESULTS</h1>
          {filteredCompanies.map((company, companyIndex) => {
            const products = filteredProducts.filter(
              product => product.companyName === company.companyName
            );

            return (
              <CardsContainer key={`CardsContainer` + companyIndex}>
                <Card key={`Card` + companyIndex}>
                  <CardElementBox key={`CardElementBox` + companyIndex}>
                    <Link
                      key={`Link` + companyIndex}
                      to={`/marketplace/${company.companyName}`}
                    >
                      <img src={company.companyLogo.url} alt='logo' />
                    </Link>
                    <Divider
                      key={`Divider` + companyIndex}
                      style={{ marginBottom: '2rem', marginLeft: '1rem' }}
                    />
                    {products.map((product, productIndex) => {
                      return (
                        <StyledLink
                          key={`ProductLink` + productIndex}
                          name={companyIndex + ',' + product.productName}
                          onClick={this.handleClick}
                        >
                          {product.productName}
                        </StyledLink>
                      );
                    })}
                  </CardElementBox>
                  <CardSample
                    key={`CardSample` + companyIndex}
                    products={products}
                    ref={ref => {
                      this.refsArray[companyIndex] = ref;
                    }}
                  />
                </Card>
              </CardsContainer>
            );
          })}
        </Content>
      );
    } else {
      return (
        <Content>
          <h1>NO RESULTS FOUND</h1>
        </Content>
      );
    }
  }
}
const mapStateToProps = state => ({
  filteredProducts: state.filteredProducts,
  fetchedCompanies: state.fetchedCompanies
});
export default connect(
  mapStateToProps,
  null
)(MarketList);
