// import firebase from 'firebase';
// import 'firebase/firestore';
const firebaseConfig = {
  apiKey: 'AIzaSyDQWtwcsBUB7j_q09lQI5TC9Caa-V1Zn5Y',
  authDomain: 'noah-c110b.firebaseapp.com',
  databaseURL: 'https://noah-c110b.firebaseio.com',
  projectId: 'noah-c110b',
  storageBucket: 'noah-c110b.appspot.com',
  messagingSenderId: '46444793451',
  appId: '1:46444793451:web:98cacb2161ea3634086b2e',
  measurementId: 'G-E9XZSJ6ZN1'
};

export { firebaseConfig };

// firebase.initializeApp(firebaseConfig);
// export default firebase;
