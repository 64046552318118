// src/views/LoginPage.js

import React from 'react';
// import { db, storage } from '../../../auth/authService';
// import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../../redux/authActions';
import AdminPageLayout from './AdminPageLayout';

// import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';

const Header = styled.h1`
  font-size: 4rem;
`;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'Admin'
    };
  }
  componentDidMount() {}

  handleNavClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    if (this.props.companies && this.props.products) {
      console.log(this.props.companies);
      console.log(this.props.products);

      return (
        <AdminPageLayout>
          <Container>
            <Header>NOAH DATABASE</Header>
            {this.props.companies.map(data => {
              const products = this.props.products.filter(product => {
                return product.companyName === data.companyName;
              });
              return (
                <ul>
                  <li>
                    {data.companyName}
                    <ul>
                      {products.map(product => {
                        return <li>{product.productName}</li>;
                      })}
                    </ul>
                  </li>
                </ul>
              );
            })}
          </Container>
        </AdminPageLayout>
      );
    } else {
      return <AdminPageLayout>LOADING</AdminPageLayout>;
    }
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  companies: state.fetchedCompanies.companies,
  products: state.fetchedProducts.products
});

export default connect(
  mapStateToProps,
  { logout }
)(Admin);
