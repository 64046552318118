import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AdminPageLayout from './AdminPageLayout';
import './style.css';
import ProductEditModal from './ProductEditModal';

import {
  Dimmer,
  Loader,
  List,
  // Segment,
  // Container,
  // Modal,
  Header,
  Table,
  Image,
  Button
} from 'semantic-ui-react';

import styled from 'styled-components';

const StyledCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

class ProductEdit extends Component {
  render() {
    const { companies, products } = this.props;
    if (companies && products) {
      console.log(companies);
      return (
        <AdminPageLayout>
          <Header>Product List </Header>

          {/* Table Component HERE ///////////////////////*/}
          <Table celled textAlign={'center'}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product Name</Table.HeaderCell>
                <Table.HeaderCell>Product Category</Table.HeaderCell>
                <Table.HeaderCell>Product Overview</Table.HeaderCell>
                <Table.HeaderCell>Product Thumbnail</Table.HeaderCell>
                <Table.HeaderCell>Product Samples</Table.HeaderCell>
                <Table.HeaderCell>Product Details</Table.HeaderCell>
                <Table.HeaderCell>Option</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {products.map((product, index) => {
                return (
                  <Table.Row key={index + `row`}>
                    <Table.Cell key={index + `productName`} selectable>
                      <h1>{product.productName}</h1>
                      <ProductEditModal
                        key={index + 'productName'}
                        productName={product.productName}
                        type={'productName'}
                        data={product.productName}
                      />
                    </Table.Cell>
                    <Table.Cell key={index + `category`} selectable>
                      {product.productCategory}
                      <ProductEditModal
                        key={index + 'productCategory'}
                        productName={product.productName}
                        type={'productCategory'}
                        data={product.productCategory}
                      />
                    </Table.Cell>
                    <Table.Cell
                      key={index + `productOverview`}
                      width={5}
                      selectable
                    >
                      <StyledCell>
                        {product.productOverview.slice(0, 100) + '...'}
                        <ProductEditModal
                          key={index + 'productOverview'}
                          productName={product.productName}
                          type={'productOverview'}
                          data={product.productOverview}
                        />
                      </StyledCell>
                    </Table.Cell>
                    <Table.Cell key={index + `thumbnail`} selectable>
                      <Image
                        src={product.productThumbnailUrl.url}
                        fluid
                        size='small'
                        key={index + `thumbnail`}
                      />
                      <ProductEditModal
                        key={index + 'productThumbnail'}
                        productName={product.productName}
                        type={'productThumbnail'}
                        data={product.productThumbnailUrl}
                      />
                    </Table.Cell>
                    <Table.Cell key={index + `samples`} selectable>
                      <ProductEditModal
                        key={index + 'productList'}
                        productName={product.productName}
                        type={'productSamples'}
                        data={product.productSamples}
                      />
                      <ProductEditModal
                        key={index + 'addProduct'}
                        productName={product.productName}
                        type={'addProductSample'}
                        data={{
                          productName: product.productName,
                          companyName: product.companyName
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell key={index + `productDetails`}>
                      <Link to={`/admin/product/${product.productName}`}>
                        <Button>Edit</Button>
                      </Link>
                    </Table.Cell>
                    <Table.Cell key={index + `productDetails`}>
                      <ProductEditModal
                        key={index + 'delete'}
                        productName={product.productName}
                        type={'delete'}
                        data={{
                          productName: product.productName,
                          companyName: product.companyName
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {/* MODAL Component HERE ///////////////////////*/}
        </AdminPageLayout>
      );
    } else {
      return (
        <AdminPageLayout>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </AdminPageLayout>
      );
    }
  }
}

const mapStateToProps = state => ({
  companies: state.fetchedCompanies.companies,
  products: state.fetchedProducts.products
});

export default connect(
  mapStateToProps,
  {}
)(ProductEdit);
