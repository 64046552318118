import React, { Component } from 'react';
import { db, timeStamp } from '../../../auth/authService';
import AdminPageLayout from './AdminPageLayout';
import './style.css';

import {
  Dimmer,
  Loader,
  List,
  Header,
  Table,
  Image,
  Button
} from 'semantic-ui-react';

import styled from 'styled-components';

const StyledCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default class BecomeMember extends Component {
  constructor(props) {
    super();

    this.state = { memberRequestList: [] };
  }
  componentDidMount() {
    db.collection('partnerRequest')
      .orderBy('createdAt', 'desc')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.setState({
            memberRequestList: [...this.state.memberRequestList, doc.data()]
          });
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    const { memberRequestList } = this.state;
    return (
      <AdminPageLayout>
        <Header>ContactUs Message List</Header>
        <Table celled textAlign={'center'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <Table.HeaderCell>createdAt</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {memberRequestList.map((request, index) => {
              let createdAt = request.createdAt.toDate().toDateString();

              return (
                <Table.Row key={index + 'row'}>
                  <Table.Cell key={index + 'firstname'} selectable>
                    {request.firstName}
                  </Table.Cell>
                  <Table.Cell key={index + 'lastname'} selectable>
                    {request.lastName}
                  </Table.Cell>
                  <Table.Cell key={index + 'company'} selectable>
                    {request.company}
                  </Table.Cell>
                  <Table.Cell key={index + 'email'} selectable>
                    {request.email}
                  </Table.Cell>
                  <Table.Cell key={index + 'message'} selectable>
                    <p style={{ whiteSpace: 'pre-line' }}>{request.message}</p>
                  </Table.Cell>
                  <Table.Cell key={index + 'createdAt'} selectable>
                    {createdAt}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </AdminPageLayout>
    );
  }
}
