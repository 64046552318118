import React, { Component } from 'react';
import { db, timeStamp } from '../../../auth/authService';
import AdminPageLayout from './AdminPageLayout';
import './style.css';

import {
  Dimmer,
  Loader,
  List,
  Header,
  Table,
  Image,
  Button
} from 'semantic-ui-react';

import styled from 'styled-components';

const StyledCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default class User extends Component {
  constructor(props) {
    super();

    this.state = { userList: [] };
  }
  componentDidMount() {
    db.collection('users')
      .orderBy('createdAt', 'desc')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.setState({ userList: [...this.state.userList, doc.data()] });
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    const { userList } = this.state;
    return (
      <AdminPageLayout>
        <Header>User List</Header>
        <Table celled textAlign={'center'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Country</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>isRegistered</Table.HeaderCell>
              <Table.HeaderCell>createdAt</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {userList.map((user, index) => {
              let createdAt = user.createdAt.toDate().toDateString();

              return (
                <Table.Row key={index + 'row'}>
                  <Table.Cell key={index + 'firstName'} selectable>
                    {user.firstName}
                  </Table.Cell>
                  <Table.Cell key={index + 'lastName'} selectable>
                    {user.lastName}
                  </Table.Cell>
                  <Table.Cell key={index + 'company'} selectable>
                    {user.company}
                  </Table.Cell>
                  <Table.Cell key={index + 'country'} selectable>
                    {user.country}
                  </Table.Cell>
                  <Table.Cell key={index + 'email'} selectable>
                    {user.email}
                  </Table.Cell>
                  <Table.Cell key={index + 'isRegistered'} selectable>
                    {user.isRegistered ? 'YES' : 'NO'}
                  </Table.Cell>
                  <Table.Cell key={index + 'createdAt'} selectable>
                    {createdAt}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </AdminPageLayout>
    );
  }
}
