import React, { Component } from 'react';
import styled from 'styled-components';
import { Divider } from './Divider';
import { Link as ReactRouterDomLink } from 'react-router-dom';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Logo = styled.img`
  width: 18.5rem;
  height: 6.5rem;
  object-fit: cover;
`;

const Link = ({ isActive, children, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};

const StyledLink = styled(Link)`
  width: 18.5rem;
  height: 6.5rem;
`;

export class CardLogo extends Component {
  render() {
    const { companyName, companyLogoUrl } = this.props;
    return (
      <Container>
        <StyledLink to={`/marketplace/${companyName}`}>
          <Logo src={companyLogoUrl} />
        </StyledLink>
        <Divider style={{ marginLeft: '1rem' }} />
      </Container>
    );
  }
}
