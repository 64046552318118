import React, { useEffect } from 'react';
import { PageLayout } from 'components/common';
import styled from 'styled-components';
import { Divider, DesktopProfileBox } from 'components/common';
import HeaderImg from 'images/company/header_image.png';
import PurposeImg from 'images/company/purpose_image.png';
import ShakeImg from 'images/company/shake_image.png';
import BusinessImg from 'images/company/business_image.png';
import WorldViewImg from 'images/company/WorldView(5).jpg';
const Header = styled.div`
  width: 100%;
  height: 61.9rem;
  background-image: url(${HeaderImg});
  background-size: cover;
  position: relative;

  > h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 85vw;
    font-family: 'MuseoSans-700';
    font-size: 4rem;
    text-align: center;
    color: #fff;

    @media screen and (max-width: 760px) {
      top: 30%;
    }
  }
`;
const History = styled.div`
  background-color: #f4f4f4;
  height: 45vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 5rem 0; */
  @media screen and (max-width: 400px) {
    height: 45vh;
  }
  > h1 {
    width: 85vw;
    font-family: 'MuseoSans-700';
    font-size: 4rem;
    text-align: center;
    color: #1e1e1e;
  }
  > p {
    font-family: 'MuseoSans-500';
    font-size: 2rem;
    text-align: center;
    color: #505050;
    width: 80vw;
  }
`;

const Purpose = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  /* > img {
    width: 45vw;
    height: auto;

    box-shadow: -1px 1px 5px 1px rgba(0, 0, 0, 0.75);
    @media screen and (max-width: 1109px) {
      margin: 0 auto;
      width: 100%;
      height: 20%;
    }
  } */
`;

const PurposeSection = styled.div`
  padding: 5rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1109px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > h1 {
    text-align: center;
    font-family: 'MuseoSans-700';
    font-size: 4rem;
    color: #1e1e1e;
    @media screen and (max-width: 1109px) {
      text-align: center;
    }
  }
  > p {
    font-family: 'MuseoSans-500';
    font-size: 2rem;
    text-align: center;
    color: #505050;
    width: 80vw;
    margin-bottom: 1.5rem;
    /* height: 20rem; */
    @media screen and (max-width: 1109px) {
      text-align: center;
    }
  }
  > ul {
    /* display: block; */
    /* display: flex; */
    /* justify-content: center;
    flex-wrap: wrap; */
    /* margin-top: 4rem; */
    /* padding-left: 2rem; */
    /* width: 80vw; */
    /* margin: 0 auto; */
    /* height: 28.6rem; */
    margin-top: 2rem;
    @media screen and (max-width: 1109px) {
      width: 100%;
      height: auto;
    }
    > li {
      font-family: 'MuseoSans-300';
      font-size: 1.8rem;
      color: #505050;
      margin-bottom: 2rem;
    }
  }
`;
const Services = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 10%;
  width: 100%;
  background-color: #3c3c3c;
  > h1 {
    font-family: 'MuseoSans-700';
    font-size: 4rem;
    color: #fff;
  }
`;

const Service1 = styled.div`
  padding: 0 12% 3rem 12%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  background-color: #1e1e1e;
`;
const Service1Title = styled.div`
  background-color: #1e1e1e;
  padding-top: 3rem;
  padding-left: 12%;
  > h1 {
    font-family: 'MuseoSans-900';
    font-size: 2rem;
    color: #fff;
    padding-bottom: 2rem;
  }
`;
const Service1Content = styled.div`
  margin-top: 3rem;
  width: 30vw;
  height: auto;
  > p {
    font-family: 'MuseoSans-700';
    font-size: 2rem;
    color: #63d9d7;
    margin-bottom: 3rem;
  }
  > ul {
    padding-left: 2rem;
    > li {
      font-family: 'MuseoSans-500';
      font-size: 1.8rem;
      color: #fff;
      margin-bottom: 2rem;
    }
  }
`;
const Service2 = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  object-fit: cover;

  > img {
    width: 45vw;
    height: auto;
    object-fit: cover;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.75);
    @media screen and (max-width: 1109px) {
      margin: 0 auto;
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
      width: 100%;
      height: 30%;
      order: 2;
    }
  }
`;
const Service2Section = styled.div`
  padding: 4rem 2rem;
  margin: auto;
  > h1 {
    margin-top: 1rem;
    font-family: 'MuseoSans-500';
    font-size: 2rem;
    color: #1e1e1e;
  }
  > p {
    width: 51rem;
    font-family: 'MuseoSans-700';
    font-size: 2.5rem;
    color: #63d9d7;
    margin-bottom: 4rem;

    /* margin-bottom: 1rem; */
  }
  > ul {
    /* margin-top: 2rem; */
    padding-left: 2rem;
    width: 51rem;
    height: auto;
    @media screen and (max-width: 1109px) {
      width: 70vw;
    }
    > li {
      font-family: 'MuseoSans-500';
      font-size: 1.8rem;
      color: #505050;
      margin-bottom: 1rem;
    }
  }
`;

const Service3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 70rem;
  background-color: #1e1e1e;
  background-image: url(${BusinessImg});
  background-size: cover;
  padding-left: 12%;

  > h1 {
    width: 40rem;
    @media screen and (max-width: 1109px) {
      width: 70vw;
    }
    font-family: 'MuseoSans-900';
    font-size: 2rem;
    color: #fff;
    margin-bottom: 3rem;
  }
  > p {
    margin-top: 3rem;
    width: 60rem;
    font-family: 'MuseoSans-500';
    font-size: 1.8rem;
    color: #fff;
    @media screen and (max-width: 1109px) {
      width: 70vw;
    }
  }
`;

export default function Company() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <PageLayout>
      <Header>
        <h1>
          We deliver information about Satellite Earth Observation and set up
          business networks.
        </h1>
      </Header>
      <History>
        <DesktopProfileBox />
        <h1>OUR HISTORY</h1>
        <Divider style={{ margin: '3rem 0' }} />
        {/* <p>
          Armed with the big database of User Experiences and Success Stories
          piled over 20 years, we have been the sales partner of the high-end
          Satellite Image companies in the USA, Canada, and Europe.
        </p>
        <p>
          To share our expertise and invite more buyers and sellers who are in
          search of the partners, an online platform (NOAH) is launched by boGO
          Consulting. And, at NOAH, Buyer and Seller can walk (along with boGO)
          through their needs/orders and offers/proposals, in the end, to
          produce mutually rewarding deals.
        </p> */}
        <p>
          With User Experience and Success Story of over 20 years, we have been
          the representative of the world leading Satellite Image provider.
        </p>
      </History>
      <Purpose>
        <PurposeSection>
          <h1 style={{ margin: '1rem 0' }}>OUR MISSION</h1>
          <Divider style={{ margin: '3rem 0' }} />
          <p>
            Helping clients search Satellite Image and Geospatial Solution, a
            virtual information center (NOAH) is served by boGO Consulting Corp.
            At NOAH, Clients can open up the posted information package, discuss
            their needs with boGO, and reach out to the providers who can best
            meet the client’s requirements.
          </p>
          {/* <p>
            At NOAH, Clients can open up the posted information package, have a
            chat with boGO about their needs, and reach out to the providers who
            can best meet the client’s requirements.
          </p> */}
          <ul>
            <li>
              Fast Track to information on the products and services provided by
              Satellite Image and Geospatial Solution companies
            </li>
            <li>
              Bespoke consulting for the clients who decide to procure satellite
              image and geospatial solution
            </li>
            <li>
              Trust-worthy support for the clients who had challenges in
              purchasing the satellite image and geospatial solution
            </li>
          </ul>
        </PurposeSection>
        {/* <img src={PurposeImg} alt='purpose' /> */}
      </Purpose>
      <Services>
        <h1>OUR SERVICES</h1>
      </Services>
      <Service1Title>
        <h1 style={{ margin: '3rem 0' }}>DEFINING MISSION AND MATCHING</h1>
        <Divider />
      </Service1Title>
      <Service1>
        <Service1Content>
          <p>For Client</p>
          <ul>
            <li>
              Update on Solution Provider’s progress including the offer with
              ROM pricing
              <br></br> -Regular update by emails and calls on 24/7 basis
            </li>
            <li>
              Arrange meetings to review Solution Provider’s offer and proposal
            </li>
            <li>
              Help negotiate and place the purchase order within Client’s budget
              range
            </li>
            <li>
              Support “contract signing procedure” between Client and Solution
              Provider
            </li>
            <li>Monitor how Solution Provider’s services are fulfilled</li>
          </ul>
        </Service1Content>
        <Service1Content>
          <p>For Solution Provider</p>
          <ul>
            <li>
              Update on Client’s progress including Client’s interests and
              budget cycle <br></br>-Regular update by emails and calls on 24/7
              basis
            </li>
            <li>
              Arrange meetings to clarify and define Client’s needs and
              requirements
            </li>
            <li>Estimate Client’s budget range</li>
            <li>
              Help negotiate and prepare the proposal that secures Provider’s
              proper margin
            </li>
            <li>
              Support “contract signing procedure” between Solution Provider and
              Client
            </li>
            <li>Continued feedback on the operation status by Client</li>
          </ul>
        </Service1Content>
      </Service1>

      <Service2>
        <img src={WorldViewImg} alt='Shake' />
        <Service2Section>
          <h1>FAST-TRACK ACCESS TO INFORMATION</h1>
          <Divider style={{ margin: '2rem 0' }} />
          <p>
            Clients can easily access information posted by world leading
            Satellite Image and Geospatial Solution providers
          </p>
          <h1>Clients can have a chat with boGO Consulting for:</h1>
          <ul>
            <li>
              Detailed information on a specific product or service they are
              looking for
            </li>
            <li>
              Comparison of the value among the similar products or services
            </li>
            <li>Recommendation that best meets their requirements.</li>
          </ul>
        </Service2Section>
      </Service2>
      <Service3>
        <h1>SETTING BUSINESS NETWORK</h1>
        <Divider />
        <p>
          boGO Consulting regularly organizes symposium in Vancouver Canada for
          Satellite Earth Observation community members to help set up a
          business network between Clients and Providers.
        </p>
      </Service3>
    </PageLayout>
  );
}
