import React, { Component } from 'react';

import { Link as ReactRouterDomLink } from 'react-router-dom';
import styled from 'styled-components';
import { LazyImage } from 'components/common/LazyImage';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';

const Link = ({ isActive, children, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};
const StyledLink = styled(Link)`
  font-family: 'MuseoSans-700';
  font-size: 1.8rem;
  text-decoration: none;
  color: #1e1e1e;
  padding-left: 1rem;
`;

const Content = styled.div`
  height: 35rem;
  width: 100%;
  position: relative;
  img {
    /* display: block; */
    width: 100%;
    object-fit: cover;
  }
  h1 {
    text-align: center;
    padding: 2rem;
    width: 40%;
    font-family: 'MuseoSans-700';
    font-size: 1.8rem;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.49);
  }
`;
const Overview = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem 2rem;
  height: 6rem;
  background-color: rgba(0, 0, 0, 0.49);
  bottom: 0;
  h2 {
    width: 80%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: relative;
    line-height: 1.92rem;
    max-height: 3.84rem;
    height: 4rem;
    font-family: 'MuseoSans-700';
    font-size: 1.4rem;
    color: #fff;
    margin-right: 1rem;
    margin-bottom: 0;
    padding-right: 1rem;
  }
  a {
    text-align: center;
    font-size: 1.4rem;
    font-family: 'MuseoSans-700';
    color: #63d9d7;
    cursor: pointer;
  }
`;
export class CardSample extends Component {
  state = {
    product: ''
    // isLoading: true
  };

  componentDidMount() {
    // console.log('componentdidmount');
    // const productSampleUrls = this.props.products.map(
    //   product => product.productSamples[0].url
    // );
    // this.cacheImages(productSampleUrls).then(() =>
    //   this.setState({
    //     isLoading: false
    //   })
    // );
  }

  componentDidUpdate() {
    // const { product } = this.state;
    // const isProduct = product ? true : false;
    // console.log('compoentDidupdate', isProduct);
    // console.log(this.state.product);
  }

  // getImagesArray = arr => {
  //   let images = [];
  //   for (let i = 0; i < arr.length; i++) {
  //     for (let j = 0; j < arr[i].productSamples.length; j++) {
  //       images.push(arr[i].productSamples[j].url);
  //     }
  //   }
  //   return images;
  // };

  // cacheImages = url => {
  //   return new Promise(function(resolve, reject) {
  //     let img = new Image();
  //     img.onload = function() {
  //       resolve(url);
  //     };
  //     img.onerror = function() {
  //       reject(url);
  //     };
  //     img.src = url;
  //   });
  // };

  changeProduct = product => {
    this.setState({
      product: product
    });
  };

  // handleImageLoaded = () => {
  //   console.log('i was called loaded');
  //   this.setState({ isLoading: false });
  // };
  // handleImageErrored = () => {
  //   alert('error occured');
  // };

  // renderSpinner = () => {
  //   if (this.state.isLoading) {
  //     return (
  //       <Segment>
  //         <Dimmer active>
  //           <Loader>Loading</Loader>
  //         </Dimmer>
  //       </Segment>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  render() {
    const productName = this.state.product;
    // console.log(this.state.isLoading, productName);
    const products = this.props.products;
    const productNames = products.map(product => product.productName);

    if (productName === '' || !productNames.includes(productName)) {
      //   console.log('state is empty, initial point, or state bug occured');

      const productSampleUrl = products[0].productSamples[0].url;

      const productOverview = products[0].productOverview;
      const companyName = products[0].companyName;
      const name = products[0].productName;
      return (
        <Content>
          {/* <img src={productSampleUrl} alt='product sample' /> */}
          {}
          <LazyImage src={productSampleUrl} />
          {/* <h1>{name}</h1>

            <Overview>
              <h2>{productOverview}</h2>
              <Link to={`/marketplace/${companyName}/${name}`}>Learn More</Link>
            </Overview> */}
        </Content>
      );
    } else {
      //   console.log('state change occured');

      const filteredProduct = products.filter(product => {
        return product.productName === productName;
      });
      //   console.log('product', filteredProduct);
      const productSampleUrl = filteredProduct[0].productSamples[0].url;
      // this.cacheImages(productSampleUrl);
      const productOverview = filteredProduct[0].productOverview;
      const companyName = filteredProduct[0].companyName;
      const name = filteredProduct[0].productName;
      return (
        <>
          <Content>
            {/* <img src={productSampleUrl} alt='product sample' /> */}
            <LazyImage src={productSampleUrl} />

            {/* <h1>{name}</h1>
              <Overview>
                <h2>{productOverview}</h2>
                <Link to={`/marketplace/${companyName}/${name}`}>
                  <a>Learn More</a>
                </Link>
              </Overview> */}
          </Content>
        </>
      );
    }
  }
  // const index = this.state.index;
  // console.log('index', index);
  // console.log('productName: ', this.props.products[index].productName);
  // console.log('company: ', this.props.products[index].companyName);
  //
}
