import React, { Component } from 'react';

import styled from 'styled-components';

const Link = styled.a`
  font-family: 'MuseoSans-700';
  font-size: 1.4rem;
  text-decoration: none;
  margin-bottom: 1rem;
  cursor: pointer;
  color: #1e1e1e;

  &:hover {
    color: #ff5c0b;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 87%;
  overflow: hidden;
`;

export class CardProductList extends Component {
  state = {};

  handleClick = e => {
    // console.log(e.target.name);
  };

  render() {
    const products = this.props.productList;
    // console.log('CardProductList', products);
    return (
      <Container>
        {products.map((product, index) => {
          return (
            <Link
              key={`productLink` + index}
              name={index}
              onClick={e => this.props.handleClick(e)}
            >
              {product.productName}
            </Link>
          );
        })}
      </Container>
    );
  }
}
