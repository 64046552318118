import React, { Component } from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import styled from 'styled-components';

const Box = styled.div`
  margin-top: 2.5vh;
  width: 75%;
  z-index: 2;
`;
const Container = styled.div`
  height: 11.8rem;
  padding: 1rem 2rem;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  img {
    height: 6rem;
    width: 20rem;
  }
`;
const Accordion = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0 4rem;
  h1 {
    font-family: 'MuseoSans-500';
    font-size: 2rem;
    margin: 0;
  }
`;
const AccordionArrow = styled.h2`
  margin: 0 0 0 2rem;
  font-family: 'MuseoSans-700';
  font-size: 2rem;
  color: #1e1e1e;
  transform: ${p => (p.open ? `rotate(90deg)` : '')};
`;

const AccordionContent = styled.div`
  display: ${p => (p.accordionOpen ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #fff;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  /* position: absolute; */
  float: right;

  a {
    padding: 1rem 6rem;
    font-family: 'MuseoSans-500';
    font-size: 1.8rem;
    margin: 0;
    text-align: center;
    &:hover {
      color: #ff5c0b;
    }
  }
  a:nth-child(odd) {
    background-color: #f4f4f4;
  }
`;

const Link = ({ isActive, children, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};

const StyledLink = styled(Link)`
  font-family: 'MuseoSans-700';
  font-size: 1.8rem;
  text-decoration: none;
  color: #1e1e1e;
  padding-left: 1rem;
`;

export class SellerHeader extends Component {
  state = {
    accordionOpen: true
  };

  handleAccordionChange = () => {
    this.setState({
      accordionOpen: !this.state.accordionOpen
    });
  };
  render() {
    const { logo, products, companyName } = this.props;
    console.log(products);

    const currentUrl = window.location.pathname;
    return (
      <Box>
        <Container>
          <Link>
            <img
              to={`marketplace/${products[0].companyName}`}
              src={logo}
              alt='logo'
            />
          </Link>
          <Accordion onClick={this.handleAccordionChange}>
            <h1>View Products</h1>
            <AccordionArrow open={this.state.accordionOpen}>
              &#10095;
            </AccordionArrow>
          </Accordion>
        </Container>
        <AccordionContent accordionOpen={this.state.accordionOpen}>
          {products.map(product => {
            return (
              <StyledLink to={`/marketplace/${companyName}/${product}`}>
                {product}
              </StyledLink>
            );
          })}
        </AccordionContent>
      </Box>
    );
  }
}
