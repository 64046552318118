import React, { Component } from 'react';
import { db, storage, timeStamp } from '../../../auth/authService';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import AdminPageLayout from './AdminPageLayout';
import Form from '@rjsf/material-ui';
import uniqid from 'uniqid';

import { Loader, Dimmer } from 'semantic-ui-react';

const schema = {
  'title': 'Company Profile',
  'type': 'object',
  'required': [
    'companyName',
    'companyLogoImg',
    'companyOverview',
    'productList'
  ],
  'properties': {
    'companyName': {
      'type': 'string',
      'title': 'company name'
    },
    'companyLogoImg': {
      'type': 'string',
      'format': 'data-url',
      'title': 'company logo file',
      'description':
        'Enter the product thumbnail to update. IF no change is required, leave the field empty'
    },
    'companyOverview': {
      'type': 'string',
      'title': 'company overview'
    },

    'productList': {
      'type': 'array',
      'title': 'Products',
      'items': {
        'type': 'object',
        'required': [
          'productName',
          'productImgFiles',
          'productOverview',
          'productCategory',
          'productDetails',
          'productThumbnail'
        ],
        'properties': {
          'productName': {
            'type': 'string',
            'title': 'product name'
          },
          'productCategory': {
            'title': 'product category',
            'type': 'string',
            'enum': [
              'Very High Resolution',
              'High Resolution',
              'Medium Resolution',
              'RADAR',
              'Platform Service',
              'Applications'
            ]
          },
          'productOverview': {
            'type': 'string',
            'title': 'product overview'
          },
          'productThumbnailUpdate': {
            'type': 'string',
            'title': 'product thumbnail',
            'format': 'data-url',
            'description':
              'Enter the product thumbnail to update. IF no change is required, leave the field empty'
          },
          'productImgFiles': {
            'type': 'array',
            'title': 'Product Samples',
            'items': {
              'type': 'object',
              'properties': {
                'imageFile': {
                  'type': 'string',
                  'format': 'data-url',
                  'title': 'Product Sample Image',
                  'description': 'Enter Jpeg Image'
                }
              }
            }
          },
          'productDetails': {
            'type': 'array',
            'title': 'Product Detail - Accordion Form',
            'items': {
              'type': 'object',
              'required': ['accordionTitle'],
              'properties': {
                'accordionTitle': {
                  'type': 'string',
                  'title': 'accordion title'
                },
                'accordionBody': {
                  'type': 'array',
                  'items': {
                    'type': 'object',
                    'anyOf': [
                      {
                        'title': 'Accordion Text',
                        'properties': {
                          'accordionText': {
                            'type': 'string',
                            'title': 'Text'
                          }
                        }
                      },
                      {
                        'title': 'Accordion Image',
                        'properties': {
                          'accordionImages': {
                            'type': 'array',
                            'title': 'Images',
                            'items': {
                              'type': 'object',
                              'properties': {
                                'imageFile': {
                                  'type': 'string',
                                  'format': 'data-url',
                                  'title': 'Image in JPEG format',
                                  'description': 'Enter jpeg Images only'
                                },
                                'imageName': {
                                  'type': 'string',
                                  'title': 'Image Description'
                                }
                              }
                            }
                          }
                        }
                      },
                      {
                        'title': 'Accordion Links',
                        'properties': {
                          'accordionLinks': {
                            'type': 'array',
                            'title': 'links',
                            'items': {
                              'type': 'object',
                              'properties': {
                                'linkUrl': {
                                  'type': 'string',
                                  'title': 'Link Address'
                                },
                                'linkDescription': {
                                  'type': 'string',
                                  'title': 'Link Description'
                                }
                              }
                            }
                          }
                        }
                      },
                      {
                        'title': 'Accordion Attachments',
                        'properties': {
                          'accordionAttachments': {
                            'type': 'array',
                            'title': 'PDF Attachment',
                            'items': {
                              'type': 'object',
                              'properties': {
                                'attachmentFile': {
                                  'type': 'string',
                                  'format': 'data-url',
                                  'title': 'Attachment in PDF file',
                                  'description': 'Enter PDF files only'
                                },
                                'attachmentName': {
                                  'type': 'string',
                                  'title': 'PDF Description'
                                }
                              }
                            }
                          }
                        }
                      }
                    ]
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};
const uiSchema = {
  companyOverview: {
    'ui:widget': 'textarea'
  },
  companyLogoImg: {
    'ui:widget': props => {
      return (
        <div>
          <input
            type='file'
            // value={props.value}
            onChange={event => props.onChange(event.target.files[0])}
          ></input>
          {console.log(props)}
        </div>
      );
    }
  },

  productList: {
    items: {
      productImgFiles: {
        'ui:widget': props => {
          return <div>{console.log('hello', props)}Pictures will show</div>;
        }
      },
      productOverview: {
        'ui:widget': 'textarea'
      },
      productDetails: {
        items: {
          accordionBody: {
            'ui:widget': 'textarea'
          },
          accordionAttachment: {
            items: {
              attachmentFile: {
                'ui:options': { accept: '.pdf' }
              }
            }
          },
          accordionImages: {
            items: {
              imageFile: {
                'ui:options': { accept: '.png' }
              }
            }
          }
        }
      }
    }
  }
};

class Edit extends Component {
  state = {
    formData: {},
    submitting: false
  };
  componentDidMount() {
    const { company, products } = this.props.location.state;
    let productsArray = products.map(product => {
      return {
        productName: product.productName,
        productCategory: product.productCategory,
        productDetails: product.productDetails,
        productOverview: product.productOverview
      };
    });

    let formData = {
      companyName: company.companyName,
      companyLogoImg: company.companyLogo.file,
      companyOverview: company.companyOverview,
      productList: productsArray
    };

    this.setState({
      formData: formData
    });
    console.log(formData);
  }

  onSubmit = async ({ formData }, e) => {
    try {
      e.preventDefault();
      console.log('data submitting: ', formData);
      this.setState({
        submitting: true
      });
      const updatedAt = timeStamp.now();
      const { companyLogoImg, companyOverview, productList } = formData;
      let companyName = formData.companyName.toUpperCase();

      //productList : array
      //    -> productCategory, productDetails(array), productImgFiles(array), productName, productOverview
      //                           -->accordionTitle, accordionBody
      //company logo upload
      const companyLogoArray = await this.uploadTaskPromise(
        companyLogoImg,
        companyName
      );

      // productDetails upload
      const products = await this.asyncForEachAccordionFileUpload(
        productList,
        this.uploadTaskPromise
      );
      console.log(products);
      // product sample images upload
      const finalProducts = await this.asyncForEachFileUpload(
        products,
        this.uploadTaskPromise
      );
      console.log(finalProducts);

      //productList to Company Collections
      let productsToCompany = [];
      finalProducts.forEach(item => {
        productsToCompany.push(item.productName);
      });

      await db
        .collection('companies')
        .doc(companyName)
        .set({
          companyName,
          companyOverview,
          companyLogo: {
            url: companyLogoArray[0],
            ref: companyLogoArray[1]
          },
          productList: productsToCompany,
          updatedAt
        })
        .then(() => console.log('company upload successful'));

      for (let index = 0; index < finalProducts.length; index++) {
        console.log('forloop called');
        const finalProduct = finalProducts[index];
        await db
          .collection('products')
          .doc(finalProduct.productName)
          .set({
            companyName,
            productName: finalProduct.productName,
            productCategory: finalProduct.productCategory,
            productSamples: finalProduct.productSamples,
            productOverview: finalProduct.productOverview,
            productDetails: finalProduct.productDetails,
            updatedAt
          })
          .then(() => console.log('data upload successful async'))
          .catch(err => console.log(err));
      }

      this.setState({
        submitting: false
      });
      // window.location.reload(true);
      toast.success('data submitted');
    } catch (err) {
      this.setState({
        submitting: false
      });
      console.log(err);
    }
  };

  asyncForEachFileUpload = async (array, callback) => {
    console.log(array);
    //productList -> WV1, WV2 , ....
    let productList = [...array];
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < array.length; i++) {
        // let url = await callback(array[index].productImgFiles);
        // products[index]['productSampleUrl'] = url;
        let newUrlObject = {
          productSamples: []
        };
        Object.assign(productList[i], newUrlObject);
        for (let j = 0; j < array[i].productImgFiles.length; j++) {
          let arr = await callback(
            array[i].productImgFiles[j],
            array[i].productName
          );

          productList[i].productSamples.push({ url: arr[0], ref: arr[1] });
          console.log(productList[i].productSamples);
        }
      }
      if (productList[0].productSamples[0] !== '') {
        // console.log(productList);
        console.log(productList);
        resolve(productList);
      } else {
        console.log('something went wrong');
        reject();
      }
    });
  };

  asyncForEachAccordionFileUpload = async (array, callback) => {
    try {
      let productList = [...array];
      console.log('HEYYY ACCORDION');
      return new Promise(async (resolve, reject) => {
        for (let i = 0; i < array.length; i++) {
          //attachments upload
          for (let j = 0; j < array[i].productDetails.length; j++) {
            if (array[i].productDetails[j].accordionAttachments) {
              console.log('I have accordion attachment');
              let newObject = {
                attachmentFileUrls: []
              };
              Object.assign(productList[i].productDetails[j], newObject);

              for (
                let w = 0;
                w < array[i].productDetails[j].accordionAttachments.length;
                w++
              ) {
                let resolvedArr = await callback(
                  array[i].productDetails[j].accordionAttachments[w]
                    .attachmentFile,
                  array[i].productDetails[j].accordionAttachments[w]
                    .attachmentName
                );

                productList[i].productDetails[j].attachmentFileUrls.push({
                  url: resolvedArr[0],
                  ref: resolvedArr[1]
                });
              }
            }
            console.log(array[i].productDetails[j].accordionImages);
            if (array[i].productDetails[j].accordionImages) {
              console.log('i have accordionImages!!!!!!!!!!!!!!!!!!!!!!');
              let newObject = {
                imageFileUrls: []
              };
              Object.assign(productList[i].productDetails[j], newObject);
              console.log(productList[i].productDetails[j].accordionImages);
              for (
                let z = 0;
                z < array[i].productDetails[j].accordionImages.length;
                z++
              ) {
                let resolvedArr = await callback(
                  array[i].productDetails[j].accordionImages[z].imageFile,
                  array[i].productDetails[j].accordionImages[z].imageName
                );
                console.log(resolvedArr);
                productList[i].productDetails[j].imageFileUrls.push({
                  url: resolvedArr[0],
                  ref: resolvedArr[1]
                });
              }
            }
          }
        }
        console.log(productList);
        resolve(productList);
        if (!productList) {
          reject();
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  uploadTaskPromise = async (imageFile, name) => {
    const uid = uniqid();
    let nameString = name.replace('(', '');
    nameString = name.replace(')', '');
    let imageName = uid.concat(nameString);
    console.log(imageName);
    return new Promise((resolve, reject) => {
      const storageRef = storage.ref(`/images/` + imageName);
      const upLoadTask = storageRef.putString(imageFile, 'data_url');
      upLoadTask.on(
        'state_changed',
        snapshot => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        err => {
          console.log(err);
          reject();
        },
        () => {
          console.log(imageName);
          storage
            .ref('images')
            .child(imageName)
            .getDownloadURL()
            .then(url => {
              console.log(url);
              resolve([url, imageName]);
            });
        }
      );
    });
  };

  onFileUpload = e => {
    console.log(e.target.files[0]);
  };
  render() {
    const { match, location, history } = this.props;
    console.log(match);
    console.log(location);
    console.log(history);
    console.log(this.props);
    return (
      <AdminPageLayout>
        <Form
          formData={this.state.formData}
          onSubmit={this.onSubmit}
          schema={schema}
          uiSchema={uiSchema}
        ></Form>

        <Dimmer active={this.state.submitting}>
          <Loader size='massive' active={this.state.submitting}>
            Submitting
          </Loader>
        </Dimmer>

        <input type='file' onChange={this.onFileUpload}></input>
      </AdminPageLayout>
    );
  }
}

export default withRouter(Edit);
