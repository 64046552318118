import React from 'react';
import { connect } from 'react-redux';
import { filterProducts } from '../../redux/marketplaceActions';
import CategoryBar from 'components/common/CategoryBar';
import { MarketplaceContent } from 'components/common';
import { PageLayout } from 'components/common';
import styled from 'styled-components';
import HeaderImg from 'images/marketplace/Marketplace_img.png';

import { Loader } from 'semantic-ui-react';

const Header = styled.div`
  background-image: url(${HeaderImg});
  background-size: cover;
  width: 100vw;
  height: 30vh;

  padding: 0 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-family: 'MuseoSans-900';
    font-size: 2.5rem;
    color: #63d9d7;
    margin: 0;
  }
  h1 {
    font-family: 'MuseoSans-700';
    font-size: 4rem;
    color: #fff;
    margin: 0;
  }
`;

const Main = styled.div`
  padding: 0 10%;
  display: flex;
  @media screen and (max-width: 870px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
class Marketplace extends React.Component {
  orderProducts = (allCompanies, allProducts) => {
    let productsInOrder = [];

    for (let i = 0; i < allCompanies.length; i++) {
      for (let k = 0; k < allCompanies[i].productList.length; k++) {
        allProducts.forEach(product => {
          if (product.productName === allCompanies[i].productList[k]) {
            console.log('Iwascalled', product);
            productsInOrder.push(product);
          }
        });
      }
    }
    console.log(productsInOrder);
    return productsInOrder;
  };
  render() {
    const allProducts = this.props.fetchedProducts.products;
    const allCompanies = this.props.fetchedCompanies.companies;
    const filteredProducts = this.props.filteredProducts;

    // console.log(Object.keys(filteredProducts).length);

    // console.log(this.props.fetchedProducts.isFetching);
    // console.log(this.props.fetchedCompanies.isFetching);
    console.log(filteredProducts);

    if (
      this.props.fetchedProducts.isFetching ||
      this.props.fetchedCompanies.isFetching
    ) {
      return (
        <PageLayout>
          <Loader
            active
            inline='centered'
            size={'massive'}
            style={{ margin: '40rem auto' }}
          >
            Loading Page
          </Loader>
        </PageLayout>
      );
    } else {
      const productsInOrder = this.orderProducts(allCompanies, allProducts);
      const filteredProductsInOrder = this.orderProducts(
        allCompanies,
        filteredProducts
      );
      return (
        <PageLayout>
          <Header>
            <p>Information Post</p>
            <h1>
              Find the core information you need and feel free to contact us for
              more information.
            </h1>
          </Header>

          <Main>
            <CategoryBar />
            {/* <MarketList /> */}
            <MarketplaceContent
              products={productsInOrder}
              companies={allCompanies}
              filteredProducts={filteredProductsInOrder}
              index={0}
            />
          </Main>
        </PageLayout>
      );
    }

    // return (
    //   <PageLayout>
    //     {this.props.fetchedProducts.isFetching ||
    //     this.props.fetchedCompanies.isFetching ? (
    //       <Loader
    //         active
    //         inline='centered'
    //         size={'massive'}
    //         style={{ margin: '40rem auto' }}
    //       >
    //         Loading Page
    //       </Loader>
    //     ) : (
    //       <>
    //         <Header>
    //           <p>Marketplace</p>
    //           <h1>Search the data source and analytics tools you need</h1>
    //         </Header>
    //         <Main>
    //           <CategoryBar />
    //           <MarketList />
    //         </Main>
    //       </>
    //     )}
    //   </PageLayout>
    // );
  }
}

const mapStateToProps = state => ({
  fetchedProducts: state.fetchedProducts,
  fetchedCompanies: state.fetchedCompanies,
  filteredProducts: state.filteredProducts
});

export default connect(
  mapStateToProps,
  { filterProducts }
)(Marketplace);
