import styled from 'styled-components';

const Button = styled.button`
  font-family: 'MuseoSans-700';
  padding: 1.5rem 2.5rem;
  border-style: none;
  background-color: #ff5c0b;
  border-radius: 5rem;
  font-size: 1.6rem;
  /* width: 85%; */
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
  flex: none;
  outline: none;

  &:hover {
    cursor: pointer;
    background-color: #ba4911;
  }

  &:active {
    background-color: #ba4911;
    transform: translateY(3px);
  }

  &:disabled {
    background-color: #ba4911;
    cursor: default;
  }
`;

export { Button };
