import React, { Component } from 'react';
import { connect } from 'react-redux';
import { auth, timeStamp, db } from '../../auth/authService';
import { PageLayout } from 'components/common';
import styled from 'styled-components';
import { Button, Spinner } from 'components/common';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import emailjs from 'emailjs-com';
import { Redirect } from 'react-router-dom';

import LoginImg from 'images/login/LoginPageImage.jpg';
import NoahFav from 'images/login/NoahFav.png';
import LogoSrc from 'images/Logo-2x.png';

const Container = styled.div`
  padding: 10% 0;
  height: 100%;
  background-image: url(${LoginImg});

  background-size: cover;
  background-repeat: no-repeat;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
  @media screen and (max-width: 500px) {
    background-color: #1d1e1d;
    width: 100%;
    height: auto;
    padding: 0;
    background-image: none;
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const SignInForm = styled.form`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  margin: auto;
  flex-direction: column;
  background-color: #1d1e1d;
  width: 50rem;
  padding: 6rem 5rem;
  border-radius: 1rem;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);

  @media screen and (max-width: 500px) {
    background-color: #1d1e1d;
    width: 100%;
    border-radius: 0;
  }
`;

const Logo = styled.img`
  width: 30%;
  margin-bottom: 1rem;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0rem;
  h1 {
    font-family: 'MuseoSans-300';
    font-size: 1.8rem;
    color: #fff;
    margin: 0;
  }
`;
const FormTitle = styled.div`
  h1 {
    font-family: 'MuseoSans-500';
    font-size: 1.8rem;
    color: #fff;
    padding: 2rem 0;
    margin-bottom: 1.5rem;
  }
  h2 {
    margin: 0;
    font-family: 'MuseoSans-300';
    font-size: 1.6rem;
    color: #fff;
  }
`;

const StyledField = styled.div`
  /* margin-top: 2rem; */
  /* width: 30rem; */
  width: 100%;
  height: 5rem;
  position: relative;
  margin-bottom: 1.4rem;
  /* padding: 0 2rem; */
  @media screen and (max-width: 1500px) {
    width: 100%;
  }
  > span {
    color: red;
    font-family: 'MuseoSans-300';
    font-size: 1.4rem;
    margin-top: 1rem;
  }
`;

const StyledInput = styled.input`
  float: left;
  font-size: 1.8rem;
  font-family: 'MuseoSans-300';
  padding: 1rem;
  display: block;
  width: 100%;
  color: #e8e8e8;
  background-color: transparent;

  border-style: none none solid none;
  border-width: 2px;
  border-color: ${props => (props.typed ? '#63d9d7' : '#e8e8e8')};

  &:focus {
    outline: 0;
    box-shadow: 0;
    border-style: none none solid none;
    border-width: 1px;
    border-color: #63d9d7;
  }
  &::placeholder {
    color: transparent;
  }

  &:focus ~ label {
    color: #63d9d7;
    top: -2rem;
    font-size: 1.2rem;
  }
`;

const StyledLabel = styled.label`
  color: ${props => (props.typed ? '#63d9d7' : '#e8e8e8')};
  position: absolute;
  font-size: ${props => (props.typed ? '1.4rem' : '1.4rem')};
  font-family: 'MuseoSans-300';
  left: 0;
  top: ${props => (props.typed ? '-1.7rem' : '-1.7rem')};

  pointer-events: none;

  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
`;

const Divider = styled.div`
  width: 100%;
  border-style: none none solid none;
  border-width: 1px;
  border-color: grey;
  margin-top: 2rem;
`;

const FormBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;
const FormBottomText = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  h1 {
    font-family: 'MuseoSans-300';
    font-size: 1.6rem;
    padding: 0 1rem;
    color: #fff;
    margin: 0;
  }
  a {
    border: none;
    color: #ff5c0b;
    font-family: 'MuseoSans-300';
    font-size: 1.6rem;
    &:hover {
      cursor: pointer;
    }
  }
`;

const SignUpForm = styled.form`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  margin: auto;
  flex-direction: column;
  background-color: #1d1e1d;
  width: 50rem;
  padding: 6rem 5rem;
  border-radius: 1rem;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);

  @media screen and (max-width: 500px) {
    background-color: #1d1e1d;
    width: 100%;
    border-radius: 0;
  }
`;

const PasswordRestForm = styled.form`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  margin: auto;
  flex-direction: column;
  background-color: #1d1e1d;
  width: 50rem;
  padding: 6rem 5rem;
  border-radius: 1rem;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);

  @media screen and (max-width: 500px) {
    background-color: #1d1e1d;
    width: 100%;
    border-radius: 0;
  }
`;

const SignUpComplete = styled.div`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  margin: auto;
  flex-direction: column;
  background-color: #1d1e1d;
  width: 50rem;
  padding: 6rem 5rem;
  border-radius: 1rem;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);

  @media screen and (max-width: 500px) {
    background-color: #1d1e1d;
    width: 100%;
    border-radius: 0;
  }
`;

const PasswordResetComplete = styled.div`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  margin: auto;
  flex-direction: column;
  background-color: #1d1e1d;
  width: 50rem;
  padding: 6rem 5rem;
  border-radius: 1rem;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);

  @media screen and (max-width: 500px) {
    background-color: #1d1e1d;
    width: 100%;
    border-radius: 0;
  }
`;

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      typed: {},
      formSubmitting: false,
      backgroundUrl: null,
      currentForm: 'signIn'
    };
  }

  componentDidMount() {
    this.loadImage(LoginImg).then(url => {
      this.setState({ backgroundUrl: url });
    });
  }

  loadImage = url => {
    return new Promise(function(resolve, reject) {
      let img = new Image();
      img.src = url;
      img.onload = () => {
        resolve(url);
      };
      img.oneror = () => {
        reject('error');
      };
    });
  };

  handleFormChange = formName => {
    this.setState({
      fields: {},
      errors: {},
      typed: {},
      formSubmitting: false,
      currentForm: formName,
      typed: {}
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    let fields = this.state.fields;
    let typed = this.state.typed;
    fields[name] = value;
    typed[name] = value.length !== 0;
    this.setState({ fields, typed });
    // console.log(this.state.typed);
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      formSubmitting: true
    });

    if (this.state.currentForm === 'signUp') {
      const {
        firstName,
        lastName,
        email,
        company,
        country
      } = this.state.fields;
      const templateParams = {
        firstName,
        lastName,
        email,
        company,
        country
      };
      const userRef = db.collection('users').doc(email);

      userRef
        .get()
        .then(doc => {
          if (doc.exists) {
            toast.error('The email is already in our system!', {
              autoClose: 6000
            });
            this.setState({
              formSubmitting: false
            });
          } else {
            db.collection('users')
              .doc(email)
              .set({
                admin: false,
                firstName,
                lastName,
                email,
                company,
                country,
                createdAt: timeStamp.now(),
                isRegistered: false
              })
              .then(() => {
                emailjs.init('user_t4OCCsC1R3vIvsBhDmumK');
                emailjs
                  .send('service_cz6erjd', 'template_0pkvydp', templateParams)
                  .then(
                    function(response) {
                      console.log('success', response.status, response.text);
                    },
                    function(error) {
                      console.log('failed', error);
                    }
                  );
              })
              .then(() => {
                toast.success('Request submitted!', { autoClose: 6000 });
                this.setState({
                  formSubmitting: false,
                  currentForm: 'signUpComplete'
                });
              });
          }
        })
        .catch(err => {
          console.log(err);
          toast.error('Error', { autoClose: 6000 });
        });
    } else if (this.state.currentForm === 'signIn') {
      const { email, password } = this.state.fields;

      auth
        .signInWithEmailAndPassword(email, password)
        .then(res => {
          localStorage.setItem('isAuthenticated', true);
          console.log(res);
          this.props.history.push('/marketplace');
        })
        .catch(error => {
          let errorCode = error.code;
          let errorMessage = error.message;
          console.log(errorCode);

          switch (errorCode) {
            case 'auth/invalid-email':
              console.log('invalid-email');
              toast.error('Incorrect login credentials.', { autoClose: 5000 });
              this.setState({
                formSubmitting: false
              });
              break;

            case 'auth/user-disabled':
              console.log('disabled-email');

              toast.error('Incorrect login credentials.', { autoClose: 5000 });
              this.setState({
                formSubmitting: false
              });
              break;

            case 'auth/user-not-found':
              console.log('invalfoundid-email');

              toast.error('Incorrect login credentials.', { autoClose: 5000 });
              this.setState({
                formSubmitting: false
              });
              break;

            case 'auth/wrong-password':
              console.log('password-email');

              toast.error('Incorrect login credentials.', { autoClose: 5000 });
              this.setState({
                formSubmitting: false
              });
              break;
          }
        });

      //
    } else if (this.state.currentForm === 'password') {
      const { email } = this.state.fields;
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          toast.success('Request submitted!', { autoClose: 6000 });
          this.setState({
            formSubmitting: false,
            currentForm: 'passwordResetComplete'
          });
        })
        .catch(() => {
          toast.success('Request submitted!', { autoClose: 6000 });
          this.setState({
            formSubmitting: false,
            currentForm: 'passwordResetComplete'
          });
        });
    }
  };

  render() {
    let { typed, currentForm } = this.state;
    if (this.state.backgroundUrl) {
      return (
        <PageLayout>
          <Container>
            <SignInForm
              onSubmit={this.handleSubmit}
              isActive={this.state.currentForm === 'signIn'}
              autocomplete='off'
            >
              <FormHeader>
                <Logo src={LogoSrc} />
                {/* <h1>New Opportunity Awaits Here</h1> */}
              </FormHeader>
              <FormTitle>
                <h1>Sign in</h1>
              </FormTitle>
              <StyledField>
                <StyledInput
                  type='email'
                  name='email'
                  // placeholder=''
                  onChange={this.handleChange}
                  typed={typed.email}
                  required
                  autocomplete='off'
                />
                <StyledLabel htmlFor='email' typed={typed.email}>
                  Email Address
                </StyledLabel>
                {<span>{this.state.errors.email}</span>}
              </StyledField>
              <StyledField>
                <StyledInput
                  type='password'
                  name='password'
                  placeholder=''
                  onChange={this.handleChange}
                  typed={typed.password}
                  autocomplete='off'
                  required
                />
                <StyledLabel htmlFor='password' typed={typed.password}>
                  Password
                </StyledLabel>
                {<span>{this.state.errors.password}</span>}
              </StyledField>
              <Button
                disabled={this.state.formSubmitting}
                style={{
                  borderRadius: '1rem',
                  marginTop: '1rem',
                  marginBottom: '1rem'
                }}
              >
                {this.state.formSubmitting ? (
                  <Loader active inline='centered' />
                ) : (
                  'SIGN IN'
                )}
              </Button>
              <Divider />
              <FormBottom>
                <FormBottomText>
                  <h1>Not a member?</h1>
                  <a onClick={() => this.handleFormChange('signUp')}>Sign up</a>
                </FormBottomText>
                <FormBottomText>
                  <h1>Forget your password?</h1>
                  <a onClick={() => this.handleFormChange('password')}>
                    Reset password
                  </a>
                </FormBottomText>
              </FormBottom>
            </SignInForm>
            <SignUpForm
              isActive={this.state.currentForm === 'signUp'}
              autocomplete='off'
              onSubmit={this.handleSubmit}
            >
              <FormHeader>
                <Logo src={LogoSrc} />
                {/* <h1>New Opportunity Awaits Here</h1> */}
              </FormHeader>
              <FormTitle>
                <h1>Sign up</h1>
              </FormTitle>
              <StyledField>
                <StyledInput
                  type='text'
                  name='firstName'
                  placeholder=''
                  onChange={this.handleChange}
                  typed={typed.firstName}
                  required
                />
                <StyledLabel htmlFor='firstName' typed={typed.firstName}>
                  First name
                </StyledLabel>
                {<span>{this.state.errors.firstName}</span>}
              </StyledField>
              <StyledField>
                <StyledInput
                  type='text'
                  name='lastName'
                  placeholder=''
                  onChange={this.handleChange}
                  typed={typed.lastName}
                  required
                />
                <StyledLabel htmlFor='lastName' typed={typed.lastName}>
                  Last name
                </StyledLabel>
                {<span>{this.state.errors.lastName}</span>}
              </StyledField>
              <StyledField>
                <StyledInput
                  type='email'
                  name='email'
                  placeholder={null}
                  onChange={this.handleChange}
                  typed={typed.email}
                  required
                />
                <StyledLabel htmlFor='email' typed={typed.email}>
                  Email Address
                </StyledLabel>
                {<span>{this.state.errors.email}</span>}
              </StyledField>
              <StyledField>
                <StyledInput
                  type='text'
                  name='company'
                  placeholder=''
                  onChange={this.handleChange}
                  typed={typed.company}
                  required
                  autocomplete='off'
                />
                <StyledLabel htmlFor='company' typed={typed.company}>
                  Company
                </StyledLabel>
                {<span>{this.state.errors.company}</span>}
              </StyledField>
              <StyledField>
                <StyledInput
                  type='text'
                  name='country'
                  placeholder=''
                  onChange={this.handleChange}
                  typed={typed.country}
                  required
                />
                <StyledLabel htmlFor='country' typed={typed.country}>
                  Country
                </StyledLabel>
                {<span>{this.state.errors.company}</span>}
              </StyledField>
              <Button
                disabled={this.state.formSubmitting}
                style={{ borderRadius: '1rem', marginTop: '1rem' }}
              >
                {this.state.formSubmitting ? (
                  <Loader active inline='centered' />
                ) : (
                  'SIGN UP'
                )}
              </Button>
              <Divider />
              <FormBottom>
                <FormBottomText>
                  <h1>Already a member?</h1>
                  <a onClick={() => this.handleFormChange('signIn')}>Sign in</a>
                </FormBottomText>
              </FormBottom>
            </SignUpForm>
            <SignUpComplete
              isActive={this.state.currentForm === 'signUpComplete'}
            >
              <FormHeader>
                <Logo src={LogoSrc} />
                {/* <h1>New Opportunity Awaits Here</h1> */}
              </FormHeader>
              <FormTitle style={{ textAlign: 'center' }}>
                <h1>Your request has been submitted! </h1>
                <h2 style={{ textAlign: 'left' }}>
                  Please allow 1-2 business days for us to reach back to you
                  through email.{' '}
                </h2>
              </FormTitle>
              <Divider />
              <FormBottom>
                <FormBottomText>
                  <h1>Already a member?</h1>
                  <a onClick={() => this.handleFormChange('signIn')}>Sign in</a>
                </FormBottomText>
              </FormBottom>
            </SignUpComplete>
            <PasswordRestForm
              onSubmit={this.handleSubmit}
              isActive={this.state.currentForm === 'password'}
            >
              <FormHeader>
                <Logo src={LogoSrc} />
                {/* <h1>New Opportunity Awaits Here</h1> */}
              </FormHeader>
              <FormTitle>
                <h1>Reset Password</h1>
              </FormTitle>
              <StyledField>
                <StyledInput
                  type='email'
                  name='email'
                  placeholder=''
                  onChange={this.handleChange}
                  typed={typed.email}
                  required
                />
                <StyledLabel htmlFor='email' typed={typed.email}>
                  Email Address
                </StyledLabel>
                {<span>{this.state.errors.emaill}</span>}
              </StyledField>
              <Button
                disabled={this.state.formSubmitting}
                style={{ borderRadius: '1rem', marginTop: '1rem' }}
              >
                {this.state.formSubmitting ? (
                  <Loader active inline='centered' />
                ) : (
                  'RESET PASSWORD'
                )}
              </Button>
              <Divider />
              <FormBottom>
                <FormBottomText>
                  <h1>Already a member?</h1>
                  <a onClick={() => this.handleFormChange('signIn')}>Sign in</a>
                </FormBottomText>
              </FormBottom>
            </PasswordRestForm>
            <PasswordResetComplete
              isActive={this.state.currentForm === 'passwordResetComplete'}
            >
              <FormHeader>
                <Logo src={LogoSrc} />
                {/* <h1>New Opportunity Awaits Here</h1> */}
              </FormHeader>
              <FormTitle style={{ textAlign: 'center' }}>
                <h1>Your request has been submitted!</h1>
                <h2>
                  If your email address exists in our system, a link to reset
                  your password will be sent to yor inbox.
                </h2>
              </FormTitle>
              <Divider />
              <FormBottom>
                <FormBottomText>
                  <h1>Already a member?</h1>
                  <a onClick={() => this.handleFormChange('signIn')}>Sign in</a>
                </FormBottomText>
              </FormBottom>
            </PasswordResetComplete>
          </Container>
        </PageLayout>
      );
    } else {
      return (
        <Loader
          active
          inline='centered'
          size={'massive'}
          style={{ margin: '40rem auto' }}
        >
          Loading Page
        </Loader>
      );
    }
  }
}
