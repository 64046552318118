import React, { Component } from 'react';
import styled from 'styled-components';

import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';

const StyledImage = styled.img`
  display: ${props => (props.isLoading ? 'none' : 'block')};
`;

export class LazyImage extends Component {
  state = {
    src: null,
    isLoading: true
  };

  componentDidMount() {
    // console.log('component did mont');
    // console.log(this.props.src);
    // const url = this.props.src;
    // // this.cacheImage(this.props.src).then(url => {
    // //   this.setState({
    // //     src: url
    // //   });
    // // });
    // const img = new Image();
    // img.src = url;
    // img.onload = () => {
    //   this.setState({
    //     src: this.props.src
    //   });
    // };
    // img.onerror = () => {
    //   alert('something went wrong. reload');
    // };
  }

  componentDidUpdate() {
    // console.log('component did update');
    // this.cacheImage(this.state.src).then((src) => {
    // })
  }

  // handleImageLoaded = () => {
  //   console.log('i was called loaded');
  //   setTimeout(() => {
  //     this.setState({ isLoading: false });
  //   }, 4000);
  // };
  // handleImageErrored = () => {
  //   alert('error occured');
  // };
  // cacheImage = url => {
  //   return new Promise(function(resolve, reject) {
  //     let img = new Image();
  //     img.onload = function() {
  //       resolve(url);
  //     };
  //     img.onerror = function() {
  //       reject(url);
  //     };
  //     img.src = url;
  //   });
  // };

  handleImageChange = src => {
    this.setState({
      src: src
    });
  };

  handleLoadImage = () => {
    // console.log('iwascalled image loaded');
    // console.log(this.state.isLoading);
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 3000);
  };

  render() {
    // console.log(this.state.isLoading);
    return (
      <div>
        <StyledImage
          src={this.props.src}
          isLoading={this.state.isLoading}
          onLoad={this.handleLoadImage}
        />
        {this.state.isLoading ? (
          <Segment>
            <Dimmer active>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        ) : null}
      </div>
    );
  }
}
